import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ListItem, List } from '@material-ui/core';
import SummaryDisplay from './SummaryDisplay';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withAuthenticationContext } from '../Authenticate';
import { getFieldChoices, loadRepeatableField, populateSearchResult } from '../util';

const styles = (theme) => ({
    list: {
        background: theme.palette.background.default,
    },
    icon: {
        verticalAlign: 'middle',
    },
});

class SummaryField extends React.PureComponent {
    state = {
        choices: [],
        repeatableField: undefined,
    };

    async componentDidMount() {
        if (!this.props.repeatable) {
            if (this.props.field.search) {
                this.setState({
                    choices: await populateSearchResult(
                        this.props.getAccessToken,
                        this.props.authorizationScopes,
                        this.props.field,
                        this.props.value,
                    ),
                });
            } else {
                this.setState({
                    choices: await getFieldChoices(
                        this.props.getAccessToken,
                        this.props.authorizationScopes,
                        this.props.field,
                        this.props.applicationData,
                    ),
                });
            }

            if (this.props.field.typeId === 'multiple') {
                this.setState({
                    repeatableField: await loadRepeatableField(
                        this.props.getAccessToken,
                        this.props.authorizationScopes,
                        this.props.field,
                    ),
                });
            }
        } else {
            this.setState({
                choices: this.props.field.choices ?? [],
            });
        }
    }

    render() {
        let label = '';
        let value = '';
        if (!this.props.field.label) {
            label = this.props.field.field;
        } else {
            label = this.props.field.label;
        }

        if (this.props.field.typeId === 'system') {
            return null;
        }

        let listItem = '';

        switch (this.props.field.typeId) {
            case 'boolean':
                if (this.props.value) {
                    value = <CheckBoxIcon className={this.props.classes.icon} />;
                } else {
                    value = <CheckBoxOutlineBlankIcon className={this.props.classes.icon} />;
                }
                break;
            case 'fileUpload':
                if (this.props.value) {
                    listItem = this.props.value.map((file, index) => {
                        return <ListItem key={index}>{file.fileName}</ListItem>;
                    });
                    value = <List className={this.props.classes.list}>{listItem}</List>;
                }
                break;
            case 'photoUpload':
                if (this.props.value) {
                    value = this.props.value.fileName;
                }
                break;
            case 'choices':
                if (this.props.value) {
                    const choiceValue = this.state.choices.find((choice) => {
                        return choice.value?.toString() === this.props.value.toString();
                    });
                    value = choiceValue && choiceValue.label;
                }
                break;
            case 'multiple':
                if (this.props.value) {
                    value = this.props.value.map((valueData, valueKey) => {
                        return (
                            <SummaryDisplay
                                key={valueKey}
                                multi={this.props.field.field + `[${valueKey}]`}
                                fields={this.state.repeatableField?.fields ?? []}
                                fieldData={valueData}
                                errors={{}}
                                visibility={this.props.visibility}
                                onChange={this.handleNestedChange}
                                onCommit={this.handleNestedChange}
                            />
                        );
                    });
                }
                break;
            case 'textBlock':
                value = <ReactMarkdown source={this.props.field.text} />;
                break;
            case 'date':
                value = this.props.value ? moment(this.props.value).format('L') : null;
                break;
            case 'alert':
                value = (
                    <Alert severity={this.props.field.alertType}>
                        <AlertTitle>{this.props.field.title}</AlertTitle>
                        {this.props.field.text}
                    </Alert>
                );
                break;
            default:
                if (this.props.value) {
                    value = this.props.value;
                }
        }

        const ControlGroup = () => {
            if (this.props.field.typeId === 'textBlock' || this.props.field.typeId === 'alert') {
                return value;
            } else if (this.props.field.instructions) {
                return (
                    <Typography component={'div'}>
                        <Typography>{this.props.field.instructions}</Typography>
                        {label} : {value}
                    </Typography>
                );
            }
            return (
                <Typography component={'div'}>
                    {label} : {value}
                </Typography>
            );
        };
        return <ControlGroup></ControlGroup>;
    }
}

export default withAuthenticationContext(withStyles(styles)(SummaryField));
