import React, { Component } from 'react';
import Panel from '../../components/Panel';
import Table from '../../components/Table';
import { NotApplicableIcon } from '../../components/Icons';
import { CompleteIcon } from '../../components/Icons';
import { DeficientIcon } from '../../components/Icons';
import { InProgressIcon } from '../../components/Icons';
import { WaivedIcon } from '../../components/Icons';
import Moment from 'react-moment';

class StatusSymbol extends Component {
    render() {
        let statusIcon = '';
        switch (this.props.status) {
            case 'In-Progress':
                statusIcon = <InProgressIcon />;
                break;
            case 'Waived':
                statusIcon = <WaivedIcon />;
                break;
            case 'Completed':
                statusIcon = <CompleteIcon />;
                break;
            case 'Deficient':
                statusIcon = <DeficientIcon />;
                break;
            case 'Not Applicable':
                statusIcon = <NotApplicableIcon />;
                break;
            default:
                break;
        }
        return statusIcon;
    }
}
class LicensingChecklist extends Component {
    render() {
        let emptyMsg = 'No checklist data';
        if (this.props.responseCode === 404) {
            emptyMsg =
                'Your application has been submitted and is pending processing.  Please check back to see the application progress.';
        }

        return (
            <Panel title="Licensing Requirements">
                <Table
                    options={{
                        toolbar: false,
                        paging: false,
                    }}
                    noDataMessage={emptyMsg}
                    components={{
                        Container: ({ children }) => <div>{children}</div>,
                    }}
                    columns={[
                        { title: 'Item', field: 'item' },
                        { title: 'License Type', field: 'licenseType' },
                        {
                            title: 'Status',
                            field: 'status',
                            render: (rowData) => (
                                <div>
                                    {' '}
                                    <StatusSymbol status={rowData.status} /> {rowData.status}
                                </div>
                            ),
                        },
                        {
                            title: 'Date',
                            field: 'statusDate',
                            render: (rowData) =>
                                rowData.statusDate ? <Moment format="L">{rowData.statusDate}</Moment> : null,
                        },
                    ]}
                    data={this.props.checkListData}
                />
            </Panel>
        );
    }
}

export default LicensingChecklist;
