import { connect } from 'react-redux';
import { loadOrganizationsList, unloadOrganizationsList, applicationCreate } from '../store/RootActions';
import SelectOrganization from './SelectOrganization';

const mapStateToProps = ({ organizations }) => ({
    organizationsList: organizations.list,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (props) => {
        dispatch(loadOrganizationsList(props));
    },
    onUnload: () => {
        dispatch(unloadOrganizationsList());
    },
    onAppStart: (data, getAccessToken, authorizationScopes, userProfile) => {
        dispatch(
            applicationCreate({
                data,
                getAccessToken,
                authorizationScopes,
                userProfile,
            }),
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectOrganization);
