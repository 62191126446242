import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import PanelElements from '../PanelElements';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import panelStyles from '../../styles/panelStyles';
import { withSnackbar } from 'notistack';

const styles = panelStyles;

class PagePanels extends Component {
    componentDidMount() {
        this.props.onLoad();
    }

    handleCloseSnackBar = () => {
        this.props.clearCreateApplicationError();
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.page.pagePanels.eligibilityErrorMessage &&
            prevProps.page.pagePanels.eligibilityErrorMessage !== this.props.page.pagePanels.eligibilityErrorMessage
        ) {
            this.props.enqueueSnackbar(this.props.page.pagePanels.eligibilityErrorMessage, {
                onClose: this.handleCloseSnackBar,
                variant: 'error',
            });
        }
    }

    render(props, classes, className) {
        const columns = this.props.page.pagePanels.columns ? this.props.page.pagePanels.columns : [];

        const panels = columns.map((column, cIndex) => {
            return (
                <Grid
                    style={{ margin: '0px', width: '100%' }}
                    key={cIndex}
                    xs={12}
                    lg={column.size}
                    container
                    item
                    spacing={2}
                    direction="column"
                >
                    {column.panels.map((panel, pIndex) => {
                        return (
                            <Grid key={pIndex} item>
                                <Grow in={true}>
                                    <Paper
                                        component="section"
                                        className={classNames(this.props.classes.panel, className)}
                                    >
                                        <Typography className={this.props.classes.panelTitle} variant="h2">
                                            {panel.title}
                                        </Typography>
                                        <PanelElements elements={panel.elements} />
                                    </Paper>
                                </Grow>
                            </Grid>
                        );
                    })}
                </Grid>
            );
        });
        return (
            <Grid container spacing={2}>
                {panels}
            </Grid>
        );
    }
}

export default withStyles(styles)(withSnackbar(PagePanels));
