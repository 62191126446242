import { createLogic } from 'redux-logic';
import { loadOrganizationsList, loadedOrganizationsList } from '../../store/RootActions';
import { apiGet } from '../../api';

const organizationsListLoad = createLogic({
    type: loadOrganizationsList,
    latest: true,
    async process({ action }, dispatch, done) {
        const { getAccessToken, authorizationScopes, userProfile } = action.payload;
        const authToken = await getAccessToken(authorizationScopes.persons);

        const organizationsIds = userProfile?.organizations || [];

        let organizationsList = [];

        for (const organizationId of organizationsIds) {
            try {
                const result = await apiGet(`/persons/${organizationId}`, authToken, null, {
                    params: { filter: { fields: { id: true, name: true } } },
                });

                organizationsList.push(result.data);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    continue;
                } else {
                    break;
                }
            }
        }
        dispatch(loadedOrganizationsList(organizationsList));
        done();
    },
});

export default [organizationsListLoad];
