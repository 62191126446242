import { createLogic } from 'redux-logic';
import { siteInfoLoad, siteInfoLoaded } from '../RootActions';
import { apiGet } from '../../api';

const loadSiteInfo = createLogic({
    type: siteInfoLoad,
    latest: true,
    process(_, dispatch, done) {
        apiGet('siteInfo')
            .then((result) => {
                dispatch(siteInfoLoaded({ ...result.data, siteExists: true }));
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    dispatch(siteInfoLoaded({ siteExists: false }));
                }
            })
            .finally(done);
    },
});

export default [loadSiteInfo];
