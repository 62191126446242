import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuthenticationContext } from '../Authenticate';
import SubmissionStatusPanel from '../SubmissionStatusPanel';
import { apiGet } from '../api';

const styles = {
    title: {
        fontSize: 28,
        marginBottom: 25,
        fontWeight: 500,
    },
    message: {
        fontSize: 16,
    },
    field: {
        fontSize: 14,
        marginTop: 15,
        marginBottom: 15,
    },
    list: {
        marginTop: 30,
        marginBottom: 70,
    },
    dashboardLink: {
        textAlign: 'center',
    },
};

class SubmissionConfirmation extends Component {
    state = {
        applicationName: '',
        applicantName: '',
        balance: undefined,
    };

    async componentDidMount() {
        const token = await this.props.getAccessToken(this.props.authorizationScopes.applications);
        const applicationId = this.props.match.params.id;
        let application;

        try {
            application = (await apiGet(`applications/${applicationId}`, token)).data;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                this.props.history.push('/404');

                return;
            } else if (error.response && error.response.status === 403) {
                return;
            }

            throw error;
        }

        if (this.isSubmitted(application)) {
            this.setState({ applicationName: application.formName, applicantName: application.applicantName });
        } else {
            this.props.history.push('/404');

            return;
        }

        // Will be modified after implementation of 'Pay later' option.
        if (application.status === 'Started') {
            return;
        }

        const response = await apiGet(`applications/${applicationId}/fees`, token);

        const feeTotal = response.data
            .map((fee) => fee.amount)
            .reduce((feeAmount1, feeAmount2) => feeAmount1 + feeAmount2, 0);

        this.setState({ balance: feeTotal });
    }

    isSubmitted = (application) =>
        application.status === 'Submitted' ||
        (!!this.props.userProfile &&
            this.props.userProfile.id === application.userId &&
            !!application.assignee &&
            this.props.userProfile.id !== application.assignee.userId);

    render() {
        const confirmationMsg = 'Thank you for your submission. We have received your request.';

        const confirmationFields = [];

        if (this.state.applicationName) {
            confirmationFields.push({
                fieldLabel: 'Request Type',
                fieldData: this.state.applicationName,
            });
        }

        if (this.state.applicantName) {
            confirmationFields.push({
                fieldLabel: 'Name',
                fieldData: this.state.applicantName,
            });
        }

        return <SubmissionStatusPanel message={confirmationMsg} confirmationFields={confirmationFields} />;
    }
}

export default compose(withStyles(styles), withAuthenticationContext)(SubmissionConfirmation);
