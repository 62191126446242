import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 50,
            marginRight: 50,
        },
    },
}));

const Layout = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.content}>{children}</div>;
};

export default Layout;
