import React from 'react';
import { compose } from 'redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormDisplay from './FormDisplay';
import Button from '../components/Button';
import { Typography } from '@material-ui/core';
import Table from '../components/Table';
import Link from '@material-ui/core/Link';
import { MTableBodyRow } from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import { apiGet } from '../api';
import { getRequiredScopes, withAuthenticationContext } from '../Authenticate';
import { composeParameters, getSortName } from '../util';
import { cloneDeep, get } from 'lodash';
import jp from 'jsonpath';

const styles = (theme) => ({
    dialogActions: {
        padding: '20px 24px',
    },
    tableRow: {
        '&:hover $addButton': {
            visibility: 'visible',
            transition: theme.transitions.create('visibility', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },
    addButton: {
        visibility: 'hidden',
    },
});

let resultColumnsWithNameType = [];

class SearchForm extends React.Component {
    state = {
        searchMode: 'search',
        searchData: {},
        searchResults: [],
        searchMappedData: {},
    };

    setStateAsync = (state) => {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    };

    handleDataChange = async (name, value) => {
        await this.setStateAsync((prevState) => {
            const newState = {
                searchData: {
                    ...prevState.searchData,
                    [name]: value,
                },
            };

            return newState;
        });
    };

    composeFieldsFilter = (mappings) => {
        const fields = mappings
            .map((resultField) => resultField.field)
            .filter((field) => field)
            .map((field) => field.split('.')[0]);
        const uniqueFields = [...new Set(fields)];
        const fieldParams = uniqueFields.map(
            (field) => `${encodeURIComponent('filter[fields]')}=${encodeURIComponent(`${field}`)}`,
        );
        return fieldParams.join('&');
    };

    handleSearch = (event) => {
        const field = this.props.field;
        const api = field.searchForm.api;

        const whereFilter = composeParameters(api.queryParams, this.state.searchData);
        const fieldsFilter = this.composeFieldsFilter(api.resultMappings);
        const queryParams = whereFilter.concat('&', fieldsFilter);

        const scopes = getRequiredScopes(api.url, this.props.authorizationScopes);
        const tokenPromise = scopes ? this.props.getAccessToken(scopes) : Promise.resolve(undefined);

        tokenPromise
            .then((token) => {
                return apiGet(`${api.url}?${queryParams}`, token);
            })
            .then(async (result) => {
                const results = result.data;
                this.setState({
                    searchMode: 'results',
                    searchResults: results,
                    searchMappedData: await this.generateMappedData(this.state.searchData),
                });
            })
            .catch();

        event.preventDefault();
    };

    transformSearchResult = () => {
        const results = cloneDeep(this.state.searchResults);
        if (resultColumnsWithNameType.length > 0) {
            return results.map((entry) => {
                resultColumnsWithNameType.forEach((field) => {
                    if (entry[field]) {
                        entry[field] = getSortName(entry[field]);
                    }
                });
                return entry;
            });
        }
        return results;
    };

    transformResultMapping = () => {
        return this.props.field.searchForm.api.resultMappings.map((field) => {
            if (field.type === 'name') {
                if (field.field) {
                    resultColumnsWithNameType.push(field.field);
                }
                const { type, ...rest } = field;
                return rest;
            }
            return field;
        });
    };

    generateMappedData = async (data) => {
        const mappedData = {};
        const fields = this.props.field.searchForm.fields;
        fields.forEach((entry) => {
            let value = data[entry.field];
            const jsonPath = entry.fieldMapping?.targetPath;
            if (jsonPath) {
                if (entry.typeId === 'boolean' && !value) {
                    value = false;
                }
                jp.value(mappedData, jsonPath, value);
            }
        });
        return mappedData;
    };

    handleAddRecord = (data) => {
        let record = {};
        const rowData = this.state.searchResults[data?.tableData?.id];
        if (rowData) {
            this.props.field.searchForm.api.resultMappings.forEach((item) => {
                if (item.mapping) {
                    record[item.mapping] = get(rowData, item.field);
                }
            });
        }
        this.props.onAddResult(record, this.state.searchMappedData);
    };

    handleBack = (event) => {
        this.setState({
            searchMode: 'search',
        });
        event.preventDefault();
    };

    render() {
        const pagination = this.state.searchResults.length > 0;
        switch (this.state.searchMode) {
            case 'results':
                return (
                    <form>
                        <DialogTitle>{this.props.field.label}</DialogTitle>
                        <DialogContent>
                            <Typography>Select from the existing list.</Typography>
                            <Table
                                options={{
                                    toolbar: false,
                                    paging: pagination,
                                    emptyRowsWhenPaging: false,
                                    draggable: false,
                                    cellStyle: {
                                        borderBottom: 'none',
                                        padding: '10px 16px',
                                    },
                                    headerStyle: {
                                        borderBottom: 'none',
                                        padding: '10px 16px',
                                    },
                                }}
                                noDataMessage="No matching results"
                                columns={[
                                    ...this.transformResultMapping().filter((field) => field.title),
                                    {
                                        render: () => {
                                            return (
                                                <Typography
                                                    variant="button"
                                                    color="primary"
                                                    className={this.props.classes.addButton}
                                                >
                                                    Add
                                                </Typography>
                                            );
                                        },
                                        cellStyle: {
                                            padding: 0,
                                            borderBottom: 'none',
                                        },
                                        width: 'fit-content',
                                        sorting: false,
                                    },
                                ]}
                                data={this.transformSearchResult()}
                                onRowClick={(event, row) => this.handleAddRecord(row)}
                                components={{
                                    Row: (props) => {
                                        return <MTableBodyRow className={this.props.classes.tableRow} {...props} />;
                                    },
                                }}
                            />
                            {this.props.field.searchForm.addRecordText && (
                                <Typography>
                                    Not Listed?{' '}
                                    <Link href="#" onClick={this.handleAddRecord}>
                                        {this.props.field.searchForm.addRecordText}
                                    </Link>
                                </Typography>
                            )}
                        </DialogContent>
                        <DialogActions classes={{ root: this.props.classes.dialogActions }}>
                            <Button variant="contained" onClick={this.props.onClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={this.handleBack}>
                                Back
                            </Button>
                        </DialogActions>
                    </form>
                );
            default:
                return (
                    <form onSubmit={this.handleSearch}>
                        <DialogTitle>{this.props.field.label}</DialogTitle>
                        <DialogContent>
                            <FormDisplay
                                fields={this.props.field.searchForm ? this.props.field.searchForm.fields : []}
                                fieldData={this.state.searchData}
                                onChange={this.handleDataChange}
                                onCommit={this.handleDataChange}
                                errors={{}}
                                visibility={{}}
                                enabledStatus={{}}
                            />
                        </DialogContent>
                        <DialogActions classes={{ root: this.props.classes.dialogActions }}>
                            <Button onClick={this.props.onClose}>Cancel</Button>
                            <Button type="submit" variant="contained">
                                Search
                            </Button>
                        </DialogActions>
                    </form>
                );
        }
    }
}

export default compose(withStyles(styles), withAuthenticationContext)(SearchForm);
