import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EventType, InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import hoistStatics from 'hoist-non-react-statics';
import { apiPost } from '../api';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { getMsalConfig, getScopes } from './authConfig';

export default function Authenticate(props) {
    const tenantAuthConfig = useSelector((state) => state.siteInfo.authentication);
    const [userProfile, setUserProfile] = useState();
    const authScopes = getScopes(tenantAuthConfig);

    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    /*
     * Attempt to automatically link the user profile by email. The user profile
     * should be returned whether or not the linking is successful.
     */
    const logout = async () => {
        await instance.handleRedirectPromise();
        if (account) {
            instance.logoutRedirect();
        }
    };

    useEffect(() => {
        async function getUserProfile() {
            const userId = account?.localAccountId;
            const authToken = await getAccessToken(authScopes.userProfiles);
            const response = await apiPost(`/userProfiles/${userId}/personLink`, null, authToken);
            setUserProfile(response.data);
        }

        if (instance) {
            if (account?.localAccountId) {
                getUserProfile();
            } else {
                setUserProfile(undefined);
            }
        }
    }, [account && account.localAccountId]);

    useEffect(() => {
        const passwordResetURL = getMsalConfig(tenantAuthConfig).forgotPasswordRequest;
        instance.enableAccountStorageEvents();
        const callbackId = instance.addEventCallback(async (event) => {
            if (event?.eventType === EventType.LOGIN_SUCCESS) {
                if (event?.payload?.authority?.includes(tenantAuthConfig.policies.passwordReset)) {
                    await instance.handleRedirectPromise();
                    return instance.logoutRedirect();
                } else {
                    instance.setActiveAccount(event?.payload?.account);
                }
            } else if (event.eventType === EventType.ACCOUNT_REMOVED) {
                instance.logoutRedirect();
            } else if (
                event?.eventType === EventType.LOGIN_FAILURE &&
                event?.interactionType === InteractionType.Redirect
            ) {
                if (event?.error?.errorMessage?.includes('AADB2C90118')) {
                    return instance.loginRedirect(passwordResetURL);
                } else if (event?.error?.errorMessage?.includes('AADB2C90091')) {
                    return instance.loginRedirect();
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);

    const getAccessToken = useCallback(
        async (scopes) =>
            instance
                ? instance
                      .acquireTokenSilent({ scopes, account })
                      .then((response) => {
                          return response.accessToken;
                      })
                      .catch((error) => {
                          if (error instanceof InteractionRequiredAuthError) {
                              // fallback to interaction when silent call fails
                              logout();
                          }
                      })
                : null,
        [instance],
    );

    return (
        <MsalAuthenticationTemplate interactionType="redirect">
            {!!account && (
                <AuthenticationContext.Provider
                    value={{
                        account: {
                            ...account,
                            name:
                                account?.name ??
                                `${account?.idTokenClaims?.given_name} ${account?.idTokenClaims?.family_name}`,
                        },
                        logout,
                        getAccessToken,
                        authorizationScopes: authScopes,
                        userProfile,
                        updateUserProfile: (updatedProfile) => {
                            setUserProfile(updatedProfile);
                        },
                    }}
                >
                    {props.children}
                </AuthenticationContext.Provider>
            )}
        </MsalAuthenticationTemplate>
    );
}

const AuthenticationContext = React.createContext();
AuthenticationContext.displayName = 'AuthenticationContext';

export function withAuthenticationContext(Component) {
    const WithAuthenticationCtx = function (props) {
        return (
            <AuthenticationContext.Consumer>
                {(value) => (
                    <Component
                        account={value.account}
                        logout={value.logout}
                        getAccessToken={value.getAccessToken}
                        authorizationScopes={value.authorizationScopes}
                        userProfile={value.userProfile}
                        updateUserProfile={value.updateUserProfile}
                        {...props}
                    />
                )}
            </AuthenticationContext.Consumer>
        );
    };

    WithAuthenticationCtx.displayName = `withAuthenticationContext(${Component.displayName | Component.name})`;

    return hoistStatics(WithAuthenticationCtx, Component);
}

export function useAccount() {
    return useContext(AuthenticationContext).account;
}

export function useLogout() {
    return useContext(AuthenticationContext).logout;
}

export function useAccessTokens() {
    const { getAccessToken, authorizationScopes } = useContext(AuthenticationContext);

    return { getAccessToken, authorizationScopes };
}

export function useUserProfile() {
    return useContext(AuthenticationContext).userProfile;
}
