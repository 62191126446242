import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import moment from 'moment';
import NavLink from '../components/NavLink';

const useStyles = makeStyles((theme) => ({
    backButton: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#484848',
        lineHeight: '24px',
        marginLeft: -7,
    },
    licenseDetailContainer: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    },
    personName: {
        fontSize: 36,
        color: '#333333',
        lineHeight: '44px',
    },
    licenseType: {
        fontSize: 18,
        color: '#666666',
        lineHeight: '28px',
    },
    licenseDetailTitle: {
        fontSize: 14,
        color: '#666666',
        lineHeight: '20px',
    },
    licenseDetailBody: {
        fontSize: 16,
        color: '#333333',
        lineHeight: '24px',
        marginBottom: theme.spacing(2),
    },
}));

import React from 'react';

const LicenseBasicDetail = ({ license }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <NavLink to="/" variant="subtitle1">
                    <span className={classes.backButton}>
                        <BackIcon />
                        Dashboard
                    </span>
                </NavLink>
            </Grid>
            <Grid container item>
                <Grid item md={9} xs={12}>
                    <Typography className={classes.personName}>{license?.person.name}</Typography>
                    <Typography className={classes.licenseType}>{license?.type.name}</Typography>
                </Grid>
                <Grid container item xs={12} sm={6} className={classes.licenseDetailContainer}>
                    <Grid item xs={6}>
                        {license?.profession && (
                            <>
                                <Typography className={classes.licenseDetailTitle}>Profession</Typography>
                                <Typography className={classes.licenseDetailBody}>{license.profession.name}</Typography>
                            </>
                        )}
                        <Typography className={classes.licenseDetailTitle}>License Number</Typography>
                        <Typography className={classes.licenseDetailBody}>{license?.licenseNumber}</Typography>
                        <Typography className={classes.licenseDetailTitle}>Status</Typography>
                        <Typography className={classes.licenseDetailBody}>{license?.status}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.licenseDetailTitle}>Issue Date</Typography>
                        <Typography className={classes.licenseDetailBody}>
                            {moment(license?.issueDate).format('L')}
                        </Typography>
                        <Typography className={classes.licenseDetailTitle}>Expiration Date</Typography>
                        <Typography className={classes.licenseDetailBody}>
                            {moment(license.expirationDate).format('L')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LicenseBasicDetail;
