import React from 'react';
import { compose } from 'redux';
import { withAuthenticationContext } from '../Authenticate';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Panel from '../components/Panel';
import NavLink from '../components/NavLink';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '../components/Button';
import { withSnackbar } from 'notistack';

const styles = (theme) => ({
    panel: {
        textAlign: 'center',
    },
    content: {
        marginTop: theme.spacing(6),
    },
    actionBar: {
        marginTop: theme.spacing(6),
    },
    footer: {
        marginTop: theme.spacing(6),
    },
    formControl: {
        minWidth: 250,
    },
    actionButton: {
        marginLeft: 10,
        marginRight: 10,
    },
});

class FormFinder extends React.Component {
    state = {
        stepProgress: [],
        currentStep: undefined,
    };

    componentDidMount() {
        this.props.onLoad({
            id: this.props.match.params.id,
            getAccessToken: this.props.getAccessToken,
            authorizationScopes: this.props.authorizationScopes,
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.questionnaire.id &&
            (!prevProps.questionnaire.id || prevProps.questionnaire.id !== this.props.questionnaire.id)
        ) {
            this.setState({
                currentStep: 0,
                stepProgress: [{ step: this.props.questionnaire.firstStep, selectedChoice: '' }],
            });
        }

        if (
            this.props.eligibilityErrorMessage &&
            prevProps.eligibilityErrorMessage !== this.props.eligibilityErrorMessage
        ) {
            this.props.enqueueSnackbar(this.props.eligibilityErrorMessage, {
                onClose: this.handleCloseSnackBar,
                variant: 'error',
            });
        }
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleCloseSnackBar = () => {
        this.props.clearFormFinderCreateAppError();
    };

    getCurrentStep() {
        return this.state.stepProgress[this.state.currentStep];
    }

    hasNext(currentStep) {
        const stepDefinition = this.props.questionnaire.steps[currentStep.step];

        return stepDefinition.continueWith || stepDefinition.choices;
    }

    hasForm(currentStep) {
        return this.props.questionnaire.steps[currentStep.step].form;
    }

    nextStepLabel(currentStep) {
        const stepDefinition = this.props.questionnaire.steps[currentStep.step];

        return stepDefinition.form ? 'Start Application' : 'Next';
    }

    canContinue(currentStep) {
        const stepDefinition = this.props.questionnaire.steps[currentStep.step];

        return stepDefinition.form || currentStep.selectedChoice !== '';
    }

    handleSelection = (event) => {
        this.setState((prevState) => ({
            ...prevState,
            stepProgress: [
                ...prevState.stepProgress.slice(0, prevState.currentStep),
                {
                    ...prevState.stepProgress[prevState.currentStep],
                    selectedChoice: event.target.value,
                },
                ...prevState.stepProgress.slice(prevState.currentStep + 1),
            ],
        }));
    };

    handleNext = () => {
        this.setState((prevState) => {
            const stepData = prevState.stepProgress[prevState.currentStep];
            const choice = this.props.questionnaire.steps[stepData.step].choices[stepData.selectedChoice];

            return {
                ...prevState,
                stepProgress: [
                    ...prevState.stepProgress.slice(0, prevState.currentStep + 1),
                    {
                        step: choice.continueWith,
                        selectedChoice: '',
                    },
                ],
                currentStep: prevState.currentStep + 1,
            };
        });
    };

    handleBack = () => {
        this.setState((prevState) => ({
            ...prevState,
            currentStep: prevState.currentStep - 1,
        }));
    };

    handleStart = () => {
        const currentStep = this.getCurrentStep();
        const stepDefinition = this.props.questionnaire.steps[currentStep.step];

        this.props.onAppStart(
            stepDefinition,
            this.props.getAccessToken,
            this.props.authorizationScopes,
            this.props.userProfile,
        );
    };

    render() {
        if (this.state.currentStep === undefined) {
            return null;
        }

        const currentStep = this.getCurrentStep();
        const stepDefinition = this.props.questionnaire.steps[currentStep.step];

        return (
            <Grid container justifyContent="center">
                <Grid item xs={12} md={9} lg={6}>
                    <Panel key={currentStep.step} className={this.props.classes.panel}>
                        <Typography variant="h2">{stepDefinition.text}</Typography>
                        <div className={this.props.classes.content}>
                            {stepDefinition.choices && (
                                <FormControl className={this.props.classes.formControl}>
                                    <InputLabel htmlFor="answer">Please select an answer</InputLabel>
                                    <Select
                                        value={currentStep.selectedChoice}
                                        onChange={this.handleSelection}
                                        inputProps={{ id: 'answer' }}
                                    >
                                        {stepDefinition.choices.map((choice, index) => (
                                            <MenuItem key={index} value={index}>
                                                {choice.choice}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                        <div className={this.props.classes.actionBar}>
                            {this.state.currentStep > 0 && (
                                <Button className={this.props.classes.actionButton} onClick={this.handleBack}>
                                    Back
                                </Button>
                            )}
                            {this.hasNext(currentStep) && (
                                <Button
                                    variant="contained"
                                    disabled={!this.canContinue(currentStep)}
                                    onClick={this.handleNext}
                                    className={this.props.classes.actionButton}
                                >
                                    Next
                                </Button>
                            )}
                            {this.hasForm(currentStep) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleStart}
                                    className={this.props.classes.actionButton}
                                >
                                    Start Application
                                </Button>
                            )}
                        </div>
                        <div className={this.props.classes.footer}>
                            <NavLink to="/">Return to Dashboard</NavLink>
                        </div>
                    </Panel>
                </Grid>
            </Grid>
        );
    }
}

export default compose(withRouter, withStyles(styles), withAuthenticationContext, withSnackbar)(FormFinder);
