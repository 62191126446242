import React from 'react';
import Panel from './components/Panel';
import NavLink from './components/NavLink';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 'inherit',
    },
    content: {
        textAlign: 'center',
    },
    line1: {
        fontWeight: 'bold',
        fontSize: 55,
        marginBottom: 30,
    },
    line2: {
        fontSize: 21,
        marginBottom: 15,
    },
    line3: {
        fontSize: 16,
    },
}));

export default function EntityNotLinkedError() {
    const classes = useStyles();

    return (
        <div>
            <Panel className={classes.content}>
                <Typography className={classes.line1}>Sorry!</Typography>
                <Typography className={classes.line2}>
                    You don&apos;t have any records. Please return to the dashboard to search for your record.
                </Typography>

                <div>
                    <Typography display="inline" className={classes.line3}>
                        Return to{' '}
                    </Typography>
                    <NavLink to="/" display="inline" linkProps={{ className: classes.link }} className={classes.line3}>
                        Dashboard
                    </NavLink>
                </div>
            </Panel>
        </div>
    );
}

export const entityNotLinkedPath = '/entityNotLinked';
