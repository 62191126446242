function b2cAuthority(tenantId, tenantName, policy) {
    return `https://${tenantName}.b2clogin.com/${tenantId}/${policy}`;
}

function b2cScope(tenantName, service, resource, permission) {
    return `https://${tenantName}.onmicrosoft.com/${service}/${resource}.${permission}`;
}

export const getMsalConfig = (authConfig) => {
    const msalConfig = {
        auth: {
            authority: b2cAuthority(authConfig.b2cTenantId, authConfig.b2cTenantName, authConfig.policies.signIn),
            knownAuthorities: [`${authConfig.b2cTenantName}.b2clogin.com`],
            clientId: authConfig.clientId,
            postLogoutRedirectUri: authConfig.postLogoutRedirectUrl || window.location.origin,
            redirectUri: window.location.origin,
        },
        system: {
            allowRedirectInIframe: true,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
        forgotPasswordRequest: {
            authority: b2cAuthority(
                authConfig.b2cTenantId,
                authConfig.b2cTenantName,
                authConfig.policies.passwordReset,
            ),
        },
    };
    return msalConfig;
};

export const getScopes = (authConfig) => {
    const scopes = {
        applications: [
            b2cScope(authConfig.b2cTenantName, 'formservice', 'applications', 'read-self'),
            b2cScope(authConfig.b2cTenantName, 'formservice', 'applications', 'write-self'),
        ],
        forms: [b2cScope(authConfig.b2cTenantName, 'formservice', 'forms', 'read')],
        formFinders: [b2cScope(authConfig.b2cTenantName, 'formservice', 'formFinders', 'read')],
        payments: [
            b2cScope(authConfig.b2cTenantName, 'paymentservice', 'payments', 'read-self'),
            b2cScope(authConfig.b2cTenantName, 'paymentservice', 'payments', 'write-self'),
        ],
        userProfiles: [
            b2cScope(authConfig.b2cTenantName, 'userprofileservice', 'userProfiles', 'read-self'),
            b2cScope(authConfig.b2cTenantName, 'userprofileservice', 'userProfiles', 'write-self'),
        ],
        licenses: [
            b2cScope(authConfig.b2cTenantName, 'licensingservice', 'licenses', 'read-self'),
            b2cScope(authConfig.b2cTenantName, 'licensingservice', 'licenses', 'read-public'),
        ],
        mergeTemplates: [b2cScope(authConfig.b2cTenantName, 'mergetemplateservice', 'mergeTemplates', 'read')],
        persons: [
            b2cScope(authConfig.b2cTenantName, 'licensingservice', 'persons', 'read-self'),
            b2cScope(authConfig.b2cTenantName, 'licensingservice', 'persons', 'read-public'),
            b2cScope(authConfig.b2cTenantName, 'licensingservice', 'persons', 'manage-users'),
            b2cScope(authConfig.b2cTenantName, 'licensingservice', 'persons', 'write-self'),
        ],
        codedValues: [b2cScope(authConfig.b2cTenantName, 'schemaservice', 'codedValues', 'read')],
        jurisdictions: [b2cScope(authConfig.b2cTenantName, 'jurisdictionservice', 'jurisdictions', 'read')],
        locations: [b2cScope(authConfig.b2cTenantName, 'mapservice', 'locations', 'read')],
        journalEntries: [b2cScope(authConfig.b2cTenantName, 'accountingservice', 'journalEntries', 'read-self')],
    };

    return scopes;
};
