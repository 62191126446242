import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './store/RootReducer';
import rootLogic from './store/RootLogic';

export const history = createBrowserHistory({
    basename: '/',
});

export default function configureStore() {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        //preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                createLogicMiddleware(rootLogic),
            ),
        ),
    );

    return store;
}
