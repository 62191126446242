export const isSubmittedStatus = (status) => {
    return ['Submitted', 'Approved', 'Denied'].includes(status);
};

export const getSortName = (nameValue) => {
    if (typeof nameValue === 'string') {
        return nameValue;
    }

    const firstMiddle = [nameValue.firstName, nameValue.middleName].filter((x) => x).join(' ');

    return [nameValue.lastName, firstMiddle, nameValue.nameSuffix].filter((x) => x).join(', ');
};

export const findDeleteField = (fields) => {
    const deleteField = fields?.find((field) => field.fieldMapping?.targetPath === '$.delete');
    const deleteFieldName = deleteField?.field;

    return deleteFieldName;
};

export function generateAddress(address) {
    if (!address) {
        return '';
    }

    return [address.line1, address.city, (address.state ?? '') + ' ' + (address.zipcode ?? '')]
        .filter(Boolean)
        .join(', ');
}
