import { handleActions } from 'redux-actions';
import { formFinderAppCreateFailed, formFinderLoaded, formFinderUnload } from './actions';

const initialState = {
    questionnaire: {},
};

export default handleActions(
    {
        [formFinderLoaded]: (state, action) => ({
            ...state,
            questionnaire: action.payload,
        }),
        [formFinderUnload]: () => initialState,
        [formFinderAppCreateFailed]: (state, action) => ({
            ...state,
            eligibilityErrorMessage: action.payload,
        }),
    },
    initialState,
);
