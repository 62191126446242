import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Grid, Typography, Paper, List as MuiList, ListItem as MuiListItem, Grow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import NavLink from './components/NavLink';
import { useAccessTokens } from './Authenticate';
import { generateAddress } from './util';
import { apiGet } from './api';
import ApplicationActivity from './ApplicationActivity';

const useStyles = makeStyles(() => ({
    downloadLink: {
        cursor: 'pointer',
    },
    backButton: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        lineHeight: '24px',
        marginLeft: -7,
    },
    entityName: {
        fontSize: 30,
        color: '#333333',
        letterSpacing: ' -0.56px',
        lineHeight: '44px',
    },
    personDocuments: {
        color: '#333333',
        fontSize: 24,
        fontWeight: 500,
        letterSpacing: '0',
        lineHeight: '32px',
    },
}));

export default function Activity({ location }) {
    const classes = useStyles();

    const { name, id, address } = location.state;
    const { getAccessToken, authorizationScopes } = useAccessTokens();
    const [activities, setActivities] = useState([]);
    const [deletedActivity, setDeletedActivity] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const userProfile = useSelector(({ userProfile }) => userProfile);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            const token = await getAccessToken(authorizationScopes.applications);

            const applications = (
                await apiGet('applications', token, null, {
                    params: { filter: { where: { personId: id } } },
                })
            ).data;

            setActivities(applications);

            setIsLoading(false);
        })();
    }, [setActivities, getAccessToken]);

    useEffect(() => {
        if (deletedActivity) {
            setActivities((prevActivites) =>
                prevActivites.filter((prevActivity) => prevActivity.id !== deletedActivity),
            );
        }

        return () => setDeletedActivity(undefined);
    }, [deletedActivity, setDeletedActivity, setActivities]);

    return (
        <>
            <Grid container spacing={2} direction="column" columns={12}>
                <Grid item>
                    <NavLink to="/" variant="subtitle1">
                        <span className={classes.backButton}>
                            <BackIcon />
                            Dashboard
                        </span>
                    </NavLink>
                </Grid>
                <Grid item>
                    <Typography className={classes.entityName}>{name}</Typography>
                    <Typography variant="subtitle1">{generateAddress(address)}</Typography>
                </Grid>
                <Grid item container justifyContent="center" direction="column">
                    <Grid item xs style={{ marginTop: 50, marginLeft: 100, marginRight: 100, height: '100%' }}>
                        <Grow in={true}>
                            <Paper>
                                {isLoading ? (
                                    <Skeleton />
                                ) : activities.length ? (
                                    <MuiList>
                                        {activities.map((application, index) => (
                                            <MuiListItem
                                                key={index}
                                                style={{
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                }}
                                            >
                                                <ApplicationActivity
                                                    getAccessToken={getAccessToken}
                                                    authorizationScopes={authorizationScopes}
                                                    key={index}
                                                    applicationId={application.id}
                                                    status={application.status}
                                                    formName={application.formName}
                                                    assignee={application.assignee}
                                                    userId={application.userId}
                                                    userProfile={userProfile}
                                                    setDeletedActivity={setDeletedActivity}
                                                    component="Activity"
                                                />
                                            </MuiListItem>
                                        ))}
                                    </MuiList>
                                ) : (
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        style={{ paddingTop: 50, paddingBottom: 50 }}
                                    >
                                        No activity
                                    </Typography>
                                )}
                            </Paper>
                        </Grow>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
