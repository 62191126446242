import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '../components/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormDisplay from './FormDisplay';
import Button from '../components/Button';
import SearchForm from './SearchForm';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { findDeleteField } from '../util';
import { apiPost } from '../api';

const Interpolator = require('string-interpolation');
const interpolator = new Interpolator();

const styles = () => ({
    list: {
        maxWidth: 400,
    },
    empty: {
        fontStyle: 'italic',
    },
    button: {
        maxWidth: 100,
    },
    dialogActions: {
        padding: '20px 24px',
    },
    deleteMessage: {
        color: '#B71C1C',
        fontWeight: '500',
    },
    deletedItem: {
        color: '#6E7788',
        maxWidth: 235,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

class FieldList extends React.Component {
    state = {
        dialogMode: '',
        editIndex: null,
        nestedFieldData: {},
        nestedFieldErrors: {},
        nestedFieldVisibility: {},
        nestedFieldEnabledStatus: {},
        searchMappedData: {},
    };

    evaluateInstance = (data, options) => {
        const entryIndexQuery =
            options?.entryIndex !== null && options?.entryIndex !== undefined
                ? `&entryIndex=${options.entryIndex}`
                : '';
        const response = this.props
            .getAccessToken(this.props.authorizationScopes.applications)
            .then((token) =>
                apiPost(
                    `applications/${this.props.applicationId}/fields/${
                        this.props.field.field
                    }/evaluateInstance?validateDefinedOnly=${options?.validateDefinedOnly || false}${entryIndexQuery}`,
                    data,
                    token,
                ),
            );

        return response;
    };

    formatEvaluationResults = (results) => {
        let formattedResults;
        let instanceHasErrors = false;
        for (const field in results) {
            instanceHasErrors = results[field].error || instanceHasErrors;
            formattedResults = {
                nestedFieldErrors: {
                    ...formattedResults?.nestedFieldErrors,
                    [field]: {
                        error: results[field].error,
                        messages: results[field].errorMessages,
                    },
                },
                nestedFieldVisibility: {
                    ...formattedResults?.nestedFieldVisibility,
                    [field]: results[field].visible,
                },
                nestedFieldEnabledStatus: {
                    ...formattedResults?.nestedFieldEnabledStatus,
                    [field]: results[field].enabled,
                },
            };
        }
        return { instanceHasErrors, resultState: formattedResults };
    };

    runAndFormatRuleEvaluation = (data, options) => {
        const results = this.evaluateInstance(data, options).then((results) => {
            return this.formatEvaluationResults(results.data.fields);
        });

        return results;
    };

    handleAddClick = async () => {
        this.setState({ dialogMode: this.props.field.searchForm ? 'search' : 'add', editIndex: null });
        const data = await this.props.ruleEngine.initializeMultiFieldInstance(this.props.field.field);

        await this.initializeDialog(data, null);
    };

    handleEditClick = async (index, data) => {
        this.setState({ dialogMode: 'edit', editIndex: index });

        await this.initializeDialog(data, index);
    };

    initializeDialog = async (data, entryIndex) => {
        this.props.ruleEngine.resetMultiFieldData();

        // First reset state, run an initial pass to determine which fields to
        // display, and finally display the dialog.
        this.setState({
            nestedFieldData: data,
            nestedFieldErrors: {},
            nestedFieldVisibility: {},
            nestedFieldEnabledStatus: {},
        });

        this.runAndFormatRuleEvaluation(data, {
            validateDefinedOnly: true,
            entryIndex,
        }).then((results) => {
            this.setState({ nestedFieldData: data, ...results.resultState });
        });
    };

    handleCloseDialog = () => {
        this.setState({ dialogMode: '' });
    };

    setStateAsync = (state) => {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    };

    handleNestedChange = async (name, value) => {
        await this.setStateAsync((prevState) => ({ nestedFieldData: { ...prevState.nestedFieldData, [name]: value } }));
        const results = await this.runAndFormatRuleEvaluation(this.state.nestedFieldData, {
            validateDefinedOnly: true,
        });
        await this.setStateAsync(results.resultState);
    };

    handleAddListValue = (event) => {
        // Use promise .then since handleAddListValue must return false.
        this.runAndFormatRuleEvaluation(this.state.nestedFieldData).then((results) => {
            this.setState(results.resultState);
            if (!results.instanceHasErrors) {
                this.props.onAdd(this.state.nestedFieldData);
                this.handleCloseDialog();
            }
        });

        event.preventDefault();

        return false;
    };

    handleAddSearchResult = async (data, searchMappedData) => {
        this.setState({
            dialogMode: 'add',
            nestedFieldData: data,
            editIndex: null,
            searchMappedData,
        });

        await this.initializeDialog(data, null);
    };

    handleSave = (event) => {
        // Use promise .then since handleSave must return false.

        this.runAndFormatRuleEvaluation(this.state.nestedFieldData).then((results) => {
            this.setState(results.resultState);
            if (!results.instanceHasErrors) {
                this.props.onUpdate(this.state.editIndex, this.state.nestedFieldData);
                this.handleCloseDialog();
            }
        });

        event.preventDefault();

        return false;
    };

    evalExpression = (data, expression, field) => {
        const dataFields = Object.assign({}, data);

        field.fields.forEach((field) => {
            if (field.typeId === 'choices' && field.choices) {
                field.choices.forEach(function (choice) {
                    //Get the label for the choice that matches the data value
                    if (data[field.field] && choice.value && data[field.field].toString() === choice.value.toString()) {
                        dataFields[field.field] = choice.label;
                    }
                });
            }
            if (field.typeId === 'date') {
                dataFields[field.field] = data[field.field] ? moment(data[field.field]).format('MM/DD/YYYY') : '';
            }
        });

        if (expression === undefined) {
            return undefined;
        }

        return interpolator.parse(expression, dataFields);
    };

    render() {
        let itemList;

        const RecordForm = (
            <form onSubmit={this.state.dialogMode === 'add' ? this.handleAddListValue : this.handleSave}>
                <DialogTitle>{this.props.field.label}</DialogTitle>
                <DialogContent>
                    <FormDisplay
                        fields={
                            this.props.field.fields || [
                                { field: this.props.field.field, label: this.props.field.label },
                            ]
                        }
                        searchMappedData={this.state.searchMappedData}
                        parentRepeatableFieldKey={this.props.field.key}
                        parentRepeatableFieldData={this.props.applicationData[this.props.field.field]}
                        fieldData={this.state.nestedFieldData}
                        errors={this.state.nestedFieldErrors}
                        visibility={this.state.nestedFieldVisibility}
                        enabledStatus={this.state.nestedFieldEnabledStatus}
                        onChange={this.handleNestedChange}
                        onCommit={this.handleNestedChange}
                        authorizationScopes={this.props.authorizationScopes}
                        getAccessToken={this.props.getAccessToken}
                        workflowStatus={this.props.workflowStatus}
                    />
                </DialogContent>
                <DialogActions classes={{ root: this.props.classes.dialogActions }}>
                    <Button onClick={this.handleCloseDialog}>Cancel</Button>
                    <Button type="submit" variant="contained">
                        {this.state.dialogMode === 'add' ? 'Add' : 'Save'}
                    </Button>
                </DialogActions>
            </form>
        );

        if (!this.props.values || this.props.values.length === 0) {
            itemList = (
                <List className={this.props.classes.list}>
                    <ListItem>
                        <ListItemText primary="None" classes={{ primary: this.props.classes.empty }} />
                    </ListItem>
                </List>
            );
        } else {
            const displayExpression = this.props.field.displayExpression || `{${this.props.field.field}}`;

            const undefinedChoices = this.props.field.fields
                .filter((field) => {
                    return field.typeId === 'choices';
                })
                .some((field) => {
                    return field.choices === undefined;
                });

            const deleteFieldName = findDeleteField(this.props.field.fields);

            itemList = (
                <List className={this.props.classes.list}>
                    {this.props.values.map((item, index) => {
                        const itemDisplay = this.evalExpression(item, displayExpression, this.props.field);

                        //If there is a dropdown where the field choices are undefined, then the label will not be returned
                        // and itemDisplay will not be able to display the label

                        if (!undefinedChoices) {
                            return (
                                <ListItem
                                    key={index}
                                    button={!item[deleteFieldName]}
                                    onClick={() => {
                                        !item[deleteFieldName] && this.handleEditClick(index, item);
                                    }}
                                    disabled={this.props.disabled}
                                >
                                    <ListItemText
                                        primary={
                                            <>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <Typography
                                                            className={
                                                                item[deleteFieldName] && this.props.classes.deletedItem
                                                            }
                                                        >
                                                            {itemDisplay}
                                                        </Typography>
                                                    </Grid>
                                                    {item[deleteFieldName] && (
                                                        <Grid item>
                                                            <Typography
                                                                variant="overline"
                                                                className={this.props.classes.deleteMessage}
                                                            >
                                                                DELETED
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </>
                                        }
                                        secondary={this.evalExpression(
                                            item,
                                            this.props.field.secondaryDisplayExpression,
                                            this.props.field,
                                        )}
                                    />
                                    {!this.props.disabled && (
                                        <ListItemSecondaryAction>
                                            {!item[deleteFieldName] ? (
                                                <IconButton
                                                    onClick={() => this.props.onDelete(index)}
                                                    aria-label={`${'Remove'} ${itemDisplay}`}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            ) : (
                                                <Button
                                                    variant="text"
                                                    size="small"
                                                    color="#444444"
                                                    onClick={() => this.props.onDeleteUndo(index)}
                                                    aria-label={`${'Undo'} ${itemDisplay}`}
                                                >
                                                    Undo
                                                </Button>
                                            )}
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem>
                            );
                        } else {
                            return <Skeleton key={index} width={400}></Skeleton>;
                        }
                    })}
                </List>
            );
        }

        return (
            <React.Fragment>
                {itemList}
                {!this.props.field?.preventAdd && (
                    <Button
                        variant="contained"
                        className={this.props.classes.button}
                        onClick={this.handleAddClick}
                        disabled={this.props.disabled}
                    >
                        Add
                    </Button>
                )}
                <Dialog
                    open={this.state.dialogMode !== ''}
                    onClose={this.handleCloseDialog}
                    fullWidth
                    disableBackdropClick={true}
                >
                    {this.state.dialogMode === 'search' ? (
                        <SearchForm
                            field={this.props.field}
                            onClose={this.handleCloseDialog}
                            onAddResult={this.handleAddSearchResult}
                        />
                    ) : (
                        RecordForm
                    )}
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(FieldList);
