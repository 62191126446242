import React from 'react';
import ReactDOM from 'react-dom';
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

class MaskedInputCustom extends React.Component {
    render() {
        const { inputRef, ...rest } = this.props;

        return <MaskedInput ref={(ref) => inputRef(ref ? ref.inputElement : null)} {...rest} />;
    }
}

class MaskedTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options,
            selectedOption: this.props.selectedOption,
        };
    }

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        this.setState({ labelWidth: ReactDOM.findDOMNode(this.inputLabelRef).offsetWidth });
    }

    render() {
        const { id, label, error, onBlur, ...rest } = this.props;

        return (
            <FormControl variant="outlined" margin="dense" error={error}>
                <InputLabel
                    htmlFor={id}
                    ref={(ref) => {
                        this.inputLabelRef = ref;
                    }}
                >
                    {label}
                </InputLabel>
                <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    id={id}
                    name={id}
                    onBlur={onBlur}
                    {...rest}
                    inputComponent={MaskedInputCustom}
                    inputProps={rest}
                />
            </FormControl>
        );
    }
}

export default MaskedTextField;
