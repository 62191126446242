import React from 'react';
import MaterialTable from 'material-table';
import memoize from 'memoize-one';
import { isEqual } from 'lodash';

// Reuse old components if nothing's changed; otherwise, MaterialTable may
// unmount/remount everything unnecessarily.
const getComponents = memoize(
    (components, containerStyles) => ({
        Container: function TableContainer({ children }) {
            return (
                // Using makeStyles to set dynamic styles based on props,
                // causes issues when anchoring a popover component based
                //  on the table or a child component. Pass in custom styles
                //  to style prop so that popover functionality can work properly.
                <div
                    style={{
                        marginTop: 24,
                        marginBottom: 24,
                        ...containerStyles,
                    }}
                >
                    {children}
                </div>
            );
        },
        ...components,
    }),
    isEqual,
);

export default function Table({
    options,
    noDataMessage,
    columns,
    data,
    onRowClick,
    components,
    actions,
    containerStyles = {},
    ...rest
}) {
    return (
        <MaterialTable
            options={{
                draggable: false,
                cellStyle: {
                    fontSize: 14,
                },
                headerStyle: {
                    fontWeight: 300,
                    textTransform: 'uppercase',
                },
                actionsColumnIndex: -1,
                ...options,
            }}
            components={getComponents(components, containerStyles)}
            actions={actions}
            localization={{
                body: {
                    emptyDataSourceMessage: noDataMessage,
                },
                pagination: {
                    firstAriaLabel: 'First Page',
                    previousAriaLabel: 'Previous Page',
                    nextAriaLabel: 'Next Page',
                    lastAriaLabel: 'Last Page',
                },
            }}
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            {...rest}
        />
    );
}
