import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import ProgressBar from '../ProgressBar';

const CustomContainedButton = styled(({ color, theme, ...otherProps }) => <MuiButton {...otherProps} />)`
    && {
        color: ${(props) => props.theme.palette.getContrastText(props.color)};
        background: ${(props) => props.color};
        &:hover {
            background: ${(props) => darken(props.color, 0.2)};
        }
    }
`;

const CustomTextButton = styled(({ color, ...otherProps }) => <MuiButton {...otherProps} />)`
    && {
        color: ${(props) => props.color};
        &:hover {
            background: ${(props) => lighten(props.color, 0.9)};
        }
    }
`;

const CustomOutlineButton = styled(({ color, ...otherProps }) => <MuiButton {...otherProps} />)`
    && {
        color: ${(props) => props.color};
        border-color: ${(props) => props.color};
        &:hover {
            background: ${(props) => lighten(props.color, 0.9)};
        }
    }
`;

const styles = (theme) => ({
    buttonContainer: {
        display: 'inline',
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    titleCase: {
        textTransform: 'capitalize',
    },
});

class Button extends React.Component {
    state = {
        loadingDelayed: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.loading !== prevProps.loading) {
            if (this.props.loading) {
                this.timer = setTimeout(() => this.setState({ loadingDelayed: this.props.loading }), 500);
            } else {
                clearTimeout(this.timer);
                this.setState({
                    loadingDelayed: this.props.loading,
                });
            }
        }
    }

    render() {
        const { children, color, variant, loading, classes, titleCase, ...rest } = this.props;

        const buttonClasses = {
            label: titleCase && classes.titleCase,
        };

        const muiColors = ['default', 'inherit', 'primary', 'secondary'];
        let buttonControl;

        if (this.props.color && !muiColors.includes(this.props.color)) {
            switch (this.props.variant) {
                case 'outlined':
                    buttonControl = (
                        <CustomOutlineButton
                            disableElevation
                            disabled={loading}
                            color={color}
                            variant={variant}
                            classes={buttonClasses}
                            {...rest}
                        >
                            {children}
                        </CustomOutlineButton>
                    );
                    break;
                case 'text':
                    buttonControl = (
                        <CustomTextButton
                            disableElevation
                            disabled={loading}
                            color={color}
                            variant={variant}
                            classes={buttonClasses}
                            {...rest}
                        >
                            {children}
                        </CustomTextButton>
                    );
                    break;
                default:
                    buttonControl = (
                        <CustomContainedButton
                            disableElevation
                            disabled={loading}
                            variant={variant}
                            color={color}
                            classes={buttonClasses}
                            {...rest}
                        >
                            {children}
                        </CustomContainedButton>
                    );
            }
        } else {
            buttonControl = (
                <MuiButton
                    disableElevation
                    disabled={loading}
                    variant={variant}
                    color={color}
                    classes={buttonClasses}
                    {...rest}
                >
                    {children}
                </MuiButton>
            );
        }

        return (
            <div className={classes.buttonContainer}>
                {buttonControl}
                {this.state.loadingDelayed && (
                    <ProgressBar type={'circular'} size={24} className={classes.buttonProgress} />
                )}
            </div>
        );
    }
}

Button.propTypes = {
    /**  Takes in color name (red, blue, green) the hex of a custom color customColor (#DB7093) rgb (rgb(255, 26, 26)), hsl, and  material ui theme colors (blue[500])}*/
    color: PropTypes.string,
    titleCase: PropTypes.bool,
    loading: PropTypes.bool,
};

export default compose(withTheme, withStyles(styles))(Button);
