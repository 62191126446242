import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { Typography } from '@material-ui/core';
import panelStyles from '../../styles/panelStyles';

const styles = panelStyles;

const Panel = ({ classes, className, children, title }) => {
    return (
        <Grow in={true}>
            <Paper component="section" className={classNames(classes.panel, className)}>
                {title && (
                    <Typography className={classes.panelTitle} variant="h2">
                        {title}
                    </Typography>
                )}
                <div className={classes.panelBody}>{children}</div>
            </Paper>
        </Grow>
    );
};

export default withStyles(styles)(Panel);
