import React from 'react';
import BlockIcon from '@material-ui/icons/Block';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import styled from 'styled-components';

const sharedStyles = `
vertical-align: middle;
font-size: 18px;
margin-right: 5px;
margin-left: 5px;
`;
const StyledNotInterestedIcon = styled(BlockIcon)`
    color: #6d7278;
    ${sharedStyles}
`;

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
    color: #6d7278;
    ${sharedStyles}
`;

const StyledErrorIcon = styled(ErrorIcon)`
    color: #6d7278;
    ${sharedStyles}
`;

const StyledCheckCircleOutlineIcon = styled(CheckCircle)`
    color: #00a612;
    ${sharedStyles}
`;

const StyledWatchLaterIcon = styled(WatchLaterIcon)`
    color: #f7b500;
    ${sharedStyles}
`;

const StyledGetAppIcon = styled(GetAppIcon)`
    ${sharedStyles}
`;

export class NotApplicableIcon extends React.Component {
    render() {
        return <StyledNotInterestedIcon />;
    }
}

export class DeficientIcon extends React.Component {
    render() {
        return <StyledErrorIcon />;
    }
}

export class CompleteIcon extends React.Component {
    render() {
        return <StyledCheckCircleOutlineIcon />;
    }
}

export class InProgressIcon extends React.Component {
    render() {
        return <StyledWatchLaterIcon />;
    }
}

export class DownloadIcon extends React.Component {
    render() {
        return <StyledGetAppIcon />;
    }
}

export class WaivedIcon extends React.Component {
    render() {
        return <StyledArrowForwardIcon />;
    }
}
