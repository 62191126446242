import React, { Component } from 'react';
import Panel from '../../components/Panel';
import { DownloadIcon } from '../../components/Icons';
import { resourceUrl, apiGet } from '../../api';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import { isSubmittedStatus } from '../../util';

const styles = () => ({
    downloadLink: {
        cursor: 'pointer',
    },
});

class Downloads extends Component {
    download = async (url) => {
        const authToken = await this.props.getAccessToken(this.props.applicationScopes);
        const response = await apiGet(`${url}/token`, authToken);
        const encodedFileId = encodeURIComponent(encodeURIComponent(response.data.id));

        window.open(
            resourceUrl(`files/${encodedFileId}?token=${encodeURIComponent(response.data.token)}`),
            '_blank',
            'noopener',
        );
    };

    createFileDownload = (file, applicationId, field) => {
        return (
            <ListItem key={file.id}>
                {' '}
                <Link
                    onClick={() =>
                        this.download(
                            resourceUrl(
                                `applications/${applicationId}/attachments/${field}/${encodeURIComponent(
                                    encodeURIComponent(file.id),
                                )}`,
                            ),
                        )
                    }
                    className={this.props.classes.downloadLink}
                >
                    {file.fileName}
                    <DownloadIcon />
                </Link>
            </ListItem>
        );
    };

    render() {
        const applicationId = this.props.applicationId;
        const preservedAppUrl = resourceUrl(`applications/${applicationId}/document`);
        let uploadedDocuments = [];
        if (this.props.uploads.length > 0) {
            this.props.uploads.forEach((upload) => {
                if (Array.isArray(upload.fieldFiles)) {
                    upload.fieldFiles.forEach((file) => {
                        uploadedDocuments.push(this.createFileDownload(file, applicationId, upload.field));
                    });
                } else {
                    uploadedDocuments.push(this.createFileDownload(upload.fieldFiles, applicationId, upload.field));
                }
            });
        } else {
            uploadedDocuments = null;
        }
        return (
            <Panel title="Downloads">
                {isSubmittedStatus(this.props.applicationStatus) ? (
                    <List>
                        <ListItem key="preservedApp">
                            <Link
                                onClick={() => this.download(preservedAppUrl)}
                                className={this.props.classes.downloadLink}
                            >
                                Application <DownloadIcon />
                            </Link>
                        </ListItem>
                        {uploadedDocuments}
                    </List>
                ) : (
                    <Typography>Downloads are unavailable while application is still in progress.</Typography>
                )}
            </Panel>
        );
    }
}

export default withStyles(styles)(Downloads);
