import React from 'react';
import MuiIconButton from '@material-ui/core/IconButton';

function IconButton({ children, ...rest }) {
    if (rest.title && !rest['aria-label']) {
        rest['aria-label'] = rest.title;
    } else if (rest['aria-label'] && !rest.title) {
        rest.title = rest['aria-label'];
    }

    return <MuiIconButton {...rest}>{children}</MuiIconButton>;
}

export default IconButton;
