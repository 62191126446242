import React from 'react';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import FieldList from './FieldList';
import OutlinedSelect from '../components/OutlinedSelect';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FileUpload from '../components/FileUpload';
import PhotoUpload from '../components/PhotoUpload';
import FormHelperText from '@material-ui/core/FormHelperText';
import Collapse from '@material-ui/core/Collapse';
import MaskedTextField from '../components/MaskedTextField';
import emailMask from 'text-mask-addons/dist/emailMask';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import FormControlInfo from '../components/FormControlInfo';
import IconButton from '../components/IconButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { apiPost, apiDelete, apiGet, resourceUrl } from '../api';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import format from 'date-fns/format';
import {
    normalizeDate,
    toDate,
    getFieldChoices,
    populateAutocomplete,
    loadRepeatableField,
    populateAutocompleteSearch,
    findDeleteField,
    populateSearchResult,
} from '../util';
import * as faceapi from 'face-api.js';
import FaceDetector from './FaceDetector';
import prettyBytes from 'pretty-bytes';
import { connect } from 'react-redux';
import { debounce, get, cloneDeep } from 'lodash';
import { loadSpinner } from '../store/RootActions';
import Autocomplete from '../components/Autocomplete';

class FormField extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            choices: [],
            repeatableField: undefined,
        };
    }

    state = {
        fileUploadError: { error: false, messages: [] },
        photoUploadError: { error: false, messages: [] },
        photo: null,
        bufferedPhoto: null,
        isAnalyzingSmartUpload: false,
        invalidFiles: [],
        repeatableDeleteFieldName: null,
    };

    async componentDidMount() {
        if (this.props.field.typeId === 'textInput' && this.props.field.autocomplete) {
            await this.setAutocompleteChoices();
        } else {
            if (this.props.field.search && this.props.value) {
                this.setState({
                    choices: await populateSearchResult(
                        this.props.getAccessToken,
                        this.props.authorizationScopes,
                        this.props.field,
                        this.props.value,
                    ),
                });
            } else {
                this.setState({
                    choices: await getFieldChoices(
                        this.props.getAccessToken,
                        this.props.authorizationScopes,
                        this.props.field,
                        this.props.applicationData,
                    ),
                });
            }
        }

        if (this.props.field.typeId === 'multiple') {
            this.setState({
                repeatableField: await loadRepeatableField(
                    this.props.getAccessToken,
                    this.props.authorizationScopes,
                    this.props.field,
                ),
                repeatableDeleteFieldName: findDeleteField(this.props.field.fields),
            });
        }

        if (this.props.field.typeId === 'photoUpload' && this.props.value) {
            const urlEncodedFileId = encodeURIComponent(this.props.value.id);
            const doubleUrlEncodedFileId = encodeURIComponent(urlEncodedFileId);

            const response = await apiGet(
                `applications/${this.props.applicationId}/attachments/${this.props.field.field}/${doubleUrlEncodedFileId}/token`,
                await this.props.getAccessToken(this.props.authorizationScopes.applications),
            );

            const url = resourceUrl(`files/${doubleUrlEncodedFileId}?token=${response.data.token}`);
            this.setPhotoUrl(url);
        }
    }

    debouncedChoices = debounce(async () => {
        const choices = await getFieldChoices(
            this.props.getAccessToken,
            this.props.authorizationScopes,
            this.props.field,
            this.props.applicationData,
        );
        this.setState({
            choices,
        });
    }, 700);

    setAutocompleteChoices = debounce(async () => {
        const choices = await populateAutocomplete(
            this.props.getAccessToken,
            this.props.authorizationScopes,
            this.props.field,
            this.props.applicationData,
        );
        this.setState({
            choices,
        });
    }, 200);

    async componentDidUpdate(prevProps) {
        const queryParams = this.props.field?.api?.queryParams;

        let isFactValueChanged;
        if (queryParams) {
            Object.values(queryParams).forEach((value) => {
                if (value.fact) {
                    if (prevProps.applicationData[value.fact] !== this.props.applicationData[value.fact]) {
                        isFactValueChanged = true;
                    }
                }
            });
            if (isFactValueChanged) {
                await this.debouncedChoices();
            }
        }
    }

    handleAutocompleteChange = async (value) => {
        const valueMapping = this.props.field.autocomplete?.valueMapping;
        const resultMappingValue = this.props.field.autocomplete?.api?.resultMapping?.value;
        if (resultMappingValue) {
            this.props.onCommit(this.props.field.field, get(value, resultMappingValue));
        } else {
            this.props.onCommit(this.props.field.field, value);
        }
        if (valueMapping) {
            for (const [mapKey, mapValue] of Object.entries(valueMapping)) {
                this.props.onCommit(mapKey, get(value, mapValue) ?? null);
            }
        }
    };

    handleAutocompleteInputChange = async (newValue) => {
        if (newValue?.trim().length > 2) {
            await this.setAutocompleteChoices();
        }
        this.props.onChange(this.props.field.field, newValue);
    };

    setAutocompleteSearch = debounce(async (value) => {
        const choices = await populateAutocompleteSearch(
            this.props.getAccessToken,
            this.props.authorizationScopes,
            this.props.field,
            this.props.applicationData,
            value,
        );
        this.setState({
            choices,
        });
    }, 200);

    handleAutoCompleteSearch = async (value) => {
        if (value.length > 2) {
            await this.setAutocompleteSearch(value);
        }
    };

    handleAutoCompleteSearchChange = (value) => {
        this.props.onCommit(this.props.field.field, value);
    };

    filterKeyDropDown = () => {
        let keyChoices;

        keyChoices = this.state.choices;

        const fieldValues = this.props.parentRepeatableFieldData
            .filter((data) => data[this.props.parentRepeatableFieldKey] !== this.props.value)
            .map((data) => data[this.props.parentRepeatableFieldKey]);

        fieldValues.forEach((value) => {
            keyChoices = keyChoices.filter((choice) => {
                return choice.value !== value;
            });
        });

        return keyChoices;
    };

    handleDateChange = (date) => {
        const normalizedDate = normalizeDate(date);
        this.props.onChange(this.props.field.field, !isNaN(date) ? normalizedDate?.toJSON() : normalizedDate);
    };

    handleDateSelection = (date) => {
        const normalizedDate = normalizeDate(date);
        this.props.onCommit(this.props.field.field, normalizedDate ? normalizedDate.toJSON() : null);
    };

    handleDateBlur = (event) => {
        this.props.onCommit(event.target.name, this.props.value || null);
    };

    handleChange = (event) => {
        this.props.onChange(event.target.name, event.target.value);
    };

    handleCheckboxChange = (event) => {
        this.props.onCommit(event.target.name, event.target.checked);
    };

    handleFileUpload = async (files) => {
        try {
            this.props.loadSpinner(true);
            this.setState({ fileUploadError: { error: false, messages: [] }, invalidFiles: [] });

            if (this.props.field?.template?.id) {
                this.setState({
                    isAnalyzingSmartUpload: true,
                });
            }

            for await (const file of files) {
                const maxSize = this.props.field.maxSize;
                if (maxSize && file.size > maxSize) {
                    this.setFileUploadError(`${file.name} exceeds file size limit of ${prettyBytes(maxSize)}.`);

                    return;
                }
                await this.saveUploadedFile(file);
            }
        } finally {
            this.props.loadSpinner(false);

            if (this.props.field?.template?.id) {
                this.setState({
                    isAnalyzingSmartUpload: false,
                });
            }
        }
    };

    handlePhotoUpload = async (files) => {
        this.props.loadSpinner(true);
        this.setState({ photoUploadError: { error: false, messages: [] }, bufferedPhoto: null });
        if (files.length !== 1) {
            this.setPhotoUploadError(false, 'Only one photo can be uploaded.');
            return;
        }

        const file = files[0];
        const acceptedImageTypes = ['image/jpeg', 'image/png'];

        let errorMessages = [];

        if (!acceptedImageTypes.includes(file.type)) {
            errorMessages.push('Photo must be a jpeg/jpg or png file.');
        }

        const maxSize = this.props.field.maxSize;

        if (maxSize && file.size > maxSize) {
            errorMessages.push(`Photo exceeds file size limit of ${prettyBytes(maxSize)}.`);
        }

        if (errorMessages.length) {
            this.setPhotoUploadError(false, ...errorMessages);

            return;
        }

        const img = await faceapi.bufferToImage(file);
        this.setState({ photo: file, bufferedPhoto: img });
    };

    saveUploadedFile = async (file) => {
        const fieldName = this.props.field.field;
        const formData = new FormData();
        formData.append(fieldName, file); //creating process circle

        const response = await apiPost(
            `applications/${this.props.applicationId}/attachments/${fieldName}`,
            formData,
            await this.props.getAccessToken(this.props.authorizationScopes.applications),
        );

        let curFiles = this.props.value || [];
        if (this.props.field.typeId === 'fileUpload') {
            if (response.data[0]) {
                curFiles = [...curFiles, response.data[0]];
            } else {
                this.setState({
                    invalidFiles: this.state.invalidFiles ? [...this.state.invalidFiles, file.name] : [file.name],
                });
            }
        } else if (this.props.field.typeId === 'photoUpload') {
            if (response.data[0]) {
                curFiles = response.data[0];
            }
        }

        this.props.onCommit(this.props.field.field, curFiles);
        this.forceUpdate();
    };

    handleFileDelete = async (index) => {
        await this.deleteFile(this.props.value[index]);
        this.props.onCommit(this.props.field.field, [
            ...this.props.value.slice(0, index),
            ...this.props.value.slice(index + 1),
        ]);
    };

    handlePhotoDelete = async () => {
        await this.deleteFile(this.props.value);
        this.props.onCommit(this.props.field.field, null);
    };

    deleteFile = async (file) => {
        const urlEncodedFileId = encodeURIComponent(file.id);
        const doubleUrlEncodedFileId = encodeURIComponent(urlEncodedFileId);

        await apiDelete(
            `applications/${this.props.applicationId}/attachments/${this.props.field.field}/${doubleUrlEncodedFileId}`,
            await this.props.getAccessToken(this.props.authorizationScopes.applications),
        );
    };

    handleCommit = (event) => {
        this.props.onCommit(event.target.name, event.target.value);
    };

    handleAddListValue = (newValue) => {
        newValue._isAppEntry = true;
        this.props.onCommit(this.props.field.field, [...(this.props.value || []), newValue]);
    };

    handleDeleteListValue = (index) => {
        const fieldValue = cloneDeep(this.props.value);
        if (!fieldValue[index]._isAppEntry && this.state.repeatableDeleteFieldName) {
            const recordToDelete = fieldValue[index];
            recordToDelete[this.state.repeatableDeleteFieldName] = true;
            fieldValue[index] = recordToDelete;
            this.props.onCommit(this.props.field.field, [...fieldValue]);
        } else {
            this.props.onCommit(this.props.field.field, [
                ...fieldValue.slice(0, index),
                ...fieldValue.slice(index + 1),
            ]);
        }
    };

    handleUndoDeleteListItem = (index) => {
        const fieldValue = cloneDeep(this.props.value);
        if (this.state.repeatableDeleteFieldName) {
            const { [this.state.repeatableDeleteFieldName]: excludedProp, ...updatedObj } = fieldValue[index];
            fieldValue.splice(index, 1, updatedObj);
            this.props.onCommit(this.props.field.field, fieldValue);
        }
    };

    handleUpdateListValue = (index, newValue) => {
        this.props.onCommit(this.props.field.field, [
            ...this.props.value.slice(0, index),
            newValue,
            ...this.props.value.slice(index + 1),
        ]);
    };

    handleInfoClick = () => {
        this.props.onAdditionalInfo(this.props.field.label, this.props.field.additionalInfo);
    };

    setFileUploadError = (errorMessage) => {
        this.setState((prevState) => ({
            fileUploadError: { messages: [...prevState.fileUploadError.messages, errorMessage], error: true },
        }));
    };

    setPhotoUploadError = (isImageCommitted, ...errorMessages) => {
        this.props.loadSpinner(false);
        if (isImageCommitted) {
            this.props.onCommit(this.props.field.field, null);
        }

        this.setState((prevState) => ({
            photoUploadError: {
                messages: [...prevState.photoUploadError.messages, ...errorMessages],
                error: true,
            },
        }));
    };

    removeUploadedPhoto = () => {
        this.props.onCommit(this.props.field.field, null);
    };

    setPhotoUrl = (url) => {
        this.setState({ photo: { url: url, ...this.state.photo } });
    };

    render() {
        let error = this.props.error && this.props.enabled ? this.props.error : {};
        let isError = error.error;
        let errorMessages = error.messages || [];
        let control = null;
        let choices;
        const dateMask = format(new Date(2020, 12, 31), 'P').replace(/[0-9][0-9]/g, '__');

        const { applicationData, ruleEngine, enabled, value } = this.props;
        if (this.props.field.typeId === 'system') {
            return null;
        }

        switch (this.props.field.typeId) {
            case 'multiple':
                control = (
                    <React.Fragment>
                        <FieldList
                            ruleEngine={ruleEngine}
                            field={this.state.repeatableField ? this.state.repeatableField : this.props.field}
                            values={value || []}
                            applicationId={this.props.applicationId}
                            applicationData={applicationData}
                            onDelete={this.handleDeleteListValue}
                            onDeleteUndo={this.handleUndoDeleteListItem}
                            onUpdate={this.handleUpdateListValue}
                            onAdd={this.handleAddListValue}
                            disabled={!enabled}
                            authorizationScopes={this.props.authorizationScopes}
                            getAccessToken={this.props.getAccessToken}
                            workflowStatus={this.props.workflowStatus}
                        />
                    </React.Fragment>
                );

                break;
            case 'textBlock':
                control = <ReactMarkdown source={this.props.field.text} />;
                break;
            case 'choices':
                if (this.props.parentRepeatableFieldKey && this.props.parentRepeatableFieldData) {
                    choices = this.filterKeyDropDown();
                } else {
                    choices = this.state.choices;
                }
                if (this.props.field.search) {
                    const selectedValue = choices?.find((choice) => {
                        return choice.value === this.props.value;
                    });
                    control = (
                        <Autocomplete
                            id={this.props.field.field}
                            name={this.props.field.field}
                            label={this.props.field.label}
                            options={this.state.choices ?? []}
                            handleChange={this.handleAutoCompleteSearchChange}
                            handleInputChange={this.handleAutoCompleteSearch}
                            disabled={!this.props.enabled}
                            variant="outlined"
                            margin="dense"
                            value={selectedValue || this.props.value}
                        />
                    );
                } else {
                    control = (
                        <OutlinedSelect
                            id={this.props.field.field}
                            name={this.props.field.field}
                            label={this.props.field.label}
                            value={this.props.value}
                            onChange={this.handleCommit}
                            onBlur={this.handleCommit}
                            error={isError}
                            disabled={!this.props.enabled}
                        >
                            {choices.length > 0 &&
                                choices.map((choice) => (
                                    <MenuItem key={choice.value} value={choice.value}>
                                        {choice.label}
                                    </MenuItem>
                                ))}
                        </OutlinedSelect>
                    );
                }
                break;
            case 'boolean':
                control = (
                    <FormControlLabel
                        label={this.props.field.label}
                        control={
                            <Checkbox
                                id={this.props.field.field}
                                name={this.props.field.field}
                                checked={!!this.props.value}
                                onChange={this.handleCheckboxChange}
                                disabled={!this.props.enabled}
                            />
                        }
                    />
                );
                break;
            case 'fileUpload':
                if (this.state.fileUploadError && this.props.enabled) {
                    error = this.state.fileUploadError;
                    isError = error.error;
                    errorMessages = error.messages || [];
                }

                control = (
                    <div>
                        <FileUpload
                            applicationId={this.props.applicationId}
                            field={this.props.field.field}
                            files={this.props.value || []}
                            invalidFiles={this.state.invalidFiles}
                            onUpload={this.handleFileUpload}
                            onDelete={this.handleFileDelete}
                            error={isError}
                            disabled={!this.props.enabled}
                            isAnalyzingSmartUpload={this.state.isAnalyzingSmartUpload}
                            isSmartUpload={this.props.field?.template?.id}
                            getAccessToken={this.props.getAccessToken}
                            applicationScopes={this.props.authorizationScopes.applications}
                        />
                    </div>
                );
                break;
            case 'photoUpload':
                if (this.state.photoUploadError && this.props.enabled) {
                    error = this.state.photoUploadError;
                    isError = error.error;
                    errorMessages = error.messages || [];
                }

                control = (
                    <div>
                        <PhotoUpload
                            file={this.props.value || []}
                            field={this.props.field}
                            photo={this.state.photo}
                            onUpload={this.handlePhotoUpload}
                            onDelete={this.handlePhotoDelete}
                            error={isError}
                            disabled={!this.props.enabled}
                        />
                        {this.state.bufferedPhoto ? (
                            <FaceDetector
                                img={this.state.bufferedPhoto}
                                setPhotoUploadError={this.setPhotoUploadError}
                                saveUploadedFile={this.saveUploadedFile}
                                file={this.state.photo}
                                setPhotoUrl={this.setPhotoUrl}
                                removeUploadedPhoto={this.removeUploadedPhoto}
                                loadSpinner={this.props.loadSpinner}
                            />
                        ) : null}
                    </div>
                );
                break;
            case 'alert':
                control = (
                    <Alert severity={this.props.field.alertType}>
                        <AlertTitle>{this.props.field.title}</AlertTitle>
                        {this.props.field.text}
                    </Alert>
                );
                break;
            case 'date':
                control = (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            inputVariant="outlined"
                            error={isError}
                            format="P"
                            margin="normal"
                            id={this.props.field.field}
                            name={this.props.field.field}
                            autoOk="true"
                            mask={dateMask}
                            value={this.props.value ? toDate(this.props.value) : null}
                            onChange={this.handleDateChange}
                            onBlur={this.handleDateBlur}
                            onAccept={this.handleDateSelection}
                            label={this.props.field.label}
                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            leftArrowButtonProps={{ 'aria-label': 'previous month' }}
                            rightArrowButtonProps={{ 'aria-label': 'next month' }}
                            disabled={!this.props.enabled}
                        />
                    </MuiPickersUtilsProvider>
                );
                break;
            default:
                if (this.props.field.mask) {
                    let mask;
                    switch (this.props.field.mask) {
                        case 'email':
                            mask = emailMask;
                            break;
                        default:
                            mask = this.props.field.mask;
                            break;
                    }

                    control = (
                        <MaskedTextField
                            id={this.props.field.field}
                            name={this.props.field.field}
                            label={this.props.field.label}
                            onChange={this.handleChange}
                            onBlur={this.handleCommit}
                            value={this.props.value}
                            error={isError}
                            mask={mask}
                            disabled={!this.props.enabled}
                        />
                    );
                } else if (this.props.field.autocomplete) {
                    control = (
                        <Autocomplete
                            id={this.props.field.field}
                            name={this.props.field.field}
                            label={this.props.field.label}
                            options={this.state.choices}
                            handleChange={this.handleAutocompleteChange}
                            disabled={!this.props.enabled}
                            handleInputChange={this.handleAutocompleteInputChange}
                            variant="outlined"
                            margin="dense"
                            value={this.props.value}
                            freeSolo={true}
                        />
                    );
                } else {
                    control = (
                        <TextField
                            id={this.props.field.field}
                            name={this.props.field.field}
                            label={this.props.field.label}
                            variant="outlined"
                            margin="dense"
                            onChange={this.handleChange}
                            onBlur={this.handleCommit}
                            value={this.props.value}
                            error={isError}
                            disabled={!this.props.enabled}
                            multiline={this.props.field.multiline}
                            minRows={this.props.field.minRows}
                            maxRows={this.props.field.maxRows}
                        />
                    );
                }
                break;
        }

        const controlGroup = (
            <FormGroup>
                <Typography inline="true">{this.props.field.instructions}</Typography>
                <FormControlInfo
                    info={
                        this.props.field.additionalInfo && (
                            <IconButton
                                onClick={this.handleInfoClick}
                                title={`Additional information for ${this.props.field.label}`}
                            >
                                <InfoIcon color="secondary" />
                            </IconButton>
                        )
                    }
                >
                    {control}
                </FormControlInfo>
                {errorMessages.map((message, index) => (
                    <FormHelperText key={index} error={true}>
                        {message}
                    </FormHelperText>
                ))}
            </FormGroup>
        );

        if (this.props.visible === undefined) {
            return controlGroup;
        }

        return (
            <Collapse in={this.props.visible} timeout={this.props.transitionDuration}>
                {controlGroup}
            </Collapse>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    loadSpinner: (payload) => {
        dispatch(loadSpinner(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormField);
