import React from 'react';
import { Provider, useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './configureStore';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { siteInfoLoad } from './store/SiteInfo/actions';
import { createTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import Banner from './components/Banner';
import Authenticate from './Authenticate';
import Logout from './Authenticate/Logout';
import MsalWrapper from './Authenticate/MsalWrapper';
import SiteReady from './SiteReady';
import AppContent from './AppContent';
import configureStore from './configureStore';

const useStyles = makeStyles((theme) => ({
    errorIcon: {
        color: '#D32F2F',
        marginRight: theme.spacing(1.5),
    },

    snackbarVariantError: {
        backgroundColor: '#fAEAEA',
        color: '#611A15',
    },

    closeIcon: {
        color: '#333333',
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: blue[700],
        },
        secondary: {
            main: grey[700],
        },
        error: {
            main: red[700],
        },
        background: {
            default: grey[200],
        },
    },
    typography: {
        h1: {
            fontSize: 20,
            fontWeight: 500,
        },
        h2: {
            fontSize: 16,
            fontWeight: 500,
        },
        body1: {
            fontSize: 14,
        },
    },
    overrides: {
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: 'rgb(255, 255, 255)',
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: grey[700],
            },
        },
        MuiStepIcon: {
            root: {
                color: grey[600],
            },
        },
    },
});

const store = configureStore();
store.dispatch(siteInfoLoad());
function SiteInfo() {
    const siteInfo = useSelector((state) => state.siteInfo);
    return (
        <HelmetProvider>
            <Helmet>
                <title>{siteInfo.title}</title>
                <meta name="description" content={siteInfo.title} />
            </Helmet>
        </HelmetProvider>
    );
}

function App() {
    const classes = useStyles();

    const snackErrorRef = React.createRef();

    const onClose = (key) => () => {
        snackErrorRef.current.closeSnackbar(key);
    };

    return (
        <Provider store={store}>
            <SiteInfo />
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider
                    ref={snackErrorRef}
                    action={(key) => (
                        <CloseIcon className={classes.closeIcon} fontSize={'small'} onClick={onClose(key)} />
                    )}
                    anchorOrigin={{
                        vertical: 'top',

                        horizontal: 'center',
                    }}
                    hideIconVariant={false}
                    autoHideDuration={6000}
                    iconVariant={{ error: <ErrorOutlineIcon className={classes.errorIcon} /> }}
                    classes={{
                        variantError: classes.snackbarVariantError,
                    }}
                >
                    <ConnectedRouter history={history}>
                        <SiteReady>
                            <MsalWrapper>
                                <Authenticate>
                                    <Banner />
                                    <AppContent />
                                </Authenticate>
                                <Switch>
                                    <Route path="/logout" component={Logout} />
                                </Switch>
                            </MsalWrapper>
                        </SiteReady>
                    </ConnectedRouter>
                </SnackbarProvider>
            </MuiThemeProvider>
        </Provider>
    );
}

export default App;
