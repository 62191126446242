import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    control: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    info: {
        marginLeft: theme.spacing(1),
        borderLeft: '0.1em solid black',
        borderColor: theme.palette.divider,
        padding: theme.spacing(1),
    },
});

class FormControlInfo extends React.Component {
    render() {
        const { children, info, classes } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.control}>{children}</div>
                {info && <div className={classes.info}>{info}</div>}
            </div>
        );
    }
}

export default withStyles(styles)(FormControlInfo);
