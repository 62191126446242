import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Panel from './components/Panel';
import { Typography, RadioGroup, FormControl, Radio, FormControlLabel, Grid } from '@material-ui/core';
import { apiPost } from './api';
import { useAccessTokens } from './Authenticate';
import Button from './components/Button';
import formSubmit from 'dom-form-submit';

const useStyles = makeStyles(() => ({
    panel: {
        textAlign: 'center',
    },
    selectPaymentMethodLabel: {
        marginBottom: 20,
    },
    formControl: {
        marginBottom: 50,
    },
    actionButton: {
        marginLeft: 10,
        marginRight: 10,
    },
    payMethod: {
        marginTop: 16,
        padding: 20,
    },
    heading: {
        fontWeight: 500,
    },
}));

export default function SelectPaymentType({ location }) {
    const classes = useStyles();

    const [paymentType, setPaymentType] = useState('CreditCard');

    const { getAccessToken, authorizationScopes } = useAccessTokens();

    const handleChangePaymentMethod = (event) => {
        setPaymentType(event.target.value);
    };

    const handlePay = async () => {
        const authToken = await getAccessToken(authorizationScopes.payments);

        const response = await apiPost(
            'payments',
            {
                order: location.state.order,
                paymentType,
            },
            authToken,
        );

        const transferData = response.data.transferData;

        formSubmit(transferData.parameters, {
            action: transferData.transferUrl,
            method: transferData.transferMethod,
        });
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={9} lg={6}>
                <Panel className={classes.panel}>
                    <Grid item className={classes.selectPaymentMethodLabel}>
                        <Typography className={classes.heading}>Select payment method</Typography>
                    </Grid>
                    <Grid item className={classes.formControl}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="paymentMethod"
                                name="radio-buttons-group"
                                onChange={handleChangePaymentMethod}
                                defaultValue="CreditCard"
                                row
                            >
                                <FormControlLabel value="CreditCard" control={<Radio />} label="Credit Card" />
                                <FormControlLabel value="eCheck" control={<Radio />} label="eCheck/ACH" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handlePay}>
                            Continue
                        </Button>
                    </Grid>
                </Panel>
            </Grid>
        </Grid>
    );
}
