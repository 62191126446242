import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import {
    makeStyles,
    Grid,
    Typography,
    Paper,
    TableContainer,
    Box,
    Button,
    Tooltip,
    IconButton,
    Snackbar,
    CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useAccessTokens, useAccount } from '../Authenticate';
import Table from '../components/Table';
import NavLink from '../components/NavLink';
import red from '@material-ui/core/colors/red';
import DeleteIcon from '@material-ui/icons/Delete';
import { apiDelete, apiGet, apiPost } from '../api';
import { MTableBodyRow } from 'material-table';

const useStyles = makeStyles((theme) => ({
    backButton: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#484848',
        lineHeight: '24px',
        marginLeft: -7,
    },
    entityName: {
        fontSize: 36,
        color: '#333333',
        letterSpacing: ' -0.56px',
        lineHeight: '44px',
    },
    linkedProfiles: {
        color: '#333333',
        fontSize: 24,
        fontWeight: 500,
        letterSpacing: '0',
        lineHeight: '32px',
    },
    removedText: {
        color: red[700],
        paddingLeft: theme.spacing(1),
    },
    disableText: {
        color: '#666666',
    },
    deleteIcon: {
        color: 'rgba(0,0,0,0.54)',
    },
    buttonWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const LinkedProfiles = ({ location }) => {
    const classes = useStyles();
    const { name, id } = location.state;
    const [linkedProfiles, setLinkedProfiles] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowHover, setRowHover] = useState('');
    const tableRef = useRef(null);
    const profilesRef = useRef(null);
    const { getAccessToken, authorizationScopes } = useAccessTokens();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [codeGenerationLoading, setCodeGenerationLoading] = useState(false);
    const currentUser = useAccount();

    useEffect(() => {
        profilesRef.current = linkedProfiles;
    }, [linkedProfiles]);

    const getLinkedProfiles = async () => {
        try {
            setLoading(true);
            const token = await getAccessToken(authorizationScopes.persons);
            const { data } = await apiGet(`persons/${id}/registeredUsers/`, token);
            const newData = data.filter((linkedProfile) => linkedProfile?.id !== currentUser?.localAccountId);
            setLinkedProfiles(newData.map((profile) => ({ ...profile, removed: false })));
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleUnlinkedProfiles = async () => {
        const profiles = profilesRef.current;
        for (const profile of profiles) {
            if (profile.removed) {
                const token = await getAccessToken(authorizationScopes.persons);
                await apiDelete(`persons/${id}/registeredUsers/${profile.id}`, token);
            }
        }
    };

    const handleCodeRegeneration = async () => {
        try {
            setCodeGenerationLoading(true);
            const token = await getAccessToken(authorizationScopes.persons);
            await apiPost(`persons/${id}/generateRegistrationCode`, {}, token);
            setOpenSnackbar(true);
            setSuccess(true);
            setCodeGenerationLoading(false);
        } catch (err) {
            setOpenSnackbar(true);
            setSuccess(false);
            setCodeGenerationLoading(false);
        }
    };

    useEffect(() => {
        getLinkedProfiles();
        return () => {
            handleUnlinkedProfiles();
        };
    }, []);

    const handleUnlinkProfiles = () => {
        setLinkedProfiles(
            linkedProfiles.map((profile) => {
                const data = selectedProfiles.find((selectedProfile) => selectedProfile.id === profile.id);
                return data ? { ...data, removed: true } : profile;
            }),
        );
        setSelectedProfiles([]);
        tableRef.current.onAllSelected(false);
    };

    const handleUnlinkProfile = (profileId) => {
        setLinkedProfiles(
            linkedProfiles.map((profile) => {
                return profile.id === profileId ? { ...profile, removed: true } : profile;
            }),
        );
        tableRef.current.onAllSelected(false);
    };

    const handleUndoProfile = (rowData) => {
        setLinkedProfiles(
            linkedProfiles.map((profile) => {
                return profile.id === rowData.id ? { ...profile, removed: false } : profile;
            }),
        );
    };

    return (
        <>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <NavLink to="/" variant="subtitle1">
                        <span className={classes.backButton}>
                            <BackIcon />
                            Dashboard
                        </span>
                    </NavLink>
                </Grid>
                <Grid item>
                    <Typography className={classes.entityName}>{name}</Typography>
                </Grid>
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Box pt={3} pb={1}>
                                <Typography className={classes.linkedProfiles}>Linked Profiles</Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                {selectedProfiles.length > 0 && (
                                    <Grid item>
                                        <Tooltip
                                            title="Remove the selected profiles linked to the facility."
                                            placement="top"
                                        >
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                onClick={handleUnlinkProfiles}
                                            >
                                                Revoke Link
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Tooltip title="Update registration code" placement="top">
                                        <Box className={classes.buttonWrapper}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={handleCodeRegeneration}
                                                disabled={codeGenerationLoading}
                                            >
                                                Regenerate Code
                                            </Button>
                                            {codeGenerationLoading && (
                                                <CircularProgress
                                                    size="20px"
                                                    color="primary"
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table
                            tableRef={tableRef}
                            options={{
                                toolbar: false,
                                paging: false,
                                draggable: false,
                                header: false,
                                selectionProps: (rowData) => ({
                                    color: 'primary',
                                    disabled: rowData.removed,
                                }),
                                rowStyle: (rowData) => ({
                                    backgroundColor:
                                        rowHover === rowData.tableData.id && !rowData.removed && 'rgba(0,0,0,0.04)',
                                }),
                            }}
                            columns={[
                                {
                                    title: 'Name',
                                    field: 'name',
                                    render: function customRender(rowData) {
                                        return (
                                            <Typography className={rowData.removed && classes.disableText}>
                                                {rowData.name}
                                                {rowData.removed && (
                                                    <span className={classes.removedText}>REMOVED</span>
                                                )}
                                            </Typography>
                                        );
                                    },
                                },
                            ]}
                            actions={[
                                {
                                    icon: 'revoke',
                                    tooltip: 'revoke link',
                                },
                            ]}
                            noDataMessage="No Linked Profiles"
                            data={linkedProfiles}
                            onSelectionChange={setSelectedProfiles}
                            components={{
                                Container: function ContainerComponent({ children }) {
                                    return <div>{children}</div>;
                                },
                                Row: function RowComponent(props) {
                                    return (
                                        <MTableBodyRow
                                            {...{
                                                ...props,
                                                options: {
                                                    ...props.options,
                                                    selection: true,
                                                },
                                            }}
                                            onMouseEnter={() => setRowHover(props.data.tableData.id)}
                                            onMouseLeave={() => setRowHover('')}
                                        />
                                    );
                                },
                                Action: function ActionComponent(props) {
                                    const { data } = props;
                                    const { id, removed, tableData } = data;
                                    return (
                                        <Grid container justifyContent="center">
                                            {tableData.id === rowHover && removed === false ? (
                                                <Tooltip title="Revoke link" placement="top">
                                                    <IconButton onClick={() => handleUnlinkProfile(id)}>
                                                        <DeleteIcon className={classes.deleteIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                removed && (
                                                    <Button size="small" onClick={() => handleUndoProfile(data)}>
                                                        UNDO
                                                    </Button>
                                                )
                                            )}
                                        </Grid>
                                    );
                                },
                            }}
                            isLoading={loading}
                        />
                    </TableContainer>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
            >
                {isSuccess ? (
                    <Alert variant="filled" onClose={() => setOpenSnackbar(false)} severity="success">
                        Registration code was successfully regenerated.
                    </Alert>
                ) : (
                    <Alert variant="filled" onClose={() => setOpenSnackbar(false)} severity="error">
                        Unable to regenerate code. Please contact your board.
                    </Alert>
                )}
            </Snackbar>
        </>
    );
};

export default LinkedProfiles;
