import moment from 'moment';

export function toDate(str) {
    if (!str) {
        return null;
    }

    let date = moment(str).toDate();

    return normalizeDate(date);
}

export function normalizeDate(date) {
    if (date) {
        date.toJSON = function () {
            if (isNaN(date.getTime())) {
                return null;
            } else {
                return new Date(date - date.getTimezoneOffset() * 60000).toISOString().substring(0, 10);
            }
        };
    }

    return date;
}
