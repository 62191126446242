import { handleActions } from 'redux-actions';
import { loadedOrganizationsList, unloadOrganizationsList } from '../../store/RootActions';

const initialState = {
    list: [],
};

export default handleActions(
    {
        [loadedOrganizationsList]: (state, action) => ({
            ...state,
            list: action.payload,
        }),
        [unloadOrganizationsList]: () => initialState,
    },
    initialState,
);
