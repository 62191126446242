import React, { useState, useEffect } from 'react';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import {
    makeStyles,
    Grid,
    Typography,
    Box,
    Paper,
    TableContainer,
    Button,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import { useAccessTokens } from '../Authenticate';
import NavLink from '../components/NavLink';
import { apiPost, apiGet } from '../api';
import Table from '../components/Table';
import formSubmit from 'dom-form-submit';
import { round } from 'lodash';
import moment from 'moment';
import TextField from '../components/TextField';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    backButton: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#484848',
        lineHeight: '24px',
        marginLeft: -7,
    },
    entityName: {
        fontSize: 36,
        color: '#333333',
        letterSpacing: ' -0.56px',
        lineHeight: '44px',
    },
    personInvoices: {
        color: '#333333',
        fontSize: 24,
        fontWeight: 500,
        letterSpacing: '0',
        lineHeight: '32px',
    },
}));

const PersonInvoices = ({ location }) => {
    const classes = useStyles();
    const history = useHistory();

    const name = location.state?.name;
    const personId = location.state?.id;

    const [displayName, setDisplayName] = useState('');
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const { getAccessToken, authorizationScopes } = useAccessTokens();
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [noResultMessage, setNoResultMessage] = useState('');

    const selectPaymentType = useSelector(({ siteInfo }) => siteInfo.selectPaymentType);

    useEffect(() => {
        (async function () {
            const token = await getAccessToken(authorizationScopes.journalEntries);
            const { data: invoices } = await apiGet('journalEntries', token, null, {
                params: {
                    filter: {
                        where: {
                            'lineItems.person.id': personId,
                            'invoicing.status': 'Open',
                        },
                    },
                },
            });

            invoices.map((invoice) => {
                invoice.invoicing = {
                    ...invoice.invoicing,
                    balance: getBalance(invoice.invoicing),
                    amountInvoiced: displayCurrencyAmount(invoice.invoicing.amountInvoiced),
                };
                invoice.date = moment(invoice.date).format('L');
            });

            setInvoices(invoices);
            setDisplayName(name);
            setNoResultMessage('');
        })();
    }, [setInvoices, getAccessToken]);

    const sanitizeAmount = (amount) => {
        return round(amount, 2);
    };

    const displayCurrencyAmount = (amount) => {
        return amount.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
    };

    const getBalance = (invoicing) => {
        if (!invoicing) {
            return 0;
        }

        return displayCurrencyAmount(sanitizeAmount(invoicing.amountInvoiced - invoicing.amountPaid));
    };

    const handlePayInvoices = async () => {
        const authToken = await getAccessToken(authorizationScopes.payments);

        const items = [];

        selectedInvoices.forEach((selectedInvoice) => {
            const person = selectedInvoice.lineItems[0].person;

            items.push({ journalEntryId: selectedInvoice.id, person });
        });

        const order = { items };

        // If the select payment type option is configured,
        // push the user to a separate page to select.
        if (selectPaymentType) {
            let route;

            if (personId) {
                route = `/persons/${personId}/invoices/selectPaymentMethod`;
            } else {
                route = '/invoices/selectPaymentMethod';
            }

            history.push(route, { order });

            return;
        }

        const response = await apiPost('payments', { order }, authToken);

        const transferData = response.data.transferData;

        formSubmit(transferData.parameters, {
            action: transferData.transferUrl,
            method: transferData.transferMethod,
        });
    };

    const findInvoice = async () => {
        const authToken = await getAccessToken(authorizationScopes.journalEntries);
        setSelectedInvoices([]);

        try {
            const { data: invoiceResult } = await apiGet('journalEntries', authToken, null, {
                params: {
                    filter: {
                        where: {
                            'lineItems.person.name': fullName,
                            'invoicing.invoiceNumber': invoiceNumber,
                            'invoicing.status': 'Open',
                        },
                    },
                },
            });

            invoiceResult.map((invoice) => {
                invoice.invoicing = {
                    ...invoice.invoicing,
                    balance: getBalance(invoice.invoicing),
                    amountInvoiced: displayCurrencyAmount(invoice.invoicing.amountInvoiced),
                };
                invoice.date = moment(invoice.date).format('L');
                setDisplayName(invoice.invoicing.billTo.name);
            });

            setInvoices(invoiceResult);
            setNoResultMessage('');
        } catch {
            setInvoices([]);
            setDisplayName('');
            setNoResultMessage('No open invoices found.');
        }
    };

    const handleInvoiceNumberChange = (e) => {
        const { value } = e.target;
        setInvoiceNumber(value);
    };

    const handleFullNameChange = (e) => {
        const { value } = e.target;
        setFullName(value);
    };

    const reloadPersonInvoices = async () => {
        const token = await getAccessToken(authorizationScopes.journalEntries);
        const { data: invoices } = await apiGet('journalEntries', token, null, {
            params: {
                filter: {
                    where: {
                        'lineItems.person.id': personId,
                        'invoicing.status': 'Open',
                    },
                },
            },
        });

        invoices.map((invoice) => {
            invoice.invoicing = {
                ...invoice.invoicing,
                balance: getBalance(invoice.invoicing),
                amountInvoiced: displayCurrencyAmount(invoice.invoicing.amountInvoiced),
            };
            invoice.date = moment(invoice.date).format('L');
        });

        setInvoices(invoices);
        setDisplayName(name);
        setNoResultMessage('');
        setSelectedInvoices([]);
        setFullName('');
        setInvoiceNumber('');
    };

    return (
        <>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <NavLink to="/" variant="subtitle1">
                        <span className={classes.backButton}>
                            <BackIcon />
                            Dashboard
                        </span>
                    </NavLink>
                </Grid>

                <Grid item>
                    <Box pt={3} pb={1}>
                        <Typography variant="h5">Search for an Invoice</Typography>
                    </Box>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="invoiceNumber"
                            variant="outlined"
                            onChange={handleInvoiceNumberChange}
                            value={invoiceNumber}
                            label="Invoice Number"
                            name="invoiceNumber"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="fullName"
                            variant="outlined"
                            onChange={handleFullNameChange}
                            value={fullName}
                            label="Full Name"
                            name="fullName"
                            fullWidth
                            helperText="Enter full name exactly as provided on your invoice, including special characters."
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Box pt={3} pb={1}>
                        <Tooltip title="Search for an invoice." placement="top">
                            <Button onClick={findInvoice} variant="contained" color="primary">
                                Find Invoice
                            </Button>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item>
                    <Typography className={classes.entityName}>{displayName}</Typography>
                </Grid>

                {displayName && (
                    <>
                        <Grid item>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Box pt={3} pb={1}>
                                        <Typography className={classes.personInvoices}>Invoices</Typography>
                                    </Box>
                                </Grid>
                                {personId && (
                                    <Grid item>
                                        <Box pt={3} pb={1}>
                                            <Tooltip title="Reload My Invoices" placement="top">
                                                <IconButton
                                                    onClick={reloadPersonInvoices}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <RefreshIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item>
                            <TableContainer component={Paper}>
                                <Table
                                    options={{
                                        toolbar: false,
                                        paging: false,
                                        draggable: false,
                                        selection: true,
                                    }}
                                    noDataMessage="No Open Invoices"
                                    columns={[
                                        { title: 'Invoice Number', field: 'invoicing.invoiceNumber' },
                                        { title: 'Invoice Date', field: 'date' },
                                        {
                                            title: 'Amount',
                                            field: 'invoicing.amountInvoiced',
                                        },
                                        {
                                            title: 'Balance',
                                            field: 'invoicing.balance',
                                        },
                                    ]}
                                    data={invoices}
                                    onSelectionChange={setSelectedInvoices}
                                />
                            </TableContainer>
                        </Grid>
                        {selectedInvoices.length > 0 && (
                            <Grid item>
                                <Box pt={3} pb={1}>
                                    <Tooltip title="Pay selected invoices" placement="top">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={handlePayInvoices}
                                        >
                                            Pay
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        )}
                    </>
                )}
                {noResultMessage && (
                    <Grid>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h5">{noResultMessage}</Typography>
                            </Grid>
                            {personId && (
                                <Grid item>
                                    <Box pt={3} pb={1}>
                                        <Tooltip title="Reload My Invoices" placement="top">
                                            <IconButton
                                                onClick={reloadPersonInvoices}
                                                variant="contained"
                                                color="primary"
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default PersonInvoices;
