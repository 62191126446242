import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Form from './Form';
import FormFinder from './FormFinder';
import SelectOrganization from './SelectOrganization';
import Dashboard from './Dashboard';
import Summary from './Summary';
import NotFoundError from './NotFoundError';
import SubmissionConfirmation from './SubmissionConfirmation';
import Payment from './Payment';
import EntityNotLinkedError, { entityNotLinkedPath } from './EntityNotLinkedError';
import LicenseDetails from './LicenseDetails';
import Layout from './components/Layout';
import LinkedProfiles from './LinkedProfiles';
import PersonDocuments from './PersonDocuments';
import PersonInvoices from './PersonInvoices';
import SchoolAdmin from './SchoolAdmin';
import ManageDependents from './ManageDependents';
import SelectPaymentType from './SelectPaymentType';
import Activity from './Activity';
import LicenseActivity from './LicenseActivity';

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: 80,
        [theme.breakpoints.up('sm')]: {
            marginTop: 120,
            marginBottom: 50,
        },
    },
}));

const StyledRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(routeProps) => (
                <Layout>
                    <Component {...routeProps} />
                </Layout>
            )}
        />
    );
};

const AppContent = () => {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <Switch>
                <StyledRoute path="/formFinders/:id" component={FormFinder} />
                <StyledRoute exact path="/applications/:id" component={Form} />
                <StyledRoute exact path="/applications/:id/summary" component={Summary} />
                <StyledRoute exact path="/applications/:id/selectPaymentMethod" component={SelectPaymentType} />
                <StyledRoute exact path="/applications/:id/submissionConfirmation" component={SubmissionConfirmation} />
                <StyledRoute exact path="/persons/:id/activities" component={Activity} />
                <StyledRoute exact path="/persons/:id/linkedProfiles" component={LinkedProfiles} />
                <StyledRoute exact path="/persons/:id/documents" component={PersonDocuments} />
                <StyledRoute exact path="/persons/:id/invoices" component={PersonInvoices} />
                <StyledRoute exact path="/persons/:id/invoices/selectPaymentMethod" component={SelectPaymentType} />
                <StyledRoute exact path="/invoices" component={PersonInvoices} />
                <StyledRoute exact path="/invoices/selectPaymentMethod" component={SelectPaymentType} />
                <StyledRoute exact path="/payments/:id" component={Payment} />
                <StyledRoute exact path="/organizations" component={SelectOrganization} />
                <Route exact path="/licenses/:id" component={LicenseDetails} />
                <StyledRoute exact path="/license/:id/activities" component={LicenseActivity} />
                <StyledRoute exact path={`${entityNotLinkedPath}`} component={EntityNotLinkedError} />
                <StyledRoute exact path={'/schoolAdmin'} component={SchoolAdmin} />
                <StyledRoute exact path={'/licenses/:id/dependents'} component={ManageDependents} />
                <StyledRoute exact path="/" component={Dashboard} />
                <StyledRoute path="/" component={NotFoundError} />
            </Switch>
        </main>
    );
};

export default AppContent;
