import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAccessTokens, useUserProfile } from '../../../../Authenticate';
import { apiGet, apiPost } from '../../../../api';
import { applicationCreate } from '../../../../store/RootActions';
import { useHistory } from 'react-router-dom';
import PopupActionMenu from '../../../PopupActionMenu';
import { useSelector } from 'react-redux';

export default function ActionMenu({
    anchor,
    onClose,
    license,
    handleClickOpen,
    person,
    disableViewLicense,
    prerequisiteId,
}) {
    const [loading, setLoading] = useState(true);
    const [actions, setActions] = useState([]);
    const history = useHistory();
    const currentUser = useUserProfile();
    const siteInfo = useSelector(({ siteInfo }) => siteInfo);

    const dispatch = useDispatch();
    const { getAccessToken, authorizationScopes } = useAccessTokens();

    const handleClick = async (action) => {
        let form;

        if (action.formId) {
            form = (await apiGet(`forms/${action.formId}`, await getAccessToken(authorizationScopes.forms))).data;
        }

        if (action.name === 'View License') {
            handleClickOpen();
        } else if (action.applicationId) {
            history.push(`applications/${action.applicationId}`);
        } else if (action.mergeTemplateId) {
            generateMergedDocument(action.mergeTemplateId);
        } else if (action.name === 'View Activity') {
            history.push(`persons/${action.person.id}/activities`, action.person);
        } else if (action.name === 'View License Activity') {
            history.push(`license/${action.license.id}/activities`, action.license);
        } else if (action.name === 'View Linked Profiles') {
            history.push(`persons/${action.person.id}/linkedProfiles`, action.person);
        } else if (action.name === 'View Documents') {
            history.push(`persons/${action.person.id}/documents`, action.person);
        } else if (action.name === 'View Invoices') {
            history.push(`persons/${action.person.id}/invoices`, action.person);
        } else if (action.type === 'SchoolAdmin') {
            history.push('/schoolAdmin', { license, formId: action.formId, supervisionHours: true });
        } else if (action.type === 'MANAGE_DEPENDENTS') {
            history.push(`/licenses/${license.id}/dependents`, { license });
        } else if (form?.onBehalfOf) {
            let data;
            if (action.type === 'AddDependent' || action.type === 'ADD_DEPENDENT') {
                data = { formId: action.formId, context: { prerequisiteId: license.id } };
            } else {
                // If the form is simply configured to be on behalf of another assume it is
                // for a new person since that is all we support without additional permissions.
                if (typeof form.onBehalfOf === 'boolean') {
                    data = { formId: action.formId, prerequisiteId };
                } else {
                    data = { formId: action.formId, context: { licenseId: license.id, prerequisiteId } };
                }
            }

            dispatch(
                applicationCreate({
                    data,
                    getAccessToken,
                    authorizationScopes,
                    dispatcher: prerequisiteId ? 'ManageDependents' : 'PagePanels',
                }),
            );
        } else {
            dispatch(
                applicationCreate({
                    data: { formId: action.formId, context: { licenseId: license.id } },
                    getAccessToken,
                    authorizationScopes,
                    dispatcher: prerequisiteId ? 'ManageDependents' : 'PagePanels',
                }),
            );
        }
    };

    const generateMergedDocument = async (mergeTemplateId) => {
        try {
            const token = await getAccessToken(authorizationScopes.mergeTemplates);
            const payload = {
                mergeIds: [license.id],
                fileName: 'mergeTemplate',
                outputFormat: 'PDF',
            };
            const response = await apiPost(`/mergeTemplates/${mergeTemplateId}/merge`, payload, token, 'arraybuffer');
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank', 'noopener');
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    useEffect(() => {
        if (license) {
            setLoading(true);
            setActions([]);

            (async function () {
                function handleViewLicense(actionsArray) {
                    return !disableViewLicense ? actionsArray.concat({ name: 'View License' }) : actionsArray;
                }

                try {
                    const token = await getAccessToken(authorizationScopes.licenses);

                    const response = await apiGet(`licenses/${license.id}/actions`, token, null, {
                        params: { actionCategory: prerequisiteId ? 'Dependent' : undefined },
                    });

                    const actionsArray = handleViewLicense(response.data);

                    actionsArray.push({ name: 'View License Activity', license });

                    setActions(actionsArray);
                } catch (error) {
                    const errorResponse = error?.response?.data;
                    if (errorResponse?.error?.statusCode === 500 && errorResponse?.partialResponse) {
                        const actionsArray = handleViewLicense(errorResponse.partialResponse);

                        setActions(actionsArray);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [license, disableViewLicense]);

    useEffect(() => {
        if (person) {
            setLoading(true);

            const actions = [];

            (async function () {
                try {
                    const token = await getAccessToken(authorizationScopes.persons);

                    const { data } = await apiGet(`persons/${person.id}/registeredUsers/`, token);

                    const linkedProfiles = data.filter((linkedProfile) => linkedProfile.id !== currentUser.id);

                    if (linkedProfiles.length > 0) {
                        const appendViewLinkedProfiles = {
                            person: { ...person, linkedProfiles },
                            name: 'View Linked Profiles',
                        };

                        actions.push(appendViewLinkedProfiles);
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }

                try {
                    const token = await getAccessToken(authorizationScopes.persons);
                    const documents = (await apiGet(`persons/${person.id}/documents`, token)).data;

                    if (documents.length) {
                        const appendViewDocuments = { person: { ...person, documents }, name: 'View Documents' };

                        actions.push(appendViewDocuments);
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }

                if (siteInfo.payInvoices) {
                    try {
                        const token = await getAccessToken(authorizationScopes.journalEntries);

                        const invoices = (
                            await apiGet('/journalEntries', token, null, {
                                params: {
                                    filter: {
                                        where: {
                                            'lineItems.person.id': person.id,
                                            'invoicing.status': 'Open',
                                        },
                                    },
                                },
                            })
                        ).data;

                        if (invoices.length) {
                            const appendViewInvoices = { person: { ...person, invoices }, name: 'View Invoices' };

                            actions.push(appendViewInvoices);
                        }
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log(e);
                    }
                }
                actions.push({ name: 'View Activity', person });

                setActions(actions);
                setLoading(false);
            })();
        }
    }, [person]);

    return (
        <PopupActionMenu
            actions={actions}
            loading={loading}
            onClose={onClose}
            anchor={anchor}
            onActionClick={handleClick}
        />
    );
}
