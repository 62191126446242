import React, { Component } from 'react';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab/';
import TextField from '../TextField';

class Autocomplete extends Component {
    render() {
        const { id, handleChange, handleInputChange, freeSolo, ...rest } = this.props;
        return (
            <MuiAutocomplete
                id={id}
                name={id}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
                onChange={(event, newValue, reason) => {
                    if (reason === 'clear') {
                        handleChange(null);
                    } else {
                        const autoCompleteValue = typeof newValue === 'string' ? newValue : newValue.value;
                        handleChange(autoCompleteValue);
                    }
                }}
                onInputChange={(event, newValue, reason) => {
                    if (reason === 'input') {
                        const autoCompleteValue = typeof newValue === 'string' ? newValue : newValue.value;
                        handleInputChange(autoCompleteValue);
                    }
                }}
                freeSolo={freeSolo}
                {...rest}
                renderInput={(params) => <TextField {...rest} {...params} />}
            />
        );
    }
}

export default Autocomplete;
