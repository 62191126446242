import { handleActions } from 'redux-actions';
import { failedPendingApplicationRequest } from './actions';

export default handleActions(
    {
        [failedPendingApplicationRequest]: (state, action) => ({
            ...state,
            rejectedRequestMessage: action.payload,
        }),
    },
    {},
);
