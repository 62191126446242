import React from 'react';
import MaterialTypography from '@material-ui/core/Typography';

class Typography extends React.Component {
    render() {
        const { children, ...others } = this.props;

        return <MaterialTypography {...others}>{children}</MaterialTypography>;
    }
}

export default Typography;
