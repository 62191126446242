import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, TextField } from '@material-ui/core';
import { apiGet, apiPut, apiPost } from './api';
import { useAccessTokens } from './Authenticate';
import Button from './components/Button';
import { ChevronLeft as BackIcon, MoreVert as MoreIcon } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { isEmpty, isEqual, isNil } from 'lodash';
import NavLink from './components/NavLink';
import moment from 'moment';
import ActionMenu from './components/PanelElements/elementTypes/LicenseTable/ActionMenu';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from './components/IconButton';
import { NumericFormat } from 'react-number-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import RefreshIcon from '@material-ui/icons/Refresh';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
    card: {
        paddingBottom: '32px',
    },
    cardHours: {
        color: '#484848',
    },
    monthDisplay: {
        color: '#484848',
        paddingRight: '25px',
    },
    topCard: {
        padding: '10px 0px 10px 50px',
        backgroundColor: grey[100],
    },
    bottomCard: {
        padding: '10px 50px',
    },
    cardLabel: {
        fontWeight: '500',
        color: '#333333',
    },
    studentCard: {
        margin: '200px',
    },
    supervisionCategories: {
        margin: '0px',
        marginLeft: '-16px',
    },
    title: {
        paddingBottom: '16px',
    },
    returnButton: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    numericInput: {
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    loader: {
        marginTop: 75,
        size: '600px',
        color: 'primary',
    },
    muiButton: {
        textTransform: 'none',
    },
}));

export default function SchoolAdmin({ location }) {
    const classes = useStyles();
    const { getAccessToken, authorizationScopes } = useAccessTokens();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Default to undefined to indicate loading status.
    const [students, setStudents] = useState();
    const [supervisionHours, setSupervisionHours] = useState({});
    const [allSupervisionCategories, setAllSupervisionCategories] = useState([]);
    const [failedStudents, setFailedStudents] = useState([]);

    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
    const applicationRef = useRef();

    const siteInfo = useSelector(({ siteInfo }) => siteInfo);

    const { license, formId } = location.state;

    const date = moment(new Date()).subtract({ months: 1 });

    useEffect(() => {
        (async function () {
            const response = await apiGet(
                'codedValues/supervision category',
                await getAccessToken(authorizationScopes.codedValues),
            );

            setAllSupervisionCategories(response.data);
        })();
    }, [getAccessToken, setAllSupervisionCategories]);

    useEffect(() => {
        if (!students) {
            enqueueSnackbar('This may take a few minutes', { variant: 'info', persist: true });
        } else {
            closeSnackbar();
        }

        return () => closeSnackbar();
    }, [students]);

    useEffect(() => {
        if (failedStudents.length) {
            enqueueSnackbar('Not all students were able to load', { variant: 'warning' });
        }
    }, [failedStudents]);

    useEffect(() => {
        (async function () {
            const dependents = (
                await apiGet(`licenses/${license.id}/dependents`, await getAccessToken(authorizationScopes.licenses))
            ).data;

            const statusFilterConfig = siteInfo.schoolAdmin?.statusFilter;

            let statusFilter = () => true;

            if (statusFilterConfig) {
                if (statusFilterConfig.include) {
                    statusFilter = (dependentWithPerson) =>
                        statusFilterConfig.include.includes(dependentWithPerson.licenseStatus);
                } else if (statusFilterConfig.exclude) {
                    statusFilter = (dependentWithPerson) =>
                        !statusFilterConfig.exclude.includes(dependentWithPerson.licenseStatus);
                }
            }

            let dependentsWithPerson = [];
            let failedDependents = [];

            for await (const dependent of dependents) {
                // Not an ideal way to implement retries but this approach keeps the
                // code changes for ML-1083 within this component.
                let retries = 2;

                for (let i = 1; i <= retries; i++) {
                    try {
                        const dependentLicense = (
                            await apiGet(
                                `licenses/${dependent.licenseId}`,
                                await getAccessToken(authorizationScopes.licenses),
                                null,
                                {
                                    params: {
                                        filter: {
                                            fields: {
                                                id: true,
                                                type: true,
                                                profession: true,
                                                status: true,
                                                totalSupervisionHours: true,
                                                monthlySupervisionHours: true,
                                                person: true,
                                            },
                                        },
                                    },
                                },
                            )
                        ).data;

                        dependentsWithPerson.push({
                            ...dependent,
                            licenseType: dependentLicense.type.name,
                            personName: dependentLicense.person.name,
                            professionId: dependentLicense.profession?.id,
                            dependentLicense,
                            licenseStatus: dependentLicense.status,
                            totalSupervisionHours: dependentLicense.totalSupervisionHours,
                            monthlySupervisionHours: dependentLicense.monthlySupervisionHours,
                        });

                        // Break the loop since api call was successful.
                        break;
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.error(err);

                        // Indicates no more retries.
                        if (i === retries) {
                            failedDependents.push(dependent);
                        }
                    }
                }
            }

            if (statusFilterConfig) {
                dependentsWithPerson = dependentsWithPerson.filter(statusFilter);
            }

            setStudents(dependentsWithPerson);

            setFailedStudents(failedDependents);
        })();
    }, [getAccessToken, setStudents, siteInfo, setFailedStudents]);

    useEffect(() => {
        (async function () {
            const existingApplications = (
                await apiGet('applications', await getAccessToken(authorizationScopes.applications), null, {
                    params: {
                        filter: {
                            where: { formId, status: { neq: 'Submitted' } },
                            fields: { id: true, data: true, status: true },
                        },
                    },
                })
            ).data;

            if (existingApplications.length) {
                if (existingApplications.length > 1) {
                    // eslint-disable-next-line no-console
                    console.warn('Multiple open supervision hours applications exist; using the first one found');
                }

                // There should only ever be one application with the matching criteria
                // but if there are multiple just grab the first one.
                applicationRef.current = existingApplications[0];

                existingApplications[0].data.dependents.forEach(function (student) {
                    student.supervisionHours.forEach(function (supHours) {
                        updateHours(student.dependentId, {
                            supervisionCategory: supHours.supervisionCategory,
                            hoursCompleted: supHours.hoursCompleted,
                        });
                    });
                });
            }

            // If an application is not 'Submitted' the status should always be 'Started'. However,
            // it's possible the status is 'Completed' which fails submission downstream. Therefore,
            // attempt to reset the applicaiton status to 'Started'.
            if (existingApplications[0]?.status === 'Completed') {
                await resetApplicationStatus(existingApplications[0].id);
            }
        })();
    }, [getAccessToken, applicationRef]);

    const resetApplicationStatus = async (id) => {
        try {
            await apiPut(
                `applications/${id}/resetStatus`,
                null,
                await getAccessToken(authorizationScopes.applications),
            );
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Failed to reset application status to Started');
        }
    };

    useEffect(() => {
        setIsReadyToSubmit(!isEmpty(supervisionHours) && !isEmpty(students));
    }, [supervisionHours, students, setIsReadyToSubmit]);

    const onSubmit = async () => {
        if (!isReadyToSubmit) {
            return;
        }

        await onSave();

        const token = await getAccessToken(authorizationScopes.applications);

        await apiPost(`applications/${applicationRef.current.id}/preSubmit`, null, token);

        await apiPost(`applications/${applicationRef.current.id}/submit`, null, token);

        setSupervisionHours({});
        applicationRef.current = undefined;
    };

    const onSave = async () => {
        const studentsWithHours = students
            .filter((student) => supervisionHours[student.licenseId])
            .map((student) => {
                const modifiedStudent = {
                    ...student,
                    supervisionHours: supervisionHours[student.licenseId].map((hours) => ({
                        ...hours,
                        dateCompleted: date.format('YYYY-MM-DD'),
                    })),
                };

                modifiedStudent.dependentId = student.licenseId;
                delete modifiedStudent.licenseId;

                return modifiedStudent;
            });

        const token = await getAccessToken(authorizationScopes.applications);

        const data = { personId: license.person.id, licenseId: license.id, dependents: studentsWithHours };

        if (!applicationRef.current) {
            const application = (
                await apiPost(
                    'applications',
                    {
                        formId,
                        data,
                        context: { licenseId: license.id },
                    },
                    token,
                )
            ).data;

            applicationRef.current = { id: application.id, data: application.data, status: application.status };
        } else {
            // Should never happen, but in case the application status is 'Completed' it.
            // will fail the save operation so attempt to reset the status to 'Started'.
            if (applicationRef.current.status === 'Completed') {
                await resetApplicationStatus(applicationRef.current.id);
            }

            await apiPut(`applications/${applicationRef.current.id}/data`, data, token);
        }
    };

    const reloadFailedStudents = async () => {
        const dependentsWithPerson = [];
        const newlyFailedStudents = [];

        for (const dependent of failedStudents) {
            try {
                const dependentLicense = (
                    await apiGet(
                        `licenses/${dependent.licenseId}`,
                        await getAccessToken(authorizationScopes.licenses),
                        null,
                        {
                            params: {
                                filter: {
                                    fields: {
                                        id: true,
                                        type: true,
                                        profession: true,
                                        status: true,
                                        totalSupervisionHours: true,
                                        monthlySupervisionHours: true,
                                        person: true,
                                    },
                                },
                            },
                        },
                    )
                ).data;

                dependentsWithPerson.push({
                    ...dependent,
                    licenseType: dependentLicense.type.name,
                    personName: dependentLicense.person.name,
                    professionId: dependentLicense.profession?.id,
                    dependentLicense,
                    licenseStatus: dependentLicense.status,
                    totalSupervisionHours: dependentLicense.totalSupervisionHours,
                    monthlySupervisionHours: dependentLicense.monthlySupervisionHours,
                });
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);

                newlyFailedStudents.push(dependent);
            }
        }

        setStudents((prevState) => prevState.concat(dependentsWithPerson));

        setFailedStudents(newlyFailedStudents);

        if (!newlyFailedStudents.length) {
            enqueueSnackbar('Successfully reloaded failed students', { variant: 'success' });
        }
    };

    const updateHours = (studentId, hours) => {
        setSupervisionHours((prevState) => {
            let prevHours = prevState[studentId];

            if (!prevHours) {
                return { ...prevState, [studentId]: [hours] };
            }

            const updatedHours = prevHours.filter(
                ({ supervisionCategory }) => supervisionCategory !== hours.supervisionCategory,
            );

            // Only add new hours if a value was entered. If the category hours
            // were erased then the category should be completely removed.
            if (hours.hoursCompleted) {
                updatedHours.push(hours);
            }

            if (!updatedHours.length) {
                const cleanState = { ...prevState };

                delete cleanState[studentId];

                return cleanState;
            }

            return { ...prevState, [studentId]: updatedHours };
        });
    };

    function generateAddress(address) {
        if (!address) {
            return '';
        }

        return `${address.line1}, ${address.city}, ${address.state} ${address.zipcode}`;
    }

    return (
        <SnackbarProvider>
            <Grid item container spacing={4} xs={12} direction="row">
                <Grid item>
                    <NavLink to="/" variant="subtitle1">
                        <span className={classes.returnButton}>
                            <BackIcon />
                            Dashboard
                        </span>
                    </NavLink>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item className={classes.title}>
                        <Typography variant="h4">Students</Typography>
                    </Grid>
                    <Grid container item xs={12} direction="column">
                        <Typography variant="subtitle1">{license.person.name}</Typography>
                        <Typography variant="subtitle1">{generateAddress(license.address)}</Typography>
                        <Typography variant="subtitle1">License #{license.licenseNumber}</Typography>
                    </Grid>
                    {!!failedStudents.length && (
                        <Grid item>
                            <MuiButton
                                startIcon={<RefreshIcon />}
                                color="primary"
                                className={classes.muiButton}
                                onClick={reloadFailedStudents}
                            >
                                Reload failed students
                            </MuiButton>
                        </Grid>
                    )}
                    <Grid item container justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle1" className={classes.monthDisplay}>
                                Hours for {date.format('MMMM YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color={grey[100]}
                                titleCase={true}
                                onClick={onSave}
                                size="small"
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                titleCase={true}
                                disabled={!isReadyToSubmit}
                                onClick={onSubmit}
                                size="small"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {!students ? (
                    <Grid container xs={12} item justifyContent="center">
                        <CircularProgress className={classes.loader} />
                    </Grid>
                ) : (
                    <Grid container xs={12} item direction="row">
                        {students.map((student, index) => (
                            <StudentCard
                                key={index}
                                className={classes.studentCard}
                                student={student}
                                updateHours={updateHours}
                                supervisionHours={supervisionHours[student.licenseId]}
                                allSupervisionCategories={allSupervisionCategories}
                                restrictDecimalsOnHours={siteInfo.schoolAdmin?.supervisionHours?.restrictDecimals}
                            />
                        ))}
                    </Grid>
                )}
            </Grid>
        </SnackbarProvider>
    );
}

const StudentCard = React.memo(
    ({ student, updateHours, supervisionHours, allSupervisionCategories, restrictDecimalsOnHours }) => {
        const classes = useStyles();

        const [supervisionCategories, setSupervisionCategories] = useState([]);
        const [actionMenuAnchor, setActionMenuAnchor] = useState();

        const isActionMenuClickedRef = useRef(false);

        useEffect(() => {
            let categories = allSupervisionCategories;

            if (!isNil(student.professionId)) {
                const professionIds = [student.professionId, '0'];

                categories = categories.filter((category) => professionIds.includes(category.professionId.toString()));
            }

            setSupervisionCategories(categories);
        }, [allSupervisionCategories, setSupervisionCategories, student]);

        const onChange = (event) => {
            updateHours(student.licenseId, {
                supervisionCategory: event.target.name,
                hoursCompleted: event.target.value,
            });
        };

        const handleActionMenu = (event) => {
            event.stopPropagation();
            setActionMenuAnchor(event.currentTarget);

            isActionMenuClickedRef.current = true;
        };

        const convertHoursToDisplay = (hours) => {
            return hours.toString().includes('.') ? hours.toFixed(2) : hours.toFixed(0);
        };

        return (
            <>
                <Grid item xs={12} className={classes.card}>
                    <Card>
                        <Grid item container xs={12} justifyContent="space-evenly">
                            <Grid container item className={classes.topCard} xs={12}>
                                <Grid container item xs={11}>
                                    <Grid item container justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="h6" className={classes.cardLabel}>
                                                {student.personName}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.cardLabel}>
                                                {student.licenseStatus}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="subtitle1" className={classes.cardHours}>
                                                {student.licenseId}
                                                {', '}
                                                {student.licenseType}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" className={classes.cardHours}>
                                                Associated On{' '}
                                                {student.dateOfAssociation
                                                    ? moment(student.dateOfAssociation).format('MM/DD/YYYY')
                                                    : '--'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs justifyContent="space-around" alignItems="center">
                                    <Grid item>
                                        <Tooltip title="Actions">
                                            {/* div is necessary to suppress console warnings */}
                                            <div>
                                                <IconButton onClick={(event) => handleActionMenu(event)}>
                                                    <MoreIcon aria-label="Menu" />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <ActionMenu
                                            anchor={actionMenuAnchor}
                                            onClose={() => setActionMenuAnchor(undefined)}
                                            license={isActionMenuClickedRef.current ? student.dependentLicense : null}
                                            disableViewLicense={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" xs={12} className={classes.bottomCard}>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    spacing={4}
                                    xs={8}
                                    className={classes.supervisionCategories}
                                    justifyContent="flex-start"
                                >
                                    {supervisionCategories.map((category, index) => (
                                        <Grid item key={index}>
                                            <NumericFormat
                                                id={category.id.toString()}
                                                name={category.id.toString()}
                                                className={classes.numericInput}
                                                label={category.label}
                                                value={
                                                    supervisionHours?.find(
                                                        (hours) => hours.supervisionCategory === category.id.toString(),
                                                    )?.hoursCompleted ?? ''
                                                }
                                                decimalScale={restrictDecimalsOnHours ? 0 : 2}
                                                allowNegative={false}
                                                isAllowed={({ value }) => !(/^0/.test(value) && value?.length > 1)}
                                                customInput={TextField}
                                                variant="outlined"
                                                margin="dense"
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                {
                                    <Grid item container direction="row" spacing={2} xs justifyContent="flex-end">
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            spacing={1}
                                            xs={6}
                                            alignItems="flex-start"
                                        >
                                            <Grid item>
                                                <Typography variant="subtitle1">Monthly Hours</Typography>
                                            </Grid>
                                            <Grid item>
                                                {restrictDecimalsOnHours
                                                    ? student.monthlySupervisionHours.toFixed(0)
                                                    : convertHoursToDisplay(student.monthlySupervisionHours)}
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column" spacing={1} xs alignItems="flex-start">
                                            <Grid item>
                                                <Typography variant="subtitle1">Total Completed Hours</Typography>
                                            </Grid>
                                            <Grid item>
                                                {restrictDecimalsOnHours
                                                    ? student.totalSupervisionHours.toFixed(0)
                                                    : convertHoursToDisplay(student.totalSupervisionHours)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </>
        );
    },
    // Define shouldComponentUpdate function to filter out updateHours since
    // the comparison will always compare to true otherwise.
    (prevProps, nextProps) => {
        const { updateHours: prevUpdateHours, ...restOfPrev } = prevProps;
        const { updateHours: nextUpdateHours, ...restOfNext } = nextProps;

        return isEqual(restOfPrev, restOfNext);
    },
);

// eslint complains if there's no display name.
StudentCard.displayName = 'StudentCard';
