import { createLogic } from 'redux-logic';
import { formFinderLoad, formFinderLoaded } from '../../store/RootActions';
import { apiGet } from '../../api';
import { push } from 'connected-react-router';

const loadFormFinder = createLogic({
    type: formFinderLoad,
    latest: true,
    async process({ action }, dispatch, done) {
        const { id, getAccessToken, authorizationScopes } = action.payload;
        const authToken = await getAccessToken(authorizationScopes.formFinders);

        apiGet(`formFinders/${id}`, authToken)
            .then((result) => {
                dispatch(formFinderLoaded(result.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    dispatch(push('/404'));
                }
            })
            .finally(done);
    },
});

export default [loadFormFinder];
