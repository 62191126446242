import React, { useState, useEffect } from 'react';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import { makeStyles, Grid, Typography, Paper, Box, List, ListItem, Link } from '@material-ui/core';
import { useAccessTokens } from '../Authenticate';
import NavLink from '../components/NavLink';
import { apiGet } from '../api';
import { DownloadIcon } from '../components/Icons';

const useStyles = makeStyles(() => ({
    downloadLink: {
        cursor: 'pointer',
    },
    backButton: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#484848',
        lineHeight: '24px',
        marginLeft: -7,
    },
    entityName: {
        fontSize: 36,
        color: '#333333',
        letterSpacing: ' -0.56px',
        lineHeight: '44px',
    },
    personDocuments: {
        color: '#333333',
        fontSize: 24,
        fontWeight: 500,
        letterSpacing: '0',
        lineHeight: '32px',
    },
}));

const PersonDocuments = ({ location }) => {
    const classes = useStyles();
    const { name, id } = location.state;
    const [documents, setDocuments] = useState([]);
    const { getAccessToken, authorizationScopes } = useAccessTokens();

    const handleDownload = async (document) => {
        const token = await getAccessToken(authorizationScopes.persons);

        const { headers, data } = await apiGet(`/persons/${id}/documents/${document.id}/content`, token, 'arraybuffer');
        serveFile(data, headers['content-type'] ?? 'application/octet-stream', document.fileName);
    };

    const serveFile = (blobParts, type, name) => {
        const blob = new Blob([blobParts], { type });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    };

    useEffect(() => {
        (async function () {
            const token = await getAccessToken(authorizationScopes.persons);
            const { data } = await apiGet(`persons/${id}/documents`, token);
            setDocuments(data);
        })();
    }, [setDocuments, getAccessToken]);

    return (
        <>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <NavLink to="/" variant="subtitle1">
                        <span className={classes.backButton}>
                            <BackIcon />
                            Dashboard
                        </span>
                    </NavLink>
                </Grid>
                <Grid item>
                    <Typography className={classes.entityName}>{name}</Typography>
                </Grid>
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Box pt={3} pb={1}>
                                <Typography className={classes.personDocuments}>Documents</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Paper title="Downloads">
                        <List>
                            {documents.map((doc) => (
                                <>
                                    <ListItem key={doc.id}>
                                        {' '}
                                        <Link onClick={() => handleDownload(doc)} className={classes.downloadLink}>
                                            {doc.fileName}
                                            <DownloadIcon />
                                        </Link>
                                    </ListItem>
                                </>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default PersonDocuments;
