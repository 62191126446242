import React from 'react';
import Grow from '@material-ui/core/Grow';

class StepTransition extends React.Component {
    render() {
        const { children: child, TransitionComponent = Grow, in: inProp, timeout, ...rest } = this.props;

        return (
            <TransitionComponent in={inProp} timeout={timeout}>
                {React.cloneElement(child, { ...rest, ...child.props })}
            </TransitionComponent>
        );
    }
}

export default StepTransition;
