import React, { Component } from 'react';

class PanelElements extends Component {
    state = {
        components: [],
    };

    async componentDidMount() {
        for (const element of this.props.elements) {
            await this.loadComponent(element);
        }
    }

    loadComponent = async (element) => {
        const { type, ...componentProps } = element;

        try {
            const Component = await import(`./elementTypes/${type}`);

            this.setState((prevState) => ({
                components: prevState.components.concat(
                    <Component.default key={prevState.components.length} {...componentProps} />,
                ),
            }));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Unable to load element type ${type}: ${error}`);
        }
    };

    render() {
        return <React.Fragment>{this.state.components}</React.Fragment>;
    }
}

export default PanelElements;
