import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useAccessTokens } from '../Authenticate';
import { apiGet } from '../api';
import LicenseBasicDetail from './LicenseBasicDetail';
import ContinuingEducation from './ContinuingEducation';
import Layout from '../components/Layout';

const useStyles = makeStyles((theme) => ({
    continuingEducationContainer: {
        background: '#F5F5F5',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
}));

const LicenseDetails = ({ location }) => {
    const [continuingEducation, setContinuingEducation] = useState(null);

    const { getAccessToken, authorizationScopes } = useAccessTokens();

    const license = location.state;
    const classes = useStyles();

    useEffect(() => {
        if (license) {
            (async function () {
                try {
                    const token = await getAccessToken(authorizationScopes.licenses);
                    const response = await apiGet(`licenses/${license.id}/continuingEducation`, token);
                    setContinuingEducation(response.data);
                } catch {
                    setContinuingEducation(null);
                }
            })();
        }
    }, [license]);

    return (
        <>
            <Layout>
                <LicenseBasicDetail license={license} />
            </Layout>

            {continuingEducation?.status === 'Current cycle' && (
                <Grid className={classes.continuingEducationContainer}>
                    <Layout>
                        <ContinuingEducation continuingEducation={continuingEducation} />
                    </Layout>
                </Grid>
            )}
        </>
    );
};

export default LicenseDetails;
