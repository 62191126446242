import React, { useState } from 'react';
import {
    Button,
    Grid,
    Typography,
    Tooltip,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Box,
} from '@material-ui/core';
import NavLink from '../components/NavLink';
import { makeStyles } from '@material-ui/core/styles';
import { isSubmittedStatus } from '../util';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { apiDelete } from '../api';
import { generateApplicationList } from '../store/PagePanels/actions';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'inline-block',
        color: theme.palette.primaryColor,
    },
    status: {
        display: 'inline-block',
        marginRight: 5,
    },
    hoverColor: {
        backgroundColor: 'rgba(0,0,0,0.04)',
    },
}));

export default function ApplicationActivity({
    formName,
    applicationId,
    userId,
    status,
    assignee,
    getAccessToken,
    authorizationScopes,
    userProfile,
    setDeletedActivity,
    component,
    statusLabel,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [rowHover, setRowHover] = useState('');

    // If the current user matches match the application user id but the application
    // is assigned to another user, treat the status as 'Submitted'.
    // Note, this checking if the application user is also the assignee. However,
    // it assumes the application user would be the assignee user id, not the person id.
    const isSubmitted = () =>
        isSubmittedStatus(status) ||
        (!!userProfile && userProfile.id === userId && !!assignee && userProfile.id !== assignee.userId);

    const appStatus = isSubmitted() ? 'Submitted' : 'Started';

    const handleDeleteConfirm = async () => {
        const authToken = await getAccessToken(authorizationScopes.applications);

        await apiDelete(`applications/${applicationId}`, authToken);

        setConfirmDeleteDialogOpen(false);

        statusLabel = isSubmitted() ? 'Submitted' : 'Started';

        if (!setDeletedActivity) {
            dispatch(generateApplicationList({ getAccessToken, authorizationScopes, userProfile, statusLabel }));
        } else {
            setDeletedActivity(applicationId);
        }
    };

    const confirmDeleteDialog = (
        <Dialog open={confirmDeleteDialogOpen}>
            <DialogTitle>Confirm Activity Delete</DialogTitle>
            <DialogContent>
                <DialogContentText id="appDeleteConfirmation">
                    Deleting your {formName} activity cannot be undone. Do you want to delete?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDeleteDialogOpen(false)}>No</Button>
                <Button onClick={handleDeleteConfirm} variant="contained" color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                onMouseEnter={() => setRowHover(applicationId)}
                onMouseLeave={() => setRowHover('')}
                className={!isSubmitted() && applicationId === rowHover ? classes.hoverColor : ''}
            >
                <Grid item xs={11} style={{ marginTop: 9, marginBottom: 9, paddingLeft: 5 }}>
                    <Typography className={classes.status}>{appStatus}</Typography>
                    <NavLink
                        className={classes.link}
                        to={{
                            pathname: `/applications/${applicationId}`,
                            state: { prevHistory: { ...location, component } },
                        }}
                    >
                        {formName}
                    </NavLink>
                </Grid>
                {applicationId === rowHover && !isSubmitted() && (
                    <Grid item xs>
                        <Box display="flex" justifyContent="flex-end" style={{ paddingRight: 5 }}>
                            <Tooltip title="Delete this activity" placement="bottom" style={{ padding: 0 }}>
                                <IconButton onClick={() => setConfirmDeleteDialogOpen(true)} variant="contained">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                )}
            </Grid>
            {confirmDeleteDialog}
        </>
    );
}
