import React from 'react';
import Grid from '@material-ui/core/Grid';
import SummaryField from '../Summary/SummaryField.js';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    multiField: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        background: theme.palette.background.default,
    },
});

class SummaryDisplay extends React.Component {
    render() {
        const { fields, fieldData, visibility, workflowStatus } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={2} className={this.props.multi ? this.props.classes.multiField : ''}>
                    {fields.map((entry) => {
                        let visibilityField = this.props.multi ? this.props.multi + '.' + entry.field : entry.field;
                        return (
                            (!entry.permissions ||
                                !entry.permissions.length ||
                                entry.permissions.includes(workflowStatus)) &&
                            visibility[visibilityField] !== false && (
                                <Grid item key={entry.field} xs={12} md={entry.width || 12}>
                                    <SummaryField
                                        field={entry}
                                        value={fieldData[entry.field]}
                                        visibility={visibility}
                                        repeatable={!!this.props.multi}
                                        applicationData={fieldData}
                                    />
                                </Grid>
                            )
                        );
                    })}
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(SummaryDisplay);
