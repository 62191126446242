import React, { Component } from 'react';
import PagePanels from '../components/PagePanels';

class Dashboard extends Component {
    render() {
        return (
            <div>
                <PagePanels />
            </div>
        );
    }
}

export default Dashboard;
