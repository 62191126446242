import React from 'react';
import { List, ListItem, MenuItem, MenuList, Popover, CircularProgress } from '@material-ui/core';

function PopupActionMenu(props) {
    const { anchor, onClose, actions, loading, onActionClick } = props;

    let content;
    if (loading) {
        content = (
            <List aria-busy>
                <ListItem>
                    <CircularProgress size="1rem" />
                </ListItem>
            </List>
        );
    } else if (actions.length === 0) {
        content = (
            <List>
                <ListItem>No actions</ListItem>
            </List>
        );
    } else {
        content = (
            <MenuList>
                {actions.map((action) => (
                    <MenuItem key={action.name} onClick={() => onActionClick(action)}>
                        {action.name}
                    </MenuItem>
                ))}
            </MenuList>
        );
    }
    return (
        <Popover
            anchorEl={anchor}
            open={!!anchor}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {content}
        </Popover>
    );
}

export default PopupActionMenu;
