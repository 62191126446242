import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { BrowserUtils } from '@azure/msal-browser';

export default function Logout() {
    const { instance } = useMsal();

    useEffect(() => {
        async function logout() {
            await instance.handleRedirectPromise();
            await instance.logoutRedirect({
                onRedirectNavigate: () => !BrowserUtils.isInIframe(),
            });
        }
        logout();
    }, [instance]);

    return null;
}
