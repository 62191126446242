import React, { Component } from 'react';
import * as faceapi from 'face-api.js';
import Smartcrop from 'smartcrop';
import Croppie from 'croppie';
import 'croppie/croppie.css';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
class FaceDetector extends Component {
    state = {
        amountFaces: 0,
        detection: null,
        canvas: null,
        ctx: null,
        face: null,
        openAiCrop: false,
        openUserCrop: false,
        croppie: null,
    };

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    setCanvas = (canvas) => {
        this.setState({ canvas });
    };

    setCtx = (ctx) => {
        this.setState({ ctx });
    };

    faceDetector = async () => {
        try {
            if (this.props.img != null) {
                await faceapi.loadTinyFaceDetectorModel('/models');
                const options = new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.35 }); //score between .01 - .99
                const detection = await faceapi.tinyFaceDetector(this.props.img, options);
                this.setState({
                    amountFaces: detection.length,
                    detection,
                });
            }
            if (this.state.amountFaces === 1) {
                const detectionsForSize = faceapi.resizeResults(this.state.detection, {
                    width: this.props.img.width,
                    height: this.props.img.height,
                });
                const face = {
                    faceHeight: detectionsForSize[0]._imageDims._height,
                    faceWidth: detectionsForSize[0]._imageDims._width,
                    faceX: detectionsForSize[0]._box._x,
                    faceY: detectionsForSize[0]._box._y,
                };
                this.setState({ face });
                const options = {
                    width: 350,
                    height: 350,
                    minScale: 0.1,
                    boost: [
                        {
                            x: face.x,
                            y: face.y,
                            width: face.width,
                            height: face.height,
                            weight: 1.0,
                        },
                    ],
                };
                Smartcrop.crop(this.props.img, options).then((cropResult) => {
                    const selectedCrop = cropResult.topCrop;
                    this.resizeImage(selectedCrop);
                });
            } else {
                this.setFaceDetectorError(this.props.file.name, this.state.amountFaces);
            }
        } catch {
            this.setState({ openUserCrop: true });
        }
    };

    resizeImage = async (crop) => {
        const canvas = this.state.canvas;
        canvas.width = 350;
        canvas.height = 350;
        canvas.getContext('2d').drawImage(this.props.img, crop.x, crop.y, crop.width, crop.height, 0, 0, 350, 350);
        this.setState({ canvas: canvas, openAiCrop: true });
    };

    setFaceDetectorError = (fileName, amountFaces) => {
        const errorMessage =
            amountFaces > 1
                ? `${fileName} contains multiple faces. Please select another photo.`
                : `${fileName} does not contain a face. Please select another photo.`;
        const isImageCommitted = true;
        this.props.setPhotoUploadError(isImageCommitted, errorMessage);
    };

    handleCloseAiCrop = async (response) => {
        this.setState({ openAiCrop: false });
        if (response === 'yes') {
            this.state.canvas.toBlob(async (blob) => {
                this.savePhoto(blob);
            });
        } else {
            this.setState({ openUserCrop: true });
        }
    };

    handleOpenUserCrop = () => {
        const cropper = document.getElementById('image-cropper');
        if (cropper) {
            const croppieInstance = new Croppie(cropper, {
                //creating croppie/user cropper
                enableExif: true,
                viewport: {
                    height: 290,
                    width: 290,
                },
                boundary: {
                    height: 330,
                    width: 500,
                },
                enableOrientation: true,
            });
            croppieInstance.bind({
                url: this.props.img.src,
            });
            this.setState({ croppie: croppieInstance });
        }
    };

    handleCloseUserCrop = async () => {
        this.setState({ openUserCrop: false });
        if (this.state.croppie !== null) {
            this.state.croppie
                .result({
                    type: 'rawcanvas',
                    size: {
                        width: 480,
                        height: 480,
                    },
                })
                .then(async (htmlImage) => {
                    await faceapi.loadTinyFaceDetectorModel('/models');
                    const options = new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.35 }); //score between .01 - .99
                    const detection = await faceapi.tinyFaceDetector(htmlImage, options);
                    if (detection.length === 1) {
                        htmlImage.toBlob(async (blob) => {
                            this.savePhoto(blob);
                        });
                    } else {
                        this.setFaceDetectorError('Cropped area', detection.length);
                    }
                });
        }
    };

    savePhoto = async (blob) => {
        this.props.loadSpinner(true);
        try {
            const file = new File([blob], this.props.file.name, {
                type: this.props.file.type,
                lastModified: Date.now(),
            });
            await this.props.saveUploadedFile(file);
            this.props.setPhotoUrl(URL.createObjectURL(blob));
        } finally {
            this.props.loadSpinner(false);
        }
    };

    closeDialogWithoutSave = () => {
        this.props.loadSpinner(false);
        this.props.removeUploadedPhoto();
        this.setState({ openUserCrop: false, openAiCrop: false });
    };

    componentDidMount() {
        this.faceDetector();
        const canvas = this.canvasRef.current;
        this.setCanvas(canvas);
        this.setCtx(canvas.getContext('2d'));
    }

    render() {
        return (
            <div>
                <canvas id="hiddenImage" hidden={true} ref={this.canvasRef} />
                <div>
                    <Dialog
                        id="user-crop"
                        open={this.state.openUserCrop}
                        onEntered={this.handleOpenUserCrop}
                        onClose={this.handleCloseUserCrop}
                        disableBackdropClick={true}
                    >
                        <DialogTitle>
                            Click &#39;Done&#39; when you are satisfied with the selection
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={this.closeDialogWithoutSave}
                                size="small"
                                aria-label="close"
                                style={{ float: 'right' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <div id="image-cropper"></div>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={this.handleCloseUserCrop}
                                >
                                    Done
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </div>

                <div id="ai-crop">
                    <Dialog
                        open={this.state.openAiCrop}
                        onClose={() => this.handleCloseAiCrop('no')}
                        disableBackdropClick={true}
                    >
                        <DialogTitle>
                            {'Do you want to use this image?'}
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={this.closeDialogWithoutSave}
                                size="small"
                                aria-label="close"
                                style={{ float: 'right' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            {this.state.canvas ? <img alt="resized" src={this.state.canvas.toDataURL()} /> : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleCloseAiCrop('yes')} color="primary">
                                Yes
                            </Button>
                            <Button onClick={() => this.handleCloseAiCrop('no')} color="primary">
                                No, I want to crop it myself
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}
export default FaceDetector;
