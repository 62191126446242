import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Centered from '../components/Centered';
import underConstructionImg from './under_construction.svg';
import NotFoundError from '../NotFoundError';

const useStyles = makeStyles(() => ({
    image: {
        height: '50vh',
        width: '50vw',
        opacity: '50%',
    },
}));

function siteConfigured(siteInfo) {
    return siteInfo.authentication !== undefined;
}

export default function SiteReady(props) {
    const classes = useStyles();

    if (props.siteInfo.siteExists !== undefined && !props.siteInfo.siteExists) {
        return <NotFoundError />;
    }

    if (!props.siteInfo.siteExists) {
        return null;
    }

    if (!siteConfigured(props.siteInfo)) {
        return (
            <Centered fullScreen>
                <img src={underConstructionImg} alt="Under Construction" className={classes.image} />
            </Centered>
        );
    }

    return props.children;
}

SiteReady.propTypes = {
    siteInfo: PropTypes.object.isRequired,
};
