import { createAction } from 'redux-actions';

export const panelsLoad = createAction('PANELS_LOAD');
export const panelsLoaded = createAction('PANELS_LOADED');
export const panelsUnload = createAction('PANELS_UNLOAD');
export const applicationCreate = createAction('CREATE_APPLICATION');
export const applicationCreateFailed = createAction('CREATE_APPLICATION_FAILED');
export const organizationSelect = createAction('SELECT FACILITY');
export const generateApplicationList = createAction('GENERATE_APPLICATIONS_LIST');
export const loadedApplicationList = createAction('LOADED_APPLICATIONS_LIST');
export const formFinderStart = createAction('START_FORMFINDER');
export const licensesLoad = createAction('LICENSES_LOAD');
export const licensesLoaded = createAction('LICENSES_LOADED');
export const invoicesPay = createAction('PAY_INVOICES');
