import { handleActions } from 'redux-actions';
import { siteInfoLoaded, loadSpinner } from './actions';

const initialState = {
    title: '',
    authentication: undefined,
    siteExists: undefined,
    isLoading: false,
};

export default handleActions(
    {
        [siteInfoLoaded]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [loadSpinner]: (state, action) => ({
            ...state,
            isLoading: action.payload,
        }),
    },
    initialState,
);
