import { handleActions } from 'redux-actions';
import { loadedApplicationList, panelsLoaded, licensesLoaded, applicationCreateFailed } from './actions';

const initialState = {
    panels: [],
    actionResult: undefined,
    licensesHeld: [],
    eligibilityErrorMessage: undefined,
};

export default handleActions(
    {
        [panelsLoaded]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [loadedApplicationList]: (state, action) => ({
            ...state,
            actionResult: {
                ...action.payload,
            },
        }),
        [licensesLoaded]: (state, action) => ({
            ...state,
            licensesHeld: action.payload,
        }),
        [applicationCreateFailed]: (state, action) => ({
            ...state,
            eligibilityErrorMessage: action.payload,
        }),
    },
    initialState,
);
