import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAccessTokens } from '../Authenticate';
import { Grid, Typography } from '@material-ui/core';
import LicensingChecklist from './LicensingChecklist';
import Downloads from './Downloads';
import { makeStyles } from '@material-ui/core/styles';
import { apiGet } from '../api';
import moment from 'moment';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import NavLink from '../components/NavLink';
import ActionMenu from './ActionMenu';
import { useHistory } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { failedPendingApplicationRequest } from '../store/RootActions';

const useStyles = makeStyles(() => ({
    h2: {
        fontSize: 24,
        marginBottom: 40,
        fontWeight: 500,
    },
    h3: {
        fontSize: 20,
        marginBottom: 10,
        fontWeight: 400,
    },
    h4: {
        fontSize: 16,
    },
    receivedDate: {
        fontSize: 14,
        fontWeight: 300,
    },
    returnButton: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

export default function ApplicantDashboard({ applicationId, applicantName, applicationStatus, applicationData, form }) {
    const classes = useStyles();
    const history = useHistory();
    const { getAccessToken, authorizationScopes } = useAccessTokens();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [applicationProgress, setApplicationProgress] = useState('');
    const [uploads, setUploads] = useState([]);
    const [responseCode, setResponseCode] = useState('');

    const rejectedRequestMessage = useSelector(({ applicantDashboard }) => applicantDashboard.rejectedRequestMessage);

    let status = '';
    if (responseCode === 404) {
        status = 'Submitted';
    } else {
        status = applicationProgress.status;
    }

    const prevHistory = history.location.state?.prevHistory;

    const closeRejectedErrorSnackbar = () => dispatch(failedPendingApplicationRequest(undefined));

    useEffect(() => {
        (async function () {
            try {
                const token = await getAccessToken(authorizationScopes.applications);

                const applicationProgress = (await apiGet(`applications/${applicationId}/progress`, token)).data;

                setApplicationProgress(applicationProgress);
            } catch (error) {
                if (error.response) {
                    setResponseCode(error.response.status);
                }
            }

            const uploads = [];

            //Loop through the sections
            if (form) {
                //Loop through the sections
                form.sections.forEach((section) => {
                    //Loop through the entry
                    section.entries.forEach((entry) => {
                        if (
                            (entry.typeId === 'fileUpload' || entry.typeId === 'photoUpload') &&
                            applicationData[entry.field]
                        ) {
                            uploads.push({
                                field: entry.field,
                                fieldFiles: applicationData[entry.field],
                            });
                        }
                    });
                });
            }

            setUploads(uploads);
        })();
    }, [
        getAccessToken,
        authorizationScopes,
        setApplicationProgress,
        setResponseCode,
        applicationData,
        setUploads,
        form,
    ]);

    useEffect(() => {
        if (rejectedRequestMessage) {
            enqueueSnackbar(rejectedRequestMessage, {
                onClose: closeRejectedErrorSnackbar,
                variant: 'error',
            });
        }

        return () => closeSnackbar();
    }, [rejectedRequestMessage]);

    return (
        <SnackbarProvider>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <NavLink to={prevHistory ?? { pathname: '/' }} variant="subtitle1">
                        <span className={classes.returnButton}>
                            <BackIcon />
                            {prevHistory?.component ?? 'Dashboard'}
                        </span>
                    </NavLink>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item md={9} xs={12}>
                            <Typography variant="h2" classes={{ h2: classes.h2 }}>
                                {applicantName}
                            </Typography>
                            <Typography variant="h3" classes={{ h3: classes.h3 }}>
                                {form.name}
                            </Typography>
                            {applicationProgress?.receivedDate && (
                                <div className={classes.receivedDate}>
                                    Application Received Date:{' '}
                                    {applicationProgress.receivedDate
                                        ? moment(applicationProgress.receivedDate).format('L')
                                        : ' '}{' '}
                                </div>
                            )}
                        </Grid>
                        <Grid item md={3} xs={12} container>
                            <Grid item container alignItems="center" spacing={1}>
                                <Grid item alignSelf="center">
                                    <Typography variant="h4" classes={{ h4: classes.h4 }}>
                                        Status: {status}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <ActionMenu applicationId={applicationId} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item lg={9} sm={12}>
                            <LicensingChecklist
                                checkListData={applicationProgress.checklist}
                                responseCode={responseCode}
                            ></LicensingChecklist>
                        </Grid>
                        <Grid item lg={3} sm={12}>
                            <Downloads
                                applicationId={applicationId}
                                uploads={uploads}
                                applicationStatus={applicationStatus}
                                getAccessToken={getAccessToken}
                                applicationScopes={authorizationScopes.applications}
                            ></Downloads>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SnackbarProvider>
    );
}
