import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = (theme) => ({
    dropzone: {
        padding: 10,
        borderWidth: 2,
        borderRadius: 4,
        borderStyle: 'dashed',
        borderColor: grey[200],
        textAlign: 'center',
    },
    error: {
        borderColor: red[500],
    },
    spacing: {
        margin: theme.spacing(2),
        height: '165px',
        width: '165px',
    },
    highlight: {
        backgroundColor: lighten(yellow[500], 0.8),
    },
    imgCard: {
        height: '165px',
        width: '165px',
        position: 'relative',
        '&:hover $deleteIcon': {
            backgroundColor: 'rgba(250,250,250,0.8)',
            '&:hover': {
                backgroundColor: 'rgba(250,250,250,0.4)',
            },
        },
    },
    deleteIcon: {
        fontSize: '25px',
        height: '20px',
        maxWidth: '20px',
        minWidth: '20px',
        position: 'absolute',
        top: '15px',
        left: '-15px',
        color: 'secondary',
        backgroundColor: 'transparent',
    },
    circularProgress: {
        padding: theme.spacing(4),
    },
    uploadMessage: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
});
class PhotoUpload extends React.Component {
    handleDelete = (event) => {
        this.props.onDelete(0);
        event.stopPropagation();
        event.preventDefault();
    };

    render() {
        return (
            <Grid container spacing={0} className={this.props.classes.spacing}>
                {this.props.file.fileName ? (
                    <Grid item xs={12}>
                        <Card className={this.props.classes.imgCard} elevation={0}>
                            {this.props.file.id && (
                                <Badge
                                    badgeContent={
                                        <Button
                                            onClick={(event) => this.handleDelete(event)}
                                            aria-label={`Remove ${this.props.field.label}`}
                                            className={this.props.classes.deleteIcon}
                                        >
                                            &times;
                                        </Button>
                                    }
                                >
                                    {this.props.photo && this.props.photo.url ? (
                                        <CardMedia
                                            component="img"
                                            src={this.props.photo.url}
                                            title={this.props.file.fileName}
                                        />
                                    ) : (
                                        <Skeleton animation="wave" variant="rect" width="165px" height="165px" />
                                    )}
                                </Badge>
                            )}
                        </Card>
                        <Grid item xs={12}>
                            <Typography align="center" noWrap={true}>
                                {this.props.file.fileName}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Dropzone onDrop={this.props.onUpload} disabled={this.props.disabled}>
                            {({ getRootProps, getInputProps, isDragActive }) => (
                                <div
                                    {...getRootProps({
                                        className: classNames(
                                            this.props.classes.dropzone,
                                            {
                                                [this.props.classes.error]: this.props.error,
                                            },
                                            {
                                                [this.props.classes.highlight]: isDragActive,
                                            },
                                        ),
                                    })}
                                >
                                    <input {...getInputProps()} />

                                    <Grid item xs={12} className={this.props.classes.uploadMessage}>
                                        <CloudUploadIcon style={{ fontSize: 50 }} color="secondary" />
                                        <Typography color="textSecondary">
                                            Drag and drop a file or click to select a file.
                                        </Typography>
                                    </Grid>
                                </div>
                            )}
                        </Dropzone>
                    </Grid>
                )}
            </Grid>
        );
    }
}
export default withStyles(styles)(PhotoUpload);
