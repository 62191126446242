import React from 'react';
import { compose } from 'redux';
import { withAuthenticationContext } from '../Authenticate';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Panel from '../components/Panel';
import NavLink from '../components/NavLink';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '../components/Button';

const styles = (theme) => ({
    panel: {
        textAlign: 'center',
    },
    content: {
        marginTop: theme.spacing(6),
    },
    actionBar: {
        marginTop: theme.spacing(6),
    },
    footer: {
        marginTop: theme.spacing(6),
    },
    formControl: {
        minWidth: 250,
    },
    italicizeMenuItem: {
        fontStyle: 'italic',
    },
    actionButton: {
        marginLeft: 10,
        marginRight: 10,
    },
});

class SelectOrganization extends React.Component {
    state = {
        selectedOrganizationId: '',
    };

    componentDidMount() {
        if (this.props.userProfile) {
            this.props.onLoad({
                getAccessToken: this.props.getAccessToken,
                authorizationScopes: this.props.authorizationScopes,
                userProfile: this.props.userProfile,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userProfile !== this.props.userProfile) {
            this.props.onLoad({
                getAccessToken: this.props.getAccessToken,
                authorizationScopes: this.props.authorizationScopes,
                userProfile: this.props.userProfile,
            });
        }
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleSelection = (event) => {
        this.setState({ selectedOrganizationId: event.target.value });
    };

    handleNext = () => {
        const data = {
            formId: this.props.location.state.formId,
            context: {
                personId:
                    this.state.selectedOrganizationId !== 'new-organization' ? this.state.selectedOrganizationId : null,
            },
        };
        this.props.onAppStart(data, this.props.getAccessToken, this.props.authorizationScopes, this.props.userProfile);
    };

    render() {
        const { organizationsList, location } = this.props;

        return (
            <Grid container justifyContent="center">
                <Grid item xs={12} md={9} lg={6}>
                    <Panel className={this.props.classes.panel}>
                        <Typography variant="h2">Which organization you are applying for?</Typography>
                        <div className={this.props.classes.content}>
                            <FormControl className={this.props.classes.formControl}>
                                <InputLabel htmlFor="organizations">Organization</InputLabel>
                                <Select
                                    value={this.state.selectedOrganizationId}
                                    onChange={this.handleSelection}
                                    inputProps={{ id: 'organizations' }}
                                >
                                    {organizationsList.length > 0 &&
                                        organizationsList.map((organization) => (
                                            <MenuItem key={organization.id} value={organization.id}>
                                                {organization.name}
                                            </MenuItem>
                                        ))}

                                    {
                                        // Only display the new organization option if the form
                                        // allows a new organization to be created.
                                        location.state && location.state.entityOptions !== 'existingOnly' ? (
                                            <MenuItem
                                                className={this.props.classes.italicizeMenuItem}
                                                value="new-organization"
                                            >
                                                Create New Organization
                                            </MenuItem>
                                        ) : undefined
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className={this.props.classes.actionBar}>
                            <Button
                                variant="contained"
                                disabled={
                                    !this.state.selectedOrganizationId || !(location.state && location.state.formId)
                                }
                                onClick={this.handleNext}
                                className={this.props.classes.actionButton}
                            >
                                Next
                            </Button>
                        </div>
                        <div className={this.props.classes.footer}>
                            <NavLink to="/">Return to Dashboard</NavLink>
                        </div>
                    </Panel>
                </Grid>
            </Grid>
        );
    }
}

export default compose(withRouter, withStyles(styles), withAuthenticationContext)(SelectOrganization);
