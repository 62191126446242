import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAccessTokens } from './Authenticate';
import SubmissionStatusPanel from './SubmissionStatusPanel';
import { apiGet } from './api';

export default function Payment() {
    const { id } = useParams();
    const history = useHistory();
    const { getAccessToken, authorizationScopes } = useAccessTokens();
    const [payment, setPayment] = useState();

    useEffect(() => {
        (async function () {
            try {
                const response = await apiGet(`payments/${id}`, await getAccessToken(authorizationScopes.payments));
                const payment = response.data;

                // Enrich with application data.
                payment.order.items = await Promise.all(
                    payment.order.items.map(async (item) => {
                        try {
                            const applicationResponse = await apiGet(
                                `applications/${item.applicationId}`,
                                await getAccessToken(authorizationScopes.applications),
                            );

                            return {
                                ...item,
                                application: applicationResponse.data,
                            };
                        } catch (error) {
                            if (error.response) {
                                if (error.response.status === 404) {
                                    // Shouldn't happen, but if the application doesn't exist, don't
                                    // throw the 404 as it will cause the whole page to display the
                                    // 404 error as if the payment didn't exist.
                                    return item;
                                } else if (error.response.status === 403) {
                                    // For a multi-step application, an assignee does not have
                                    // access to the application at this point, resulting in a
                                    // 403 error. In that scenario just return the item.
                                    return item;
                                }
                            }

                            throw error;
                        }
                    }),
                );

                setPayment(payment);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    history.push('/404');
                }
            }
        })();
    }, [id]);

    if (!payment) {
        return null;
    }

    const paymentReceived = payment.paymentResult && payment.paymentResult.paymentStatus === 'Successful';

    const title = paymentReceived ? 'Submission Confirmed!' : 'Awaiting Payment';
    const message = paymentReceived
        ? 'Thank you for your submission. We have received your request and payment.'
        : 'Your request will be submitted upon receipt of payment.';

    const confirmationFields = payment.order.items.flatMap((item) => {
        // If the application was able to be retrieved, display
        // the applicant name and form name.
        const itemData = [];

        if (item.application) {
            itemData.push({
                fieldLabel: 'Request Type',
                fieldData: item.application.formName,
            });

            // Only display applicant name if we have it.
            if (item.application.applicantName) {
                itemData.push({
                    fieldLabel: 'Name',
                    fieldData: item.application.applicantName,
                });
            }
        }

        return itemData;
    });

    return <SubmissionStatusPanel title={title} message={message} confirmationFields={confirmationFields} />;
}
