import React from 'react';
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

class OutlinedSelect extends React.Component {
    state = {
        labelWidth: 0,
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        this.setState({ labelWidth: ReactDOM.findDOMNode(this.inputLabelRef).offsetWidth });
    }

    render() {
        return (
            <FormControl variant="outlined" margin="dense" error={this.props.error} disabled={this.props.disabled}>
                <InputLabel
                    htmlFor={this.props.id}
                    ref={(ref) => {
                        this.inputLabelRef = ref;
                    }}
                >
                    {this.props.label}
                </InputLabel>
                <Select
                    name={this.props.name}
                    value={this.props.value}
                    {...this.props}
                    id={`${this.props.id}-select`}
                    input={<OutlinedInput id={this.props.id} labelWidth={this.state.labelWidth} />}
                >
                    {this.props.children}
                </Select>
            </FormControl>
        );
    }
}

export default OutlinedSelect;
