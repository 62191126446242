import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
    viewport: {
        height: '100vh',
    },
    grid: {
        height: '100%',
    },
}));

export default function Centered({ className, fullScreen, children }) {
    const classes = useStyles();

    return (
        <div
            className={classNames({
                [classes.viewport]: fullScreen,
                [className]: className,
            })}
        >
            <Grid container alignItems="center" justifyContent="center" className={classes.grid}>
                <Grid item>{children}</Grid>
            </Grid>
        </div>
    );
}

Centered.propTypes = {
    fullScreen: PropTypes.bool,
};
