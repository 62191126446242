import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuthenticationContext } from '../Authenticate';
import { withRouter } from 'react-router-dom';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Panel from '../components/Panel';
import Typography from '@material-ui/core/Typography';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Tooltip } from '@material-ui/core';
import NavLink from '../components/NavLink';
import Button from '../components/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowLeft';
import ArrowForwardIcon from '@material-ui/icons/ArrowRight';
import Pluralize from 'react-pluralize';
import WarningIcon from '@material-ui/icons/Warning';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import IconButton from '../components/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import StepTransition from '../components/StepTransition';
import NotesIcon from '@material-ui/icons/SpeakerNotes';
import FormDisplay from './FormDisplay';
import ApplicantDashboard from '../ApplicantDashboard';
import { loadForm, saveForm, runBusinessRules, saveAndValidateForm } from './FormLogic';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import { isSubmittedStatus } from '../util';
import { loadSpinner } from '../store/RootActions';
import { withSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import { apiDelete } from '../api';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 'calc(25% + 25px)',
        },
    },
    content: {
        marginTop: theme.spacing(6),
    },
    actionBar: {
        marginTop: theme.spacing(6),
        display: 'flex',
    },
    leftActionBar: {
        flexGrow: 1,
    },
    rightActionBar: {},
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    footer: {
        marginTop: theme.spacing(6),
        textAlign: 'center',
    },
    stepperButton: {
        textAlign: 'left',
    },
    drawerSpacer: theme.mixins.toolbar,
    drawer: {
        padding: theme.spacing(3),
        minWidth: 240,
    },
    permanentDrawer: {
        padding: theme.spacing(3),
        minWidth: 240,
        maxWidth: `calc(25% - ${theme.spacing(6)}px)`,
        zIndex: theme.zIndex.appBar - 1,
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    grow: {
        flexGrow: 1,
    },
    alert: {
        zIndex: theme.zIndex.appBar,
        width: '50%',
        marginLeft: '50%',
    },
});

class Form extends React.Component {
    state = {
        form: {
            id: '',
            sections: [],
        },
        data: {},
        workflowStatus: undefined,
        assignee: undefined,
        userId: '',
        errors: {},
        visibility: {},
        enabledStatus: {},
        sectionStatus: [],
        saving: false,
        status: undefined,
        lastSaved: '',
        currentStep: 0,
        sectionIndex: 0,
        drawer: false,
        showDeficiencies: false,
        unloadOnFinish: false,
        loadingFinish: false,
        finishButtonClicked: false,
        finishError: false,
        confirmDeleteDialogOpen: false,
    };

    async componentDidMount() {
        await this.reloadFormComponent();
    }

    reloadFormComponent = async () => {
        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        await loadForm(this.props.match.params, authToken, this.updateState, this.props.history, this.isSubmitted);

        if (!this.isSubmitted()) {
            this.unblock = this.props.history.block((targetLocation) => {
                if (!this.state.unloadOnFinish) {
                    // Get a new token, as authToken may have expired by the
                    // time this runs.
                    this.props.getAccessToken(this.props.authorizationScopes.applications).then((token) => {
                        saveForm(this.props.match.params.id, token, this.getState, this.updateState)
                            .then(() => {
                                this.unblock();
                                this.props.history.push(targetLocation);
                            })
                            .catch(() => {});
                    });
                } else {
                    this.unblock();
                    this.props.history.push(targetLocation);
                }

                return false;
            });
        }
    };

    componentWillUnmount() {
        this.unblock && this.unblock();
        this.props.isLoading && this.props.loadSpinner(false);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            await this.reloadFormComponent();
        } else if (this.state.currentStep !== prevState.currentStep) {
            window.scrollTo(0, 0);
        }

        if (this.state.finishError && !prevState.finishError) {
            const errorMessage =
                'Application failed to be completed. This may be due to a duplicate record in the system. Please contact customer service.';

            this.props.enqueueSnackbar(errorMessage, {
                onClose: this.updateState({ finishError: false }),
                variant: 'error',
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    getState = () => {
        return this.state;
    };

    updateState = (newState) => {
        this.setState(newState);
    };

    handleStepChange = async (step, sectionIndex) => {
        this.setState({ currentStep: step, sectionIndex, drawer: false });

        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        saveForm(this.props.match.params.id, authToken, this.getState, this.updateState);
    };

    handleBack = async () => {
        const visibleSteps = this.state.form.sections
            .map((section, index) => ({ ...section, sectionIndex: index }))
            .filter((section, index) => this.state.sectionStatus[index].visible)
            .filter((section) => this.isSectionVisible(section));

        this.setState((prevState) => ({
            currentStep: prevState.currentStep - 1,
            sectionIndex: visibleSteps[prevState.currentStep - 1].sectionIndex,
        }));

        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        saveForm(this.props.match.params.id, authToken, this.getState, this.updateState);
    };

    handleContinue = async () => {
        const visibleSteps = this.state.form.sections
            .map((section, index) => ({ ...section, sectionIndex: index }))
            .filter((section, index) => this.state.sectionStatus[index].visible)
            .filter((section) => this.isSectionVisible(section));

        this.setState((prevState) => ({
            currentStep: prevState.currentStep + 1,
            sectionIndex: visibleSteps[prevState.currentStep + 1].sectionIndex,
        }));

        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        saveForm(this.props.match.params.id, authToken, this.getState, this.updateState);
    };

    showDrawer = () => {
        this.setState({ drawer: true });
    };

    hideDrawer = () => {
        this.setState({ drawer: false });
    };

    showDeficiencies = () => {
        this.setState({ showDeficiencies: true });
    };

    handleDeficienciesClose = () => {
        this.setState({ showDeficiencies: false });
    };

    setStateAsync = (state) => {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    };

    handleChange = async (id, value) => {
        await this.setStateAsync((prevState) => ({
            data: {
                ...prevState.data,
                [id]: value,
            },
        }));
    };

    handleCommit = async (id, value) => {
        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);
        await this.handleChange(id, value);
        runBusinessRules(this.getState, this.props.match.params.id, this.updateState, authToken);
    };

    handleSubmit = async () => {
        this.setState({
            loadingFinish: true,
            finishButtonClicked: true,
        });

        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        saveAndValidateForm(
            this.props.match.params.id,
            authToken,
            this.props.history,
            this.getState,
            this.updateState,
            this.setStateAsync,
        );
    };

    // If the current user matches match the application user id but the application
    // is assigned to another user, treat the status as 'Submitted'.
    // Note, this checking if the application user is also the assignee. However,
    // it assumes the application user would be the assignee user id, not the person id.
    isSubmitted = (status) =>
        // Either check the status provided or else check the
        // status in the state
        (status && isSubmittedStatus(status)) ||
        isSubmittedStatus(this.state.status) ||
        (!!this.props.userProfile &&
            this.props.userProfile.id === this.state.userId &&
            !!this.state.assignee &&
            this.props.userProfile.id !== this.state.assignee.userId);

    isSectionVisible = (section) =>
        section.entries.some(
            (entry) =>
                (!entry.permissions ||
                    !entry.permissions.length ||
                    entry.permissions.includes(this.state.workflowStatus)) &&
                this.state.visibility[entry.field] !== false,
        );

    handleDeleteConfirm = async () => {
        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        await apiDelete(`applications/${this.props.match.params.id}`, authToken);

        // Set unloadOnFinish to true to avoid attempting to update the application
        // after deleting it.
        this.setState({ confirmDeleteDialogOpen: false, unloadOnFinish: true });

        const prevHistory = this.props.history.location.state?.prevHistory;

        this.props.history.push(prevHistory ?? '/');
    };

    render() {
        if (this.state.form.sections.length === 0) {
            return null;
        }

        if (this.isSubmitted()) {
            return (
                <ApplicantDashboard
                    applicantName={this.state.applicantName}
                    applicationData={this.state.data}
                    form={this.state.form}
                    applicationId={this.props.match.params.id}
                    applicationStatus={this.state.status}
                />
            );
        }

        const visibleSteps = this.state.form.sections
            .map((section, index) => ({ ...section, sectionIndex: index }))
            .filter((section, index) => this.state.sectionStatus[index].visible)
            .filter((section) => this.isSectionVisible(section));

        const section = visibleSteps[this.state.currentStep];

        const savedText = this.state.lastSaved ? `Saved ${this.state.lastSaved}` : '';

        const confirmDeleteDialog = (
            <Dialog open={this.state.confirmDeleteDialogOpen}>
                <DialogTitle>Confirm Request Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="appDeleteConfirmation">
                        Deleting your {this.state.form.name} form cannot be undone. Do you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ confirmDeleteDialogOpen: false })}>No</Button>
                    <Button onClick={this.handleDeleteConfirm} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        );

        const stepper = (
            <>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="h2">{this.state.form.name}</Typography>
                    </Grid>
                    <Grid item xs>
                        {!this.isSubmitted() && (
                            <Tooltip title="Delete this request" placement="bottom">
                                {/* div is necessary to supress console warnings. */}
                                <div style={{ textAlign: 'center' }}>
                                    <IconButton
                                        onClick={() => this.setState({ confirmDeleteDialogOpen: true })}
                                        variant="contained"
                                        style={{ padding: 0 }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        )}
                    </Grid>
                    {confirmDeleteDialog}
                </Grid>
                <Typography variant="caption" color="primary">
                    {this.state.saving ? 'Saving...' : savedText}
                </Typography>
                <Stepper activeStep={this.state.currentStep} orientation="vertical" nonLinear>
                    {visibleSteps.map((section, index) => {
                        const buttonProps = {};
                        let icon;

                        if (this.state.sectionStatus[section.sectionIndex].errors) {
                            buttonProps.optional = (
                                <Typography variant="caption" color="error">
                                    <Pluralize
                                        singular="problem"
                                        count={this.state.sectionStatus[section.sectionIndex].errors}
                                    />
                                </Typography>
                            );

                            icon = <WarningIcon color="error" />;
                        }

                        return (
                            <Step key={section.sectionIndex}>
                                <StepTransition in={true} timeout={600}>
                                    <StepButton
                                        onClick={() => this.handleStepChange(index, section.sectionIndex)}
                                        {...buttonProps}
                                        {...(icon && { icon })}
                                        className={this.props.classes.stepperButton}
                                    >
                                        {section.name}
                                    </StepButton>
                                </StepTransition>
                            </Step>
                        );
                    })}
                </Stepper>
            </>
        );

        const hasErrors = some(this.state.sectionStatus, (section) => section.errors && section.visible);

        return (
            <div className={this.props.classes.root}>
                <Hidden lgUp>
                    <Drawer
                        open={this.state.drawer}
                        onClose={this.hideDrawer}
                        classes={{ paper: this.props.classes.drawer }}
                    >
                        {stepper}
                    </Drawer>
                </Hidden>
                <Hidden mdDown>
                    <Drawer variant="permanent" classes={{ paper: this.props.classes.permanentDrawer }}>
                        <div>
                            <div className={this.props.classes.drawerSpacer} />
                            {stepper}
                        </div>
                    </Drawer>
                </Hidden>
                <Grid container justifyContent="center" spacing={2} className={this.props.classes.grid}>
                    <Grid item xs={12} md={9} lg={10} xl={8}>
                        <Panel key={section.name}>
                            <div className={this.props.classes.sectionHeader}>
                                <Hidden lgUp>
                                    <IconButton onClick={this.showDrawer} title="Open application menu">
                                        <Badge variant="dot" invisible={!hasErrors} color="error">
                                            <MenuIcon />
                                        </Badge>
                                    </IconButton>
                                </Hidden>
                                <Typography variant="h2">{section.name}</Typography>
                                <div className={this.props.classes.grow} />
                                {this.state.deficiencies && this.state.deficiencies.length > 0 && (
                                    <IconButton onClick={this.showDeficiencies}>
                                        <Badge variant="dot" color="error">
                                            <NotesIcon />
                                        </Badge>
                                    </IconButton>
                                )}
                            </div>
                            <div className={this.props.classes.content}>
                                <FormDisplay
                                    applicationId={this.props.match.params.id}
                                    ruleEngine={this.state.ruleEngine}
                                    fields={section.entries}
                                    fieldData={this.state.data}
                                    errors={this.state.errors}
                                    visibility={this.state.visibility}
                                    enabledStatus={this.state.enabledStatus}
                                    onChange={this.handleChange}
                                    onCommit={this.handleCommit}
                                    getAccessToken={this.props.getAccessToken}
                                    authorizationScopes={this.props.authorizationScopes}
                                    workflowStatus={this.state.workflowStatus}
                                />
                            </div>
                            <div className={this.props.classes.actionBar}>
                                <div className={this.props.classes.leftActionBar}>
                                    {this.state.currentStep > 0 && (
                                        <Button variant="contained" titleCase={true} onClick={this.handleBack}>
                                            <ArrowBackIcon className={this.props.leftIcon} />
                                            {visibleSteps[this.state.currentStep - 1].name}
                                        </Button>
                                    )}
                                </div>
                                <div className={this.props.classes.rightActionBar}>
                                    {this.state.currentStep < visibleSteps.length - 1 && (
                                        <Button titleCase={true} variant="contained" onClick={this.handleContinue}>
                                            {visibleSteps[this.state.currentStep + 1].name}
                                            <ArrowForwardIcon className={this.props.rightIcon} />
                                        </Button>
                                    )}
                                    {this.state.currentStep === visibleSteps.length - 1 && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleSubmit}
                                            loading={this.state.loadingFinish}
                                        >
                                            Finish
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className={this.props.classes.footer}>
                                <NavLink to="/">Return to Dashboard</NavLink>
                            </div>
                        </Panel>
                    </Grid>
                </Grid>
                {this.props.isLoading ? <Loader /> : null}
            </div>
        );
    }
}

const mapStateToProps = ({ siteInfo }) => ({
    isLoading: siteInfo.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    loadSpinner: (payload) => {
        dispatch(loadSpinner(payload));
    },
});

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withAuthenticationContext,
    withSnackbar,
)(Form);
