import axios from 'axios';
import { toDate } from './util';

const APP_ROOT = `${window.location.origin}`;
const API_ROOT = process.env.REACT_APP_API_ROOT || `${APP_ROOT}/api`;

const api = axios.create({
    baseURL: API_ROOT,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    transformResponse: [
        function (data) {
            if (data && typeof data === 'string') {
                try {
                    data = JSON.parse(data, (key, value) => {
                        if (/^\d{4}-\d{2}-\d{2}/.test(value)) {
                            return toDate(value);
                        }

                        return value;
                    });
                } catch {
                    // Not JSON response
                }
            }

            return data;
        },
    ],
});

export default api;

function requestConfig(authToken, responseType, config) {
    config = config ?? {};

    if (authToken) {
        config.headers = { ...config.headers, Authorization: `Bearer ${authToken}` };
        config.responseType = responseType ?? config.responseType ?? 'json';
    }

    return config;
}

export const apiGet = async (resource, authToken, responseType, config) => {
    try {
        return await api.get(resource, requestConfig(authToken, responseType, config));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);

        throw e;
    }
};

export const apiPost = async (resource, payload, authToken, responseType) => {
    try {
        return await api.post(resource, payload, requestConfig(authToken, responseType));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);

        throw e;
    }
};

export const apiPut = async (resource, payload, authToken) => {
    try {
        return await api.put(resource, payload, requestConfig(authToken));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);

        throw e;
    }
};

export const apiDelete = async (resource, authToken) => {
    try {
        return await api.delete(resource, requestConfig(authToken));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);

        throw e;
    }
};

export function resourceUrl(path) {
    return `${API_ROOT}/${path}`;
}
