import React from 'react';
import { Grid, Typography, Card, TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../components/Table';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';

const useStyles = makeStyles(() => ({
    cardHours: {
        lineHeight: '57px',
        letterSpacing: '-0.33px',
        color: '#484848',
    },
    card: {
        padding: '30px 50px',
    },
    cardLabel: {
        fontWeight: '500',
        lineHeight: '32px',
        letterSpacing: '1px',
        color: '#333333',
    },
    statusCardLabel: {
        fontWeight: '500',
        lineHeight: '32px',
        color: '#FFFFFF',
        letterSpacing: '1px',
    },
    continueEducationStatus: {
        fontSize: '24px',
        color: '#FFFFFF',
        fontWeight: '500',
        lineHeight: '28px',
        marginTop: '10px',
        letterSpacing: '-0.03px',
    },
    continuingEducationHeader: {
        lineHeight: '32px',
        fontWeight: '500',
        color: '#333333',
    },
    statusIcon: {
        color: '#FFFFFF',
        fontSize: '86px',
    },
    statusCard: {
        background: 'linear-gradient(180deg, #05AFE0 0%, #0277BD 100%)',
        padding: '30px',
    },
}));

const courseTableHeader = [
    { title: 'Course Number', field: 'courseNumber' },
    { title: 'Course Title', field: 'courseTitle' },
    { title: 'Hours Taken', field: 'creditsTaken' },
    { title: 'Date Completed', field: 'dateCompleted', type: 'date' },
    { title: 'Category', field: 'category' },
    { title: 'Provider', field: 'provider' },
];

const categoryTableHeader = [
    { title: 'Course Category', field: 'courseCategory' },
    {
        title: 'Hours Taken',
        field: 'creditsTaken',
        render: (rawData) => rawData.creditsTaken.toFixed(2),
    },
];

const tableStyles = {
    headerStyle: {
        color: '#333333',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.15px',
        lineHeight: '24px',
    },
    rowStyle: {
        color: '#333333',
        fontSize: '14px',
        letterSpacing: '0.15px',
        lineHeight: '20px',
    },
};

const ContinuingEducation = ({ continuingEducation }) => {
    const classes = useStyles();

    const tableData = () => {
        return continuingEducation.categories.flatMap((category) =>
            category.courseCredits.map((course) => {
                return {
                    courseNumber: course.courseNumber,
                    courseTitle: course.courseTitle,
                    creditsTaken: course.creditsTaken.toFixed(2),
                    dateCompleted: course.dateCompleted,
                    category: category.courseCategory,
                    provider: course.course ? course.course.providerName : 'Unavailable',
                };
            }),
        );
    };

    const continuingEducationProgress =
        continuingEducation.cycleCreditsRequired === 0
            ? 0
            : (continuingEducation.creditsTaken / continuingEducation.cycleCreditsRequired) * 100;
    let statusIcon = (
        <CircularProgressBar
            percent={continuingEducationProgress}
            colorSlice="#FFFFFF"
            colorCircle="#27739D"
            fontColor="#FFFFFF"
            fontSize="16px"
            fontWeight="500"
            size="85"
            stroke="10"
            strokeBottom="10"
            cut="0"
            rotation="-90"
            unit="%"
            round={true}
        />
    );

    let continuingEducationStatus = 'In Progress';
    if (continuingEducationProgress === 0) {
        continuingEducationStatus = 'Not Started';
    } else if (continuingEducationProgress === 100) {
        continuingEducationStatus = 'Completed';
        statusIcon = <CheckCircleOutlineIcon className={classes.statusIcon} />;
    }

    return (
        <Grid container spacing={3} direction="column">
            <Grid item container>
                <Typography variant="h5" className={classes.continuingEducationHeader}>
                    Continuing Education
                </Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={3}>
                    <Card className={classes.statusCard}>
                        <Grid xs={12} container spacing={2}>
                            <Grid item>{statusIcon}</Grid>

                            <Grid item>
                                <Typography variant="overline" className={classes.statusCardLabel}>
                                    STATUS
                                </Typography>
                                <Typography className={classes.continueEducationStatus}>
                                    {continuingEducationStatus}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <Typography variant="overline" className={classes.cardLabel}>
                            HOURS REQUIRED
                        </Typography>
                        <Typography variant="h3" className={classes.cardHours}>
                            {continuingEducation.cycleCreditsRequired}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <Typography variant="overline" className={classes.cardLabel}>
                            HOURS TAKEN
                        </Typography>
                        <Typography variant="h3" className={classes.cardHours}>
                            {continuingEducation.creditsTaken}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <Typography variant="overline" className={classes.cardLabel}>
                            HOURS REMAINING
                        </Typography>
                        <Typography variant="h3" className={classes.cardHours}>
                            {continuingEducation.creditsRemaining}
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
            <Grid item container>
                <TableContainer component={Paper}>
                    <Table
                        columns={courseTableHeader}
                        data={tableData()}
                        noDataMessage="No records to display"
                        options={{
                            sorting: true,
                            paging: false,
                            toolbar: false,
                            ...tableStyles,
                        }}
                        icons={{
                            SortArrow: ArrowDropDownIcon,
                        }}
                        components={{
                            Container: function ContainerComponent({ children }) {
                                return <div>{children}</div>;
                            },
                        }}
                    />
                </TableContainer>
            </Grid>
            <Grid item container xs={6}>
                <TableContainer component={Paper}>
                    <Table
                        columns={categoryTableHeader}
                        data={continuingEducation.categories}
                        noDataMessage="No records to display"
                        options={{
                            sorting: true,
                            paging: false,
                            toolbar: false,
                            ...tableStyles,
                        }}
                        icons={{
                            SortArrow: ArrowDropDownIcon,
                        }}
                        components={{
                            Container: function ContainerComponent({ children }) {
                                return <div>{children}</div>;
                            },
                        }}
                    />
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default ContinuingEducation;
