import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import NavLink from './components/NavLink';
import Panel from './components/Panel';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 28,
        marginBottom: 25,
        fontWeight: 500,
    },
    message: {
        fontSize: 16,
    },
    field: {
        fontSize: 14,
        marginTop: 15,
        marginBottom: 15,
    },
    list: {
        marginTop: 30,
        marginBottom: 70,
    },
    dashboardLink: {
        textAlign: 'center',
    },
}));

export default function SubmissionStatusPanel(props) {
    const classes = useStyles();
    const confirmationFields = props.confirmationFields || [];

    return (
        <Panel>
            <Typography className={classes.title}>{props.title || 'Submission Confirmed!'}</Typography>
            <Typography className={classes.message}>{props.message}</Typography>
            <div className={classes.list}>
                {confirmationFields.map((field, index) => {
                    return (
                        <div className={classes.field} key={index}>
                            {field.fieldLabel}: {field.fieldData}
                        </div>
                    );
                })}
            </div>
            <NavLink className={classes.dashboardLink} to="/">
                Return to Dashboard
            </NavLink>
        </Panel>
    );
}

SubmissionStatusPanel.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    confirmationFields: PropTypes.arrayOf(
        PropTypes.shape({
            fieldLabel: PropTypes.string.isRequired,
            fieldData: PropTypes.string,
        }),
    ),
};
