import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useAccessTokens } from '../../Authenticate';
import PopupActionMenu from '../../components/PopupActionMenu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { grey } from '@material-ui/core/colors';
import { apiGet } from '../../api';
import { applicationCreate } from '../../store/RootActions';
import Button from '../../components/Button';

const useStyles = makeStyles(() => ({
    menuButton: {
        backgroundColor: grey[200],
        color: 'rgba(0, 0, 0, 0.54)',
    },
}));

function ActionMenu(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { applicationId } = props;
    const [actions, setActions] = useState([]);
    const [anchor, setAnchor] = useState();
    const [loading, setLoading] = useState(false);
    const { authorizationScopes, getAccessToken } = useAccessTokens();

    const loadActions = async (event) => {
        event.stopPropagation();
        setLoading(true);
        setAnchor(event.currentTarget);
        try {
            const token = await getAccessToken(authorizationScopes.applications);
            const result = await apiGet(`applications/${applicationId}/actions`, token);
            const actions = result.data;
            setActions(actions);
        } catch (error) {
            const errorResponse = error?.response?.data;
            if (errorResponse?.error?.statusCode === 500 && errorResponse?.partialResponse) {
                setActions(errorResponse.partialResponse);
            }
        }
        setLoading(false);
    };

    const onClose = () => {
        setAnchor(undefined);
        setActions([]);
    };

    const selectAction = (action) => {
        dispatch(
            applicationCreate({
                data: { formId: action.formId, context: { applicationId } },
                getAccessToken,
                authorizationScopes,
                dispatcher: 'ApplicantDashboard',
            }),
        );
    };

    return (
        <>
            <Button variant="text" titleCase={true} className={classes.menuButton} onClick={loadActions} size="small">
                Actions <KeyboardArrowDownIcon />
            </Button>
            <PopupActionMenu
                anchor={anchor}
                actions={actions}
                onClose={onClose}
                loading={loading}
                onActionClick={selectAction}
            />
        </>
    );
}

export default ActionMenu;
