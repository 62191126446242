import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const NavLink = ({ to, children, linkProps, ...rest }) => {
    return (
        <Typography {...rest}>
            <Link component={RouterLink} to={to} {...linkProps} color="primary" underline="hover">
                {children}
            </Link>
        </Typography>
    );
};

export default NavLink;
