import React, { Component } from 'react';
import { compose } from 'redux';
import { withAuthenticationContext } from '../Authenticate';
import Grid from '@material-ui/core/Grid';
import FeePanel from './FeePanel';
import SummaryDetails from './SummaryDetails';
import { withRouter } from 'react-router-dom';
import { apiGet } from '../api';
import { withSnackbar } from 'notistack';

class Summary extends Component {
    state = {
        application: null,
        isStatusErrorSnackbarOpen: false,
    };

    async componentDidMount() {
        const applicationId = this.props.match.params.id;
        const token = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        try {
            const result = await apiGet(`/applications/${applicationId}`, token);
            const application = result.data;

            if (application.status !== 'Completed') {
                this.props.history.push(`/applications/${applicationId}`);
            } else {
                this.setState({ application });
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                this.props.history.push('/404');
            }
        }
    }

    handleCloseStatusErrorSnackbar = () => {
        this.setState({ isStatusErrorSnackbarOpen: false });
    };

    setStatusErrorSnackbarOpen = (isOpen) => {
        this.setState({ isStatusErrorSnackbarOpen: isOpen });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isStatusErrorSnackbarOpen && !prevState.isStatusErrorSnackbarOpen) {
            this.props.enqueueSnackbar(
                'Unfortunately, an error has occurred. Please try refreshing the page. If the problem persists, please contact customer support.',
                {
                    onClose: this.handleCloseStatusErrorSnackbar,
                    variant: 'error',
                    preventDuplicate: true,
                    persist: true,
                },
            );
        }
    }

    componentWillUnmount() {
        this.props.closeSnackbar();

        const history = this.props.history;

        if (history.action === 'POP' && history.location.pathname === `/applications/${this.state.application?.id}`) {
            history.replace(history.location.pathname, {
                ...history.location.state,
                resetStatus: true,
            });
        }
    }

    render() {
        return (
            this.state.application && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <FeePanel
                            applicationId={this.props.match.params.id}
                            getAccessToken={this.props.getAccessToken}
                            authorizationScopes={this.props.authorizationScopes}
                            setStatusErrorSnackbarOpen={this.setStatusErrorSnackbarOpen}
                            isStatusErrorSnackbarOpen={this.state.isStatusErrorSnackbarOpen}
                        ></FeePanel>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <SummaryDetails
                            getAccessToken={this.props.getAccessToken}
                            authorizationScopes={this.props.authorizationScopes}
                            application={this.state.application}
                        ></SummaryDetails>
                    </Grid>
                </Grid>
            )
        );
    }
}

export default compose(withRouter, withAuthenticationContext, withSnackbar)(Summary);
