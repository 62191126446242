import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import { apiGet } from './api';
import { useAccessTokens } from './Authenticate';
import { ChevronLeft as BackIcon, MoreVert as MoreIcon } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import NavLink from './components/NavLink';
import moment from 'moment';
import ActionMenu from './components/PanelElements/elementTypes/LicenseTable/ActionMenu';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from './components/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { failedDependentRequest } from './store/RootActions';

const useStyles = makeStyles(() => ({
    card: {
        paddingBottom: '1px',
    },
    topCard: {
        padding: '10px 0px 10px 50px',
        backgroundColor: grey[100],
    },
    cardLabel: {
        fontWeight: '500',
        color: '#333333',
    },
    dependentCard: {
        margin: '200px',
    },
    title: {
        paddingBottom: '16px',
    },
    returnButton: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    loader: {
        marginTop: 75,
        size: '600px',
        color: 'primary',
    },
    muiButton: {
        textTransform: 'none',
    },
}));

export default function ManageDependents({ location }) {
    const classes = useStyles();
    const { getAccessToken, authorizationScopes } = useAccessTokens();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    // Default to undefined to indicate loading status.
    const [dependents, setDependents] = useState();
    const [relationshipTypes, setRelationshipTypes] = useState([]);
    const [dependentsSnackbarKey, setDependentsSnacbarKey] = useState();
    const [rejectedErrorSnackbarKey, setRejectedErrorSnacbarKey] = useState();
    const [loadingFailed, setLoadingFailed] = useState(false);
    const [loadingFailedrSnackbarKey, setloadingFailedSnacbarKey] = useState();

    const dependentConfig = useSelector(({ siteInfo }) => siteInfo.manageDependents);
    const rejectedRequestMessage = useSelector(({ manageDependents }) => manageDependents.rejectedRequestMessage);

    const title = dependentConfig?.title ?? 'Dependents';
    const titleLowerCase = title.toLowerCase();

    const { license } = location.state;

    const closeRejectedErrorSnackbar = () => dispatch(failedDependentRequest(undefined));

    useEffect(() => {
        (async function () {
            const response = await apiGet(
                'codedValues/predicate license relationship',
                await getAccessToken(authorizationScopes.codedValues),
            );

            setRelationshipTypes(response.data);
        })();
    }, [getAccessToken, setRelationshipTypes]);

    useEffect(() => {
        if (!dependents) {
            const key = enqueueSnackbar('This may take a few minutes', { variant: 'info', persist: true });

            setDependentsSnacbarKey(key);
        } else {
            closeSnackbar(dependentsSnackbarKey);
        }

        return () => closeSnackbar(dependentsSnackbarKey);
    }, [dependents]);

    useEffect(() => {
        if (rejectedRequestMessage) {
            const key = enqueueSnackbar(rejectedRequestMessage, {
                onClose: closeRejectedErrorSnackbar,
                variant: 'error',
            });

            setRejectedErrorSnacbarKey(key);
        } else {
            closeSnackbar(rejectedErrorSnackbarKey);
        }

        return () => closeSnackbar(rejectedErrorSnackbarKey);
    }, [rejectedRequestMessage]);

    useEffect(() => {
        if (loadingFailed) {
            const key = enqueueSnackbar(`We were unable to load the ${titleLowerCase}`, {
                variant: 'error',
                persist: true,
            });

            setloadingFailedSnacbarKey(key);
        } else {
            closeSnackbar(loadingFailedrSnackbarKey);
        }

        return () => closeSnackbar(loadingFailedrSnackbarKey);
    }, [loadingFailed]);

    useEffect(() => {
        (async function () {
            const relationshipTypeFilterConfig = dependentConfig.relationshipTypeFilter;

            let relationshipTypeFilter = () => true;

            if (relationshipTypeFilterConfig) {
                if (relationshipTypeFilterConfig.include) {
                    relationshipTypeFilter = (dependent) =>
                        relationshipTypeFilterConfig.include.includes(dependent.relationshipType);
                } else if (relationshipTypeFilterConfig.exclude) {
                    relationshipTypeFilter = (dependent) =>
                        !relationshipTypeFilterConfig.exclude.includes(dependent.relationshipType);
                }
            }

            const dependents = (
                await apiGet(`licenses/${license.id}/dependents`, await getAccessToken(authorizationScopes.licenses))
            ).data
                // Filter out attribute and self dependents. This should really be done on a database level but
                // for backwards compatibility reasons it can't be done there so they must be filtered
                // out here.
                .filter(({ relationshipType }) => relationshipType !== '4' && relationshipType !== '1')
                .filter(relationshipTypeFilter);

            const statusFilterConfig = dependentConfig?.statusFilter;

            let statusFilter = () => true;

            if (statusFilterConfig) {
                if (statusFilterConfig.include) {
                    statusFilter = (dependentWithPerson) =>
                        statusFilterConfig.include.includes(dependentWithPerson.licenseStatus);
                } else if (statusFilterConfig.exclude) {
                    statusFilter = (dependentWithPerson) =>
                        !statusFilterConfig.exclude.includes(dependentWithPerson.licenseStatus);
                }
            }

            let dependentsWithPerson = await loadDependents(dependents);

            if (statusFilterConfig) {
                dependentsWithPerson = dependentsWithPerson.filter(statusFilter);
            }

            setDependents(dependentsWithPerson);
        })();
    }, [getAccessToken, setDependents, dependentConfig]);

    const loadDependents = async (dependents) => {
        let dependentLicenses = [];
        const ids = dependents.map(({ licenseId }) => licenseId);

        const chunks = [];

        while (ids.length) {
            chunks.push(ids.splice(0, 200));
        }

        try {
            for (const chunk of chunks) {
                dependentLicenses = dependentLicenses.concat(
                    (
                        await apiGet('licenses', await getAccessToken(authorizationScopes.licenses), null, {
                            params: {
                                filter: {
                                    where: { id: { inq: chunk } },
                                    fields: {
                                        id: true,
                                        licenseNumber: true,
                                        type: true,
                                        profession: true,
                                        status: true,
                                        person: true,
                                    },
                                },
                            },
                        })
                    ).data,
                );
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);

            setLoadingFailed(true);
        }

        return dependents.map((dependent) => {
            const dependentLicense = dependentLicenses.find(({ id }) => id === dependent.licenseId);

            return {
                ...dependent,
                licenseType: dependentLicense.type.name,
                personName: dependentLicense.person.name,
                professionId: dependentLicense.profession?.id,
                licenseStatus: dependentLicense.status,
                dependentLicense,
            };
        });
    };

    function generateAddress(address) {
        if (!address) {
            return '';
        }

        return `${address.line1}, ${address.city}, ${address.state} ${address.zipcode}`;
    }

    return (
        <SnackbarProvider>
            <Grid item container spacing={4} xs={12} direction="row">
                <Grid item>
                    <NavLink to="/" variant="subtitle1">
                        <span className={classes.returnButton}>
                            <BackIcon />
                            Dashboard
                        </span>
                    </NavLink>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item className={classes.title}>
                        <Typography variant="h4">{title}</Typography>
                    </Grid>
                    <Grid container item xs={12} direction="column">
                        <Typography variant="subtitle1">{license.person.name}</Typography>
                        <Typography variant="subtitle1">{generateAddress(license.address)}</Typography>
                        <Typography variant="subtitle1">License number: {license.licenseNumber}</Typography>
                    </Grid>
                </Grid>
                {!dependents ? (
                    <Grid container xs={12} item justifyContent="center">
                        <CircularProgress className={classes.loader} />
                    </Grid>
                ) : !dependents.length ? (
                    <Grid container xs={12} item justifyContent="center">
                        <Typography variant="h5" className={classes.cardHours}>{`No ${titleLowerCase}`}</Typography>
                    </Grid>
                ) : (
                    <Grid container xs={12} item direction="row">
                        {dependents.map((dependent, index) => (
                            <DependentCard
                                key={index}
                                className={classes.dependentCard}
                                dependent={dependent}
                                prerequisiteId={license.id}
                                relationshipTypes={relationshipTypes}
                            />
                        ))}
                    </Grid>
                )}
            </Grid>
        </SnackbarProvider>
    );
}

const DependentCard = React.memo(({ dependent, prerequisiteId, relationshipTypes }) => {
    const classes = useStyles();

    const [actionMenuAnchor, setActionMenuAnchor] = useState();
    const [relationshipTypeLabel, setRelationshipTypeLabel] = useState();

    const isActionMenuClickedRef = useRef(false);

    useEffect(() => {
        setRelationshipTypeLabel(
            relationshipTypes.find((relationshipType) => relationshipType.id.toString() === dependent.relationshipType)
                ?.label,
        );
    }, [relationshipTypes, setRelationshipTypeLabel, dependent]);

    const handleActionMenu = (event) => {
        event.stopPropagation();
        setActionMenuAnchor(event.currentTarget);

        isActionMenuClickedRef.current = true;
    };

    return (
        <>
            <Grid item xs={12} className={classes.card}>
                <Card>
                    <Grid item container xs={12} justifyContent="space-evenly">
                        <Grid container item className={classes.topCard} xs={12}>
                            <Grid container item xs={11}>
                                <Grid item container justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="h6" className={classes.cardLabel}>
                                            {dependent.personName}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6" className={classes.cardLabel}>
                                            {relationshipTypeLabel}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.cardHours}>
                                            {dependent.dependentLicense.licenseNumber ?? '--'}
                                            {', '}
                                            {dependent.licenseType}
                                            {', '}
                                            {dependent.licenseStatus}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.cardHours}>
                                            Associated On{' '}
                                            {dependent.dateOfAssociation
                                                ? moment(dependent.dateOfAssociation).format('MM/DD/YYYY')
                                                : '--'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs justifyContent="space-around" alignItems="center">
                                <Grid item>
                                    <Tooltip title="Actions">
                                        {/* div is necessary to suppress console warnings */}
                                        <div>
                                            <IconButton onClick={(event) => handleActionMenu(event)}>
                                                <MoreIcon aria-label="Menu" />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <ActionMenu
                                        anchor={actionMenuAnchor}
                                        onClose={() => setActionMenuAnchor(undefined)}
                                        license={isActionMenuClickedRef.current ? dependent.dependentLicense : null}
                                        disableViewLicense={true}
                                        prerequisiteId={prerequisiteId}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
});

// eslint complains if there's no display name.
DependentCard.displayName = 'DependentCard';
