import { connect } from 'react-redux';
import { applicationCreateFailed, panelsLoad } from '../../store/RootActions';
import PagePanels from './PagePanels';

const mapStateToProps = (page) => ({
    page,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => {
        dispatch(panelsLoad('home'));
    },
    clearCreateApplicationError: () => {
        dispatch(applicationCreateFailed(undefined));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PagePanels);
