import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

//  Function to normalize the values (MIN / MAX could be integrated)
const normalizedValue = (value, max, min) => ((value - min) * 100) / (max - min);

const CustomCircularProgress = styled(({ color, ...otherProps }) => (
    <CircularProgress color="inherit" {...otherProps} />
))`
    color: ${(props) => props.color};
`;

const CustomLinearProgress = styled(({ color, ...otherProps }) => <LinearProgress {...otherProps} />)`
    color: ${(props) => props.color};
`;

class ProgressBar extends React.Component {
    render() {
        const { color, type, max, min, ...rest } = this.props;

        const muiColors = ['inherit', 'primary', 'secondary'];

        if (this.props.color) {
            if (!muiColors.includes(this.props.color)) {
                switch (this.props.type) {
                    case 'linear':
                        return <CustomLinearProgress color={color} {...rest} />;
                    default:
                        return <CustomCircularProgress color={color} {...rest} />;
                }
            } else {
                switch (this.props.type) {
                    case 'linear':
                        return (
                            <LinearProgress
                                {...this.props}
                                value={normalizedValue(this.props.value, max || 100, min || 0)}
                            />
                        );
                    default:
                        return (
                            <CircularProgress
                                {...this.props}
                                value={normalizedValue(this.props.value, max || 100, min || 0)}
                            />
                        );
                }
            }
        } else {
            switch (this.props.type) {
                case 'linear':
                    return (
                        <LinearProgress
                            {...this.props}
                            value={normalizedValue(
                                this.props.value,
                                this.props.max ? this.props.max : 100,
                                this.props.min ? this.props.min : 0,
                            )}
                        />
                    );
                default:
                    return (
                        <CircularProgress
                            {...this.props}
                            value={normalizedValue(
                                this.props.value,
                                this.props.max ? this.props.max : 100,
                                this.props.min ? this.props.min : 0,
                            )}
                        />
                    );
            }
        }
    }
}

ProgressBar.propTypes = {
    /**  Takes in color name (red, blue, green) the hex of a custom color customColor (#DB7093) rgb (rgb(255, 26, 26)), hsl, and  material ui theme colors (blue[500])}*/
    color: PropTypes.string,
};

export default withTheme(ProgressBar);
