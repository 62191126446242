import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormField from './FormField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '../components/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import jp from 'jsonpath';

const transitionDuration = {
    enter: 800,
    exit: 300,
};

const styles = () => ({
    hidden: {
        padding: '0 !important', // MUI styles will override the padding without !important
        transitionProperty: 'padding',
        transitionDuration: transitionDuration.exit,
    },
});

class FormDisplay extends React.Component {
    state = {
        infoField: '',
        infoContent: '',
    };

    handleInfoDialog = (field, content) => {
        this.setState({ infoField: field, infoContent: content });
    };

    handleDialogClose = () => {
        this.setState({ infoField: '', infoContent: '' });
    };

    render() {
        const {
            applicationId,
            fields,
            fieldData,
            errors,
            visibility,
            enabledStatus,
            onChange,
            onCommit,
            classes,
            getAccessToken,
            authorizationScopes,
            parentRepeatableFieldKey,
            parentRepeatableFieldData,
            workflowStatus,
            searchMappedData,
        } = this.props;

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    {fields.map((entry) => {
                        let searchMappingValue;
                        if (entry.fieldMapping?.targetPath) {
                            searchMappingValue = jp.value(searchMappedData ?? {}, entry.fieldMapping?.targetPath);
                        }

                        return (
                            <Grid
                                item
                                key={entry.field}
                                xs={12}
                                md={entry.width || 12}
                                className={classNames({
                                    [classes.hidden]:
                                        (entry.permissions &&
                                            entry.permissions.length &&
                                            !entry.permissions.includes(workflowStatus)) ||
                                        visibility[entry.field] === false,
                                })}
                            >
                                <FormField
                                    field={entry}
                                    parentRepeatableFieldKey={parentRepeatableFieldKey}
                                    parentRepeatableFieldData={parentRepeatableFieldData}
                                    value={
                                        fieldData[entry.field] !== undefined && fieldData[entry.field] !== null
                                            ? fieldData[entry.field]
                                            : searchMappingValue ?? ''
                                    }
                                    applicationData={fieldData}
                                    applicationId={applicationId}
                                    ruleEngine={this.props.ruleEngine}
                                    error={errors[entry.field]}
                                    onChange={onChange}
                                    onCommit={onCommit}
                                    visible={
                                        (!entry.permissions ||
                                            !entry.permissions.length ||
                                            entry.permissions.includes(workflowStatus)) &&
                                        visibility[entry.field]
                                    }
                                    enabled={
                                        enabledStatus[entry.field] !== undefined ? enabledStatus[entry.field] : true
                                    }
                                    transitionDuration={transitionDuration}
                                    onAdditionalInfo={this.handleInfoDialog}
                                    getAccessToken={getAccessToken}
                                    authorizationScopes={authorizationScopes}
                                    workflowStatus={workflowStatus}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Dialog open={Boolean(this.state.infoContent)} onClose={this.handleDialogClose} maxWidth="md">
                    <DialogTitle>{this.state.infoField}</DialogTitle>
                    <DialogContent tabIndex="0">
                        <DialogContentText>{this.state.infoContent}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(FormDisplay);
