import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import formFinder from './FormFinder/reducer';
import organizations from './SelectOrganization/reducer';
import pagePanels from './PagePanels/reducer';
import siteInfo from './SiteInfo/reducer';
import manageDependents from './ManageDependents/reducer';
import applicantDashboard from './ApplicantDashboard/reducer';

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        formFinder,
        organizations,
        pagePanels,
        siteInfo,
        manageDependents,
        applicantDashboard,
    });
