import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { getMsalConfig } from './authConfig';
import { PublicClientApplication } from '@azure/msal-browser';

function MsalWrapper({ children }) {
    const [msalInstance, setMsalInstance] = useState();
    const { authentication } = useSelector((state) => state.siteInfo);

    useEffect(() => {
        if (authentication) {
            const msalConfig = getMsalConfig(authentication);
            const msalInstance = new PublicClientApplication(msalConfig);

            setMsalInstance(msalInstance);
        } else {
            setMsalInstance(undefined);
        }
    }, [authentication]);

    if (!msalInstance) {
        return null;
    }

    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}

export default MsalWrapper;
