import { apiGet, apiPut, apiPost } from '../api';
import { Form } from '@mylicenseone/forms';
import moment from 'moment';

const momentTZ = require('moment-timezone');

export const loadForm = async (applicationId, authToken, updateState, history, isSubmittedFn) => {
    try {
        const result = await apiGet(`applications/${applicationId.id}`, authToken);
        const application = result.data;
        return transformFormState(application, updateState, authToken, history, isSubmittedFn);
    } catch (error) {
        if (error.response && error.response.status === 404) {
            history.push('/404');
        }
    }
};

async function transformFormState(application, updateState, authToken, history, isSubmittedFn) {
    const form = application.form;
    const workflowStatus = application.workflowStatus;
    const assignee = application.assignee;
    const userId = application.userId;
    let data;
    let status;
    let applicantName = '';

    if (application.data !== undefined) {
        data = application.data;
        if (application.status !== undefined) {
            status = application.status;
        }
        if (application.applicantName !== undefined) {
            applicantName = application.applicantName;
        }
    } else {
        data = {};
    }

    if (status && status === 'Completed') {
        if (!history.location.state?.resetStatus) {
            history.push(`${application.id}/summary`);

            return;
        }

        // Reset status to 'Started'.
        await apiPut(`applications/${application.id}/resetStatus`, null, authToken);

        status = 'Started';
    }

    let ruleEngine = {};

    // Should not evaluate an application after it's been submitted.
    if (!isSubmittedFn(status)) {
        ruleEngine = new Form(form);

        let results = await apiPost(
            `applications/${application.id}/evaluate?validateDefinedOnly=true&disableNestedRules=true`,
            {
                data: {
                    ...data,
                    _workflowStatus: workflowStatus,
                },
            },
            authToken,
        );

        updateApplicationRules(results.data, updateState);
    }

    const saving = false;
    const lastSaved = '';

    updateState({
        data,
        form,
        status,
        assignee,
        userId,
        workflowStatus,
        ruleEngine,
        saving,
        lastSaved,
        applicantName,
    });
}

export const saveForm = async (applicationId, authToken, getLocalState, updateState) => {
    updateState({ saving: true });

    return saveApplicationData(applicationId, getLocalState().data, authToken)
        .then(() => {
            const timeZone = momentTZ.tz(momentTZ.tz.guess()).zoneAbbr();
            const lastSaved = `${moment().format('LT')} ${timeZone}`;
            updateState({ lastSaved, saving: false });
        })
        .catch(() => {
            updateState({ saving: false });
        })
        .finally();
};

async function saveApplicationData(applicationId, data, authToken) {
    return apiPut(`applications/${applicationId}/data`, data, authToken);
}

export const runBusinessRules = async (getLocalState, applicationId, updateState, authToken) => {
    const { data, finishButtonClicked, workflowStatus } = getLocalState();

    let results = await apiPost(
        `applications/${applicationId}/evaluate?validateDefinedOnly=${!finishButtonClicked}&disableNestedRules=true`,
        {
            data: {
                ...data,
                _workflowStatus: workflowStatus,
            },
        },
        authToken,
    );

    updateApplicationRules(results.data, updateState);
};

const runAllBusinessRules = (applicationId, authToken, history, getLocalState, updateState, updateStateAsync) => {
    const form = getLocalState();
    let formValid = false;
    formValidation(form, applicationId, updateState, authToken)
        .then(async (valid) => {
            formValid = valid;

            if (formValid) {
                try {
                    await apiPost(`applications/${applicationId}/preSubmit`, null, authToken);
                    await updateStateAsync({ unloadOnFinish: true });
                    history.push(`${applicationId}/summary`);
                } catch (err) {
                    // Record an error when ssn validation fails. The closest we
                    // know is that the error will be a 422 so only display the
                    // message for that status code.
                    if (err.response.status === 422) {
                        updateState({ unloadOnFinish: false, finishError: true });
                    }
                }
            }
        })
        .finally(() => {
            updateState({ loadingFinish: false });
        });
};

async function formValidation(form, applicationId, updateState, authToken) {
    let results = await apiPost(
        `applications/${applicationId}/evaluate`,
        {
            data: {
                ...form.data,
                _workflowStatus: form.workflowStatus,
            },
        },
        authToken,
    );

    updateApplicationRules(results.data, updateState);
    return !results.data.sections.some((section) => section.errors > 0);
}

export const saveAndValidateForm = (
    applicationId,
    authToken,
    history,
    getLocalState,
    updateState,
    updateStateAsync,
) => {
    const form = getLocalState();

    saveApplicationData(applicationId, form.data, authToken)
        .then(() =>
            runAllBusinessRules(applicationId, authToken, history, getLocalState, updateState, updateStateAsync),
        )
        .catch(() => {
            updateState({ loadingFinish: false });
        });
};

function updateApplicationRules(ruleData, updateState) {
    const fields = ruleData.fields;
    for (const field in fields) {
        updateState((prevState) => ({
            errors: {
                ...prevState.errors,
                [field]: {
                    error: fields[field].error,
                    messages: fields[field].errorMessages,
                },
            },
            visibility: {
                ...prevState.visibility,
                [field]: fields[field].visible,
            },
            enabledStatus: {
                ...prevState.enabledStatus,
                [field]: fields[field].enabled,
            },
        }));
    }

    const sections = ruleData.sections;
    for (const section in sections) {
        updateState((prevState) => ({
            sectionStatus: {
                ...prevState.sectionStatus,
                [section]: {
                    errors: sections[section].errors,
                    visible: sections[section].visible,
                    enabled: sections[section].enabled,
                },
            },
        }));
    }
}
