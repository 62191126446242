import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Panel from '../components/Panel';
import SummaryDisplay from './SummaryDisplay';
import { apiPost } from '../api';

const styles = (theme) => ({
    section: {
        marginBottom: theme.spacing(8),
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: 'solid 2px',
        borderColor: theme.palette.divider,
        paddingBottom: '10px',
        marginBottom: '10px',
    },
});

class SummaryDetails extends Component {
    state = {
        initialized: false,
    };

    async componentDidMount() {
        this.setState({ visibility: {} });

        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        const results = (
            await apiPost(
                `applications/${this.props.application.id}/evaluate`,
                {
                    data: {
                        ...this.props.application.data,
                        _workflowStatus: this.props.application.workflowStatus,
                    },
                },
                authToken,
            )
        ).data;

        Object.entries(results.fields).forEach(([field, result]) => {
            this.setState((prevState) => ({
                visibility: {
                    ...prevState.visibility,
                    [field]: result.visible,
                },
            }));

            result.nestedFields?.forEach((nestedFieldResult, index) => {
                Object.entries(nestedFieldResult).forEach(([nestedField, nestedResult]) => {
                    this.setState((prevState) => ({
                        visibility: {
                            ...prevState.visibility,
                            [`${field}[${index}].${nestedField}`]: nestedResult.visible,
                        },
                    }));
                });
            });
        });

        this.setState({ initialized: true });
    }

    isSectionVisible = (section) =>
        section.entries.some(
            (entry) =>
                (!entry.permissions ||
                    !entry.permissions.length ||
                    entry.permissions.includes(this.props.application.workflowStatus)) &&
                this.state.visibility[entry.field] !== false &&
                entry.typeId !== 'system',
        );

    render() {
        if (!this.state.initialized) {
            return null;
        }

        const { classes } = this.props;
        const application = this.props.application.form.sections.map((section) => {
            // Add a filter here to check section visibility based on rulesEngine's visibility status.
            if (this.isSectionVisible(section)) {
                return (
                    <div className={classes.section} key={section.name}>
                        <div className={classes.sectionHeader}>
                            <Typography variant="h2">{section.name}</Typography>
                        </div>
                        <SummaryDisplay
                            summary={true}
                            fields={section.entries}
                            fieldData={this.props.application.data}
                            visibility={this.state.visibility}
                            workflowStatus={this.props.application.workflowStatus}
                        />
                    </div>
                );
            }
        });

        return <Panel>{application}</Panel>;
    }
}
export default withStyles(styles)(SummaryDetails);
