import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';
import * as colors from '@material-ui/core/colors';
import initials from 'initials';
import isString from 'lodash/isString';
import { hashCode } from 'hash-code';

const colorMap = [
    colors.amber[500],
    colors.blue[700],
    colors.blueGrey[600],
    colors.brown[500],
    colors.cyan[400],
    colors.deepOrange[900],
    colors.deepPurple[500],
    colors.green[400],
    colors.grey[400],
    colors.indigo[500],
    colors.lightBlue[400],
    colors.lightGreen[500],
    colors.lime[500],
    colors.orange[500],
    colors.pink[600],
    colors.purple[500],
    colors.red[700],
    colors.teal[700],
    colors.yellow[500],
];

const useStyles = makeStyles((theme) => {
    function avatarBackground(text) {
        return colorMap[((hashCode(text) % colorMap.length) + colorMap.length) % colorMap.length];
    }

    return {
        avatar: (props) => ({
            backgroundColor: avatarBackground(props.text),
            color: theme.palette.getContrastText(avatarBackground(props.text)),
        }),
    };
});

export default function Avatar({ children, ...rest }) {
    let originalText = '';
    let avatarText = '';

    if (isString(children)) {
        originalText = children;
        avatarText = initials(children);
    }

    const classes = useStyles({ text: avatarText, ...rest });

    return (
        <MuiAvatar className={classes.avatar} {...rest} aria-label={originalText} title={originalText}>
            {avatarText}
        </MuiAvatar>
    );
}
