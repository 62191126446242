import { connect } from 'react-redux';
import {
    formFinderAppCreateFailed,
    formFinderLoad,
    formFinderUnload,
    applicationCreate,
    organizationSelect,
} from '../store/RootActions';
import FormFinder from './FormFinder';

const mapStateToProps = ({ formFinder }) => ({
    questionnaire: formFinder.questionnaire,
    eligibilityErrorMessage: formFinder.eligibilityErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (props) => {
        dispatch(formFinderLoad(props));
    },
    onUnload: () => {
        dispatch(formFinderUnload());
    },
    onAppStart: (stepDefinition, getAccessToken, authorizationScopes, userProfile) => {
        const payload = {
            data: {
                formId: stepDefinition.form,
            },
            getAccessToken,
            authorizationScopes,
            userProfile,
            dispatcher: 'FormFinder',
        };
        if (stepDefinition.selectFacility) {
            dispatch(organizationSelect(payload));
        } else {
            dispatch(applicationCreate(payload));
        }
    },
    clearFormFinderCreateAppError: () => dispatch(formFinderAppCreateFailed(undefined)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormFinder);
