import React, { Component } from 'react';
import Panel from '../components/Panel';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NavLink from '../components/NavLink';

const styles = (theme) => ({
    link: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 'inherit',
    },
    content: {
        textAlign: 'center',
    },
    line1: {
        fontWeight: 'bold',
        fontSize: 55,
        marginBottom: 30,
    },
    line2: {
        fontSize: 21,
        marginBottom: 15,
    },
    line3: {
        fontSize: 16,
    },
});

class NotFoundError extends Component {
    render() {
        return (
            <div>
                <Panel className={this.props.classes.content}>
                    <Typography className={this.props.classes.line1}>Oops!</Typography>
                    <Typography className={this.props.classes.line2}>
                        We can&apos;t seem to find the page you&apos;re looking for.
                    </Typography>

                    {this.props.siteInfo.siteExists && (
                        <div>
                            <Typography display="inline" className={this.props.classes.line3}>
                                Return to the{' '}
                            </Typography>
                            <NavLink
                                to="/"
                                display="inline"
                                linkProps={{ className: this.props.classes.link }}
                                className={this.props.classes.line3}
                            >
                                Dashboard
                            </NavLink>
                        </div>
                    )}
                </Panel>
            </div>
        );
    }
}

export default withStyles(styles)(NotFoundError);
