export function getRequiredScopes(url, authorizationScopes) {
    if (!url) {
        return null;
    }

    const isAbsolute = /^[a-zA-Z]+:\/\//.test(url);

    if (isAbsolute) {
        return null;
    }

    if (url.startsWith('/')) {
        url = url.substring(1);
    }

    const resource = url.split('/')[0];

    return authorizationScopes[resource];
}
