import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Panel from '../../components/Panel';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    Toolbar,
    Button,
    TableHead,
    TableRow,
    Typography,
    RadioGroup,
    FormControl,
    Radio,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import { apiPost, apiGet } from '../../api';
import formSubmit from 'dom-form-submit';

const styles = (theme) => ({
    fixedPanel: {
        [theme.breakpoints.up('md')]: {
            position: 'sticky',
            top: 120, // Matches the AppContent marginTop.
        },
    },
    actionBar: {
        paddingLeft: 0,
        paddingRight: 0,
        alignItems: 'flex-end',
    },
    spacer: {
        flexGrow: 1,
    },
    actionButton: {
        marginLeft: 5,
        marginRight: 5,
    },
    payMethod: {
        marginTop: 16,
        padding: 20,
    },
    heading: {
        fontWeight: 500,
    },
});

const cellPadding = 20;

const LeftTableCell = withStyles(() => ({
    body: {
        paddingLeft: cellPadding,
        whiteSpace: 'normal',
        wordBreak: 'break-word',
    },
}))(TableCell);

const RightTableCell = withStyles(() => ({
    body: {
        paddingRight: cellPadding,
        textAlign: 'right',
    },
}))(TableCell);

/*
 * Style the header row
 */
const HeaderTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: '14',
    },
}))(TableCell);

const LeftHeaderTableCell = withStyles(() => ({
    head: {
        paddingLeft: cellPadding,
    },
}))(HeaderTableCell);

const RightHeaderTableCell = withStyles(() => ({
    head: {
        paddingRight: cellPadding,
        textAlign: 'right',
    },
}))(HeaderTableCell);

/*
 * Style the fee total row
 */
const FeeTotalTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
}))(TableRow);

const LeftFeeTotalTableCell = withStyles(() => ({
    body: {
        fontWeight: 'bold',
    },
}))(LeftTableCell);

const RightFeeTotalTableCell = withStyles(() => ({
    body: {
        fontWeight: 'bold',
    },
}))(RightTableCell);

class FeePanel extends Component {
    state = {
        fees: [],
        isLoading: true,
        paymentType: 'CreditCard',
        formPayLater: undefined,
        payLaterDialogOpen: false,
    };

    async componentDidMount() {
        const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

        const { form } = (await apiGet(`applications/${this.props.applicationId}`, authToken)).data;

        this.setState({ formPayLater: form.payLater });

        try {
            const response = await apiGet(`applications/${this.props.applicationId}/fees`, authToken);

            this.setState({ fees: response.data, isLoading: false });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);

            this.setState({ fees: undefined, isLoading: false });
        }
    }

    render() {
        const isAppStatusCompleted = async (token) => {
            token = token ?? (await this.props.getAccessToken(this.props.authorizationScopes.applications));

            const { status } = (
                await apiGet(`applications/${this.props.applicationId}`, token, null, {
                    filter: { fields: { status: true } },
                })
            ).data;

            return status === 'Completed';
        };

        const handlePay = async () => {
            // If the status is not 'Completed' that means somehow the
            // application status flow was violated and the status is
            // not in the expected state.
            if (!(await isAppStatusCompleted())) {
                this.props.setStatusErrorSnackbarOpen(true);

                return;
            }

            const authToken = await this.props.getAccessToken(this.props.authorizationScopes.payments);

            const response = await apiPost(
                'payments',
                {
                    order: { items: [{ applicationId: this.props.applicationId }] },
                    paymentType: this.state.paymentType,
                },
                authToken,
            );

            const transferData = response.data.transferData;

            formSubmit(transferData.parameters, {
                action: transferData.transferUrl,
                method: transferData.transferMethod,
            });
        };

        const handleSubmit = async () => {
            const authToken = await this.props.getAccessToken(this.props.authorizationScopes.applications);

            // If the status is not 'Completed' that means somehow the
            // application status flow was violated and the status is
            // not in the expected state.
            if (!(await isAppStatusCompleted(authToken))) {
                this.props.setStatusErrorSnackbarOpen(true);
            } else {
                apiPost(`applications/${this.props.applicationId}/submit`, null, authToken).then(() => {
                    this.props.history.push(`/applications/${this.props.applicationId}/submissionConfirmation`);
                });
            }

            this.setState({ payLaterDialogOpen: false });
        };

        const handleBack = async () => {
            this.props.history.push(`/applications/${this.props.applicationId}`, { resetStatus: true });
        };

        const handleChangePaymentMethod = (event) => {
            this.setState({ paymentType: event.target.value });
        };

        const payLaterDialog = (
            <Dialog open={this.state.payLaterDialogOpen}>
                <DialogTitle>Submit without paying?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="payLaterConfirmation">
                        Proceeding without paying may require you to make arrangement for payment. Are you sure you
                        would like to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ payLaterDialogOpen: false })} variant="contained">
                        No
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        );

        let toolBar;
        let tableContainer;
        let noFeeMessage;
        let paymentMethod;
        let payNowDisabled = false;

        // handle error in fees
        if (!this.state.fees) {
            tableContainer = (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <LeftHeaderTableCell>Fee</LeftHeaderTableCell>
                                <RightHeaderTableCell>Amount</RightHeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    The application is unable to be submitted. Please contact customer service.
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            );

            toolBar = (
                <Toolbar className={this.props.classes.actionBar}>
                    <Button variant="contained" onClick={handleBack} className={this.props.classes.actionButton}>
                        Back
                    </Button>
                </Toolbar>
            );
            // set the fees
        } else {
            // if fees api has not returned, display a skeleton cell
            if (this.state.isLoading) {
                tableContainer = (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Skeleton />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            } else {
                // filter out fees that have no fee amount
                const fees = this.state.fees;

                // if there are no fees, display a message and a submit button instead of pay button
                if (!fees.length) {
                    noFeeMessage = 'You have no fees. Please review and submit your application.';

                    toolBar = (
                        <Toolbar className={this.props.classes.actionBar}>
                            <Button
                                variant="contained"
                                onClick={handleBack}
                                className={this.props.classes.actionButton}
                            >
                                Back
                            </Button>
                            <div className={this.props.classes.spacer} />
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                color="primary"
                                className={this.props.classes.actionButton}
                                disabled={this.props.isStatusErrorSnackbarOpen}
                            >
                                Submit
                            </Button>
                        </Toolbar>
                    );
                } else {
                    const feeTotal = fees
                        .map((fee) => fee.amount)
                        .reduce((feeAmount1, feeAmount2) => feeAmount1 + feeAmount2);

                    const displayFees = fees.map((fee) => (
                        <TableRow key={fee.feeType}>
                            <LeftTableCell>{fee.description} </LeftTableCell>
                            <RightTableCell>
                                $
                                {fee.amount.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </RightTableCell>
                        </TableRow>
                    ));

                    tableContainer = (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <LeftHeaderTableCell>Fee</LeftHeaderTableCell>
                                        <RightHeaderTableCell>Amount</RightHeaderTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayFees}
                                    <FeeTotalTableRow>
                                        <LeftFeeTotalTableCell>Total</LeftFeeTotalTableCell>
                                        <RightFeeTotalTableCell>
                                            $
                                            {feeTotal.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </RightFeeTotalTableCell>
                                    </FeeTotalTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );

                    paymentMethod = this.props.siteInfo.selectPaymentType && (
                        <Paper className={this.props.classes.payMethod}>
                            <Typography className={this.props.classes.heading}>Select payment method</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="paymentMethod"
                                    name="radio-buttons-group"
                                    onChange={handleChangePaymentMethod}
                                    defaultValue="CreditCard"
                                >
                                    <FormControlLabel value="CreditCard" control={<Radio />} label="Credit Card" />
                                    <FormControlLabel value="eCheck" control={<Radio />} label="eCheck/ACH" />
                                </RadioGroup>
                            </FormControl>
                        </Paper>
                    );

                    const formPayLater = this.state.formPayLater;
                    const sitePayLater = this.props.siteInfo.payLater;

                    /*
                        If the form is configured to allow paying later the option should be available
                        and if it is explicitly configured to not allow paying later the option should
                        not be available. Otherwise, the tenant configuration should determine whether
                        the option should be available.

                        This gives full flexibility to either allow paying later option globally and
                        specific forms can be configured to exclude the option or to disallow paying
                        later globally and specific forms can be configured to include the option.
                     */
                    const payLaterEnabled = !!formPayLater || (formPayLater !== false && !!sitePayLater);

                    // If pay later button is enabled check whether pay button should
                    // be disabled.
                    if (payLaterEnabled) {
                        // If form disables pay now then disable it without looking at the site config.
                        if (typeof formPayLater === 'object' && formPayLater.disablePayNow) {
                            payNowDisabled = true;
                        } else if (typeof sitePayLater === 'object' && sitePayLater.disablePayNow) {
                            // If site disables pay now, only disable it if the form does not explicitly
                            // enable it.
                            if (typeof formPayLater !== 'object' || formPayLater.disablePayNow !== false) {
                                payNowDisabled = true;
                            }
                        }
                    }

                    const payButton = (
                        <Button
                            variant="contained"
                            onClick={handlePay}
                            color="primary"
                            className={this.props.classes.actionButton}
                            disabled={this.props.isStatusErrorSnackbarOpen}
                        >
                            Pay
                        </Button>
                    );

                    const payLaterButton = (
                        <Button
                            variant="contained"
                            // If pay now is disabled then submit without a warning dialog.
                            onClick={!payNowDisabled ? () => this.setState({ payLaterDialogOpen: true }) : handleSubmit}
                            className={this.props.classes.actionButton}
                            // If pay now is disabled then the pay later button color
                            // should be the primary color.
                            color={payNowDisabled ? 'primary' : undefined}
                            disabled={this.props.isStatusErrorSnackbarOpen}
                        >
                            Pay Later
                        </Button>
                    );

                    toolBar = (
                        <Toolbar className={this.props.classes.actionBar}>
                            <Button
                                variant="contained"
                                onClick={handleBack}
                                className={this.props.classes.actionButton}
                            >
                                Back
                            </Button>
                            <div className={this.props.classes.spacer} />
                            {payLaterEnabled && payLaterButton}
                            {!payNowDisabled && payButton}
                        </Toolbar>
                    );
                }
            }
        }

        return (
            <Panel className={this.props.classes.fixedPanel} title="Fees">
                {payLaterDialog}
                {tableContainer}
                {noFeeMessage}
                {!payNowDisabled && paymentMethod}
                {toolBar}
            </Panel>
        );
    }
}

export default withRouter(withStyles(styles)(FeePanel));
