import { cloneDeep, isArray, get, forEach, isObject, transform } from 'lodash';
import { apiGet } from '../api';
import { getRequiredScopes } from '../Authenticate';
import pupa from 'pupa';
import moment from 'moment/moment';

const evaluateDataValues = (parameters, data) => {
    let evaluatedParameters = {};
    Object.entries(parameters).forEach(([key, value]) => {
        if (value.fact) {
            if (value.fact === '$value') {
                evaluatedParameters[key] = '$value';
            } else if (data && data[value.fact]) {
                evaluatedParameters[key] = data[value.fact];
            }
        } else if (typeof value !== 'object') {
            evaluatedParameters[key] = value;
        } else {
            evaluatedParameters[key] = evaluateDataValues(value, data);
        }
    });
    return evaluatedParameters;
};

const combineFilterKeyWithValue = (key, value) => {
    let stringifiedValue = typeof value === 'string' ? value : JSON.stringify(value);

    if (key === 'filter[where][dateOfBirth]') {
        stringifiedValue = stringifiedValue.replace(/"/g, '');
    }

    return [key, encodeURIComponent(stringifiedValue)].join('=');
};

const interpolateText = (template, data) => {
    // An axios transformer is already in place to convert
    // ISO date strings, e.g. '2022-01-01' to Date objects
    // so we only need to check for Date instances.
    const transformDeepDate = (data) => {
        let accumulator;

        if (isArray(data)) {
            accumulator = [];
        } else {
            accumulator = {};
        }

        return transform(
            data,
            (result, value, index) => {
                value = value ?? '';

                if (value instanceof Date) {
                    result[index] = moment(value).format('MM/DD/YYYY');

                    return;
                } else if (isArray(value)) {
                    result[index] = value.map(transformDeepDate);

                    return;
                } else if (isObject(value)) {
                    result[index] = transformDeepDate(value);

                    return;
                }

                result[index] = value;
            },
            accumulator,
        );
    };

    return pupa(template, transformDeepDate(data));
};

export function composeParameters(parameters, data) {
    let combinedParameterString;

    if (parameters) {
        let filterClauses = [];
        const evaluatedParameters = evaluateDataValues(parameters, data);
        filterClauses = Object.entries(evaluatedParameters).map(([key, value]) =>
            combineFilterKeyWithValue(key, value),
        );
        combinedParameterString = filterClauses.join('&');
    }
    return combinedParameterString || '';
}

export const getFieldChoices = async (getAccessToken, authorizationScopes, field, applicationData) => {
    let choices = [];

    if (field.api) {
        const apiUrl = field.api.url;
        let url;

        if (isObject(apiUrl)) {
            for (const [variable, fact] of Object.entries(apiUrl.variables)) {
                url = apiUrl.template.replace(`{${variable}}`, applicationData[fact]);
            }
        } else {
            url = apiUrl;
        }

        let token;

        const scopes = getRequiredScopes(url, authorizationScopes);

        if (scopes) {
            token = await getAccessToken(scopes);
        }

        if (field.api.queryParams) {
            const queryParams = composeParameters(field.api.queryParams, applicationData);

            url = `${url}?${queryParams}`;
        }

        const results = await apiGet(`${url}`, token);

        if (isArray(results.data)) {
            let choiceResults = results.data;

            const resultMapping = field.api.resultMapping;

            if (resultMapping) {
                choices = choiceResults.map((choice) => ({
                    value: get(choice, resultMapping.value),
                    label: resultMapping.label.template
                        ? interpolateText(resultMapping.label.template, choice)
                        : get(choice, resultMapping.label),
                }));
            } else {
                choices = results.data;
            }
        }
    } else {
        choices = field.choices;
    }

    return choices;
};

export const populateAutocomplete = async (getAccessToken, authorizationScopes, field, applicationData) => {
    let choices = [];
    const fieldAutocompleteApi = field.autocomplete?.api;
    if (fieldAutocompleteApi) {
        let token;
        let results;
        let url = fieldAutocompleteApi.url;

        const scopes = getRequiredScopes(url, authorizationScopes);

        if (scopes) {
            token = await getAccessToken(scopes);
        }

        if (fieldAutocompleteApi.queryParams) {
            const queryParams = composeParameters(fieldAutocompleteApi.queryParams, applicationData);

            results = await apiGet(`${url}?${queryParams}`, token);
        } else {
            results = await apiGet(`${url}`, token);
        }

        if (isArray(results.data)) {
            let choiceResults = results.data;
            const resultMapping = fieldAutocompleteApi.resultMapping;

            if (choiceResults.every((choice) => typeof choice !== 'string') && resultMapping) {
                choices = choiceResults.map((choice) => {
                    let choiceMap = {};
                    choiceMap.label = resultMapping.label.template
                        ? pupa(resultMapping.label.template, choice, {
                              transform: (data) => {
                                  return data.value ? data.value : '';
                              },
                          })
                        : get(choice, resultMapping.label);
                    choiceMap.value = choice;
                    return choiceMap;
                });
            } else {
                choices = results.data;
            }
        }
    }
    return choices;
};

const mapSearchResult = (resultValue, definedMapping) => {
    let mappedValue = {};
    forEach(definedMapping, (mapValue, mapKey) => {
        if (mapValue.template) {
            mappedValue[mapKey] = interpolateText(mapValue.template, resultValue);
        } else {
            mappedValue[mapKey] = get(resultValue, mapValue);
        }
    });
    return mappedValue;
};

export const populateSearchResult = async (getAccessToken, authorizationScopes, field, resultValueId) => {
    let formattedResult = [];
    if (field.search && resultValueId) {
        let token;

        const url = `${field.search.url}/${resultValueId}`;

        const scopes = getRequiredScopes(url, authorizationScopes);

        try {
            if (scopes) {
                token = await getAccessToken(scopes);
                const result = await apiGet(url, token);

                if (field?.search?.resultMapping) {
                    const mappedValue = mapSearchResult(result.data, field.search.resultMapping);
                    formattedResult = [mappedValue];
                } else {
                    formattedResult = [result.data];
                }
            }
        } catch (error) {
            /*eslint-disable-next-line no-console*/
            console.error(error);
            return formattedResult;
        }
    }
    return formattedResult;
};

export const populateAutocompleteSearch = async (getAccessToken, authorizationScopes, field, data, value) => {
    let choices = [];
    if (field.search) {
        let token;
        let results;
        let url = field.search.url;

        const scopes = getRequiredScopes(url, authorizationScopes);

        if (scopes) {
            token = await getAccessToken(scopes);
        }

        if (field.search.queryParams) {
            const queryParams = composeParameters(field.search.queryParams, data);
            url = `${url}?${queryParams}`;
            url = url.replace('%24value', value).replace(/(\?")/g, '?').replace(/(")/g, '');
            results = await apiGet(url, token);
        } else {
            results = await apiGet(`${url}`, token);
        }

        if (field?.search?.resultMapping) {
            choices = results.data.map((choice) => mapSearchResult(choice, field.search.resultMapping));
        } else {
            choices = results.data;
        }
    } else {
        choices = field.choices;
    }
    return choices;
};

export const loadRepeatableField = async (getAccessToken, authorizationScopes, field) => {
    const fieldAPI = field.fields.find((field) => field.api);
    if (fieldAPI) {
        const repeatableField = cloneDeep(field);

        for (const nestedField of repeatableField.fields) {
            if (nestedField.typeId === 'choices') {
                nestedField.choices = await getFieldChoices(getAccessToken, authorizationScopes, nestedField);
            }
        }
        return repeatableField;
    }
    return field;
};
