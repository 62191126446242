const styles = (theme) => ({
    panel: {
        padding: theme.spacing(4),
        [theme.breakpoints.only('xs')]: {
            borderRadius: 0,
        },
    },
    panelTitle: {
        fontSize: 24,
        marginBottom: 20,
        margin: 0,
    },
    panelBody: {
        fontSize: 14,
        margin: 0,
    },
});

export default styles;
