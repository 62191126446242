import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import fileImg from './Images/file.svg';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { resourceUrl, apiGet } from '../../api';
import { Tooltip } from '@material-ui/core';

const styles = (theme) => ({
    dropzone: {
        padding: 20,
        borderWidth: 2,
        borderRadius: 4,
        borderStyle: 'dashed',
        borderColor: grey[200],
        textAlign: 'center',
    },
    error: {
        borderColor: red[500],
    },
    errorIcon: {
        paddingRight: theme.spacing(0.5),
        color: grey[600],
    },
    invalidFileList: {
        marginTop: theme.spacing(0.5),
    },
    fileList: {
        marginBottom: theme.spacing(3),
    },
    fileImage: {
        width: 64,
        height: 64,
        cursor: 'pointer',
    },
    deleteIcon: {
        padding: 5,
    },
    fileName: {
        wordWrap: 'break-word',
    },
    highlight: {
        backgroundColor: lighten(yellow[500], 0.8),
    },
});

class FileUpload extends React.Component {
    handleDelete = (event, index) => {
        this.props.onDelete(index);

        event.stopPropagation();
        event.preventDefault();
    };

    download = async (event, url) => {
        event.stopPropagation();
        event.preventDefault();

        const authToken = await this.props.getAccessToken(this.props.applicationScopes);
        const response = await apiGet(`${url}/token`, authToken);
        const encodedFileId = encodeURIComponent(encodeURIComponent(response.data.id));

        window.open(
            resourceUrl(`files/${encodedFileId}?token=${encodeURIComponent(response.data.token)}`),
            '_blank',
            'noopener',
        );
    };

    render() {
        return (
            <Grid>
                <Dropzone onDrop={this.props.onUpload} disabled={this.props.disabled}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                            {...getRootProps({
                                className: classNames(
                                    this.props.classes.dropzone,
                                    {
                                        [this.props.classes.error]: this.props.error,
                                    },
                                    {
                                        [this.props.classes.highlight]: isDragActive,
                                    },
                                ),
                            })}
                        >
                            <input {...getInputProps()} />
                            {this.props.files && this.props.files.length > 0 && (
                                <Grid container spacing={1} className={this.props.classes.fileList}>
                                    {this.props.files.map((file, index) => (
                                        <Grid item key={index} xs={3}>
                                            <Badge
                                                badgeContent={
                                                    !this.props.disabled && (
                                                        <Tooltip
                                                            title={`Remove file ${file.fileName}`}
                                                            placement="right-end"
                                                        >
                                                            <div>
                                                                <IconButton
                                                                    onClick={(event) => this.handleDelete(event, index)}
                                                                    aria-label={`Remove file ${file.fileName}`}
                                                                    className={this.props.classes.deleteIcon}
                                                                >
                                                                    &times;
                                                                </IconButton>
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                }
                                            >
                                                <Tooltip title={`Download file ${file.fileName}`}>
                                                    <input
                                                        type="image"
                                                        src={fileImg}
                                                        alt={file.fileName}
                                                        className={this.props.classes.fileImage}
                                                        onClick={(event) =>
                                                            this.download(
                                                                event,
                                                                resourceUrl(
                                                                    `applications/${
                                                                        this.props.applicationId
                                                                    }/attachments/${
                                                                        this.props.field
                                                                    }/${encodeURIComponent(
                                                                        encodeURIComponent(file.id),
                                                                    )}`,
                                                                ),
                                                            )
                                                        }
                                                    />
                                                </Tooltip>
                                            </Badge>
                                            <div>
                                                <Typography variant="caption" className={this.props.classes.fileName}>
                                                    {file.fileName}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                            {!this.props.isAnalyzingSmartUpload && (
                                <Typography color="textSecondary">
                                    Drag &apos;n&apos; drop files here, or click to select files.
                                </Typography>
                            )}
                            {!this.props.isAnalyzingSmartUpload && this.props.isSmartUpload && (
                                <Typography color="textSecondary" variant="caption">
                                    Upon uploading we will verify that your files are correct
                                </Typography>
                            )}
                            {this.props.isAnalyzingSmartUpload && (
                                <Typography color="textSecondary">Validating files...</Typography>
                            )}
                        </div>
                    )}
                </Dropzone>
                <Grid container spacing={1} className={this.props.classes.fileList}>
                    {this.props.invalidFiles?.map((file, index) => (
                        <Grid
                            container
                            item
                            alignItems="center"
                            className={this.props.classes.invalidFileList}
                            key={index}
                        >
                            <ErrorOutlineIcon className={this.props.classes.errorIcon}></ErrorOutlineIcon>
                            <Typography color="textSecondary">
                                {file} does not sufficiently match expected file
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(FileUpload);
